import * as React from "react";
const LargeCircles = (props) => (
  <svg
    className={'absolute top-8 right-8 -z-10'}
    xmlns="http://www.w3.org/2000/svg"
    width={969}
    height={917}
    fill="none"
    {...props}
  >
    <path
      fill="#D4F7F5"
      d="M953.678 805.599c8.323 0 15.07-6.742 15.07-15.059s-6.747-15.06-15.07-15.06-15.071 6.743-15.071 15.06 6.748 15.059 15.071 15.059"
    />
    <path
      fill="#FFDED4"
      d="M908.31 642.708c5.301 0 9.598-4.294 9.598-9.59s-4.297-9.591-9.598-9.591c-5.3 0-9.597 4.294-9.597 9.591s4.297 9.59 9.597 9.59M831.155 894.919a7.665 7.665 0 0 0 7.668-7.662 7.666 7.666 0 0 0-7.668-7.663c-4.235 0-7.669 3.431-7.669 7.663s3.434 7.662 7.669 7.662"
    />
    <path
      stroke="#38F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M952.754 803.263c8.323 0 15.07-6.742 15.07-15.059s-6.747-15.059-15.07-15.059-15.07 6.742-15.07 15.059 6.747 15.059 15.07 15.059M907.369 640.356c5.3 0 9.597-4.293 9.597-9.59s-4.297-9.59-9.597-9.59c-5.301 0-9.598 4.293-9.598 9.59s4.297 9.59 9.598 9.59M829.901 891.787a7.666 7.666 0 0 0 7.668-7.663 7.666 7.666 0 0 0-7.668-7.663c-4.235 0-7.669 3.431-7.669 7.663s3.434 7.663 7.669 7.663"
    />
    <path
      className={'spinner'}
      stroke="#38F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M839.937 914.194a31.8 31.8 0 0 1-10.052 1.63c-17.517 0-31.725-14.197-31.725-31.701s14.208-31.701 31.725-31.701a31.64 31.64 0 0 1 20.339 7.365M858.113 869.645c2.243 4.34 3.497 9.261 3.497 14.479"
    />
    <path
      fill="#FFD9F6"
      d="M612.012 510.018c15.473 0 28.017-12.546 28.017-28.021s-12.544-28.02-28.017-28.02-28.016 12.545-28.016 28.02 12.543 28.021 28.016 28.021"
    />
    <path
      fill="#FFDED4"
      d="M365.08 689.002c9.836 0 17.809-7.975 17.809-17.812s-7.973-17.811-17.809-17.811c-9.835 0-17.809 7.974-17.809 17.811s7.974 17.812 17.809 17.812"
    />
    <path
      stroke="#38F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M610.276 505.674c15.473 0 28.016-12.545 28.016-28.021 0-15.475-12.543-28.02-28.016-28.02s-28.016 12.545-28.016 28.02c0 15.476 12.543 28.021 28.016 28.021"
    />
    <path
      fill="#FFDED4"
      d="M118.364 114.689c15.473 0 28.016-12.545 28.016-28.02s-12.543-28.02-28.016-28.02-28.016 12.545-28.016 28.02 12.543 28.02 28.016 28.02"
    />
    <path
      stroke="#38F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M116.626 110.346c15.473 0 28.016-12.546 28.016-28.02 0-15.476-12.543-28.021-28.016-28.021S88.609 66.85 88.609 82.325s12.544 28.021 28.017 28.021M363.342 684.654c9.835 0 17.809-7.974 17.809-17.811s-7.974-17.812-17.809-17.812-17.809 7.975-17.809 17.812 7.973 17.811 17.809 17.811"
    />
    <path
      fill="#D4F7F5"
      d="M165.926 202.443c9.835 0 17.809-7.974 17.809-17.811s-7.974-17.812-17.809-17.812-17.809 7.975-17.809 17.812 7.973 17.811 17.809 17.811"
    />
    <path
      stroke="#38F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M164.188 198.099c9.835 0 17.808-7.974 17.808-17.811s-7.973-17.811-17.808-17.811-17.809 7.974-17.809 17.811 7.973 17.811 17.809 17.811"
    />
    <path
      fill="#FFD9F6"
      d="M21.283 41.705c9.836 0 17.81-7.974 17.81-17.811S31.118 6.081 21.282 6.081 3.475 14.057 3.475 23.894s7.973 17.811 17.808 17.811"
    />
    <path
      stroke="#38F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.547 37.361c9.836 0 17.809-7.974 17.809-17.811S29.382 1.738 19.547 1.738 1.738 9.713 1.738 19.55 9.712 37.36 19.547 37.36"
    />
    <path
      fill="#D4F7F5"
      d="M608.321 114.904c15.473 0 28.016-12.545 28.016-28.02s-12.543-28.02-28.016-28.02-28.016 12.545-28.016 28.02 12.543 28.02 28.016 28.02"
    />
    <path
      className={'spinner'}
      stroke="#38F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M606.583 110.561c15.473 0 28.016-12.546 28.016-28.021s-12.543-28.02-28.016-28.02-28.017 12.545-28.017 28.02 12.544 28.021 28.017 28.021M554.678 43.658c11.727-14.987 29.753-24.545 50.168-24.545s29.537 5.43 40.613 14.77M589.644 144.23c-27.799-6.734-48.214-31.93-48.214-61.689 0-29.758 0-5.864.651-8.688M622.871 143.578c-3.475 1.086-7.167 1.738-11.076 2.172M660.01 51.047c5.212 9.34 8.253 19.984 8.253 31.496 0 19.983-9.339 38.012-23.673 49.524"
    />
  </svg>
);
export default LargeCircles;