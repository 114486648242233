import React, { useState } from 'react';

function ContactForm() {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    message: ''
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Clear previous messages
    setSuccessMessage('');
    setErrorMessage('');

    fetch('https://bold-meadow-c112.7ssmbhq6vp.workers.dev/send_email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.text())
      .then(data => {
        console.log('Success:', data);
        setSuccessMessage('Email sent successfully!');
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage('Failed to send email.');
      });
  };

  return (
    <div className={"mb-40"}>
      <form className={"flex flex-col gap-9"} action="https://formsubmit.co/fbe35f9743775d524848e8a242cf8c6e" method="POST">
        <div className={"flex flex-col gap-6"}>
          <label className={"bodyText"} htmlFor="name">Your name</label>
          <input
            className={"border border-[#CCC] rounded-lg bg-[#fafafa] p-4 bodyText"}
            type="text"
            id="name"
            name="name"
            placeholder="Type here"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className={"flex flex-col gap-6"}>
          <label className={"bodyText"} htmlFor="email">Your e-mail</label>
          <input
            className={"border border-[#CCC] rounded-lg bg-[#fafafa] p-4 bodyText"}
            type="email"
            id="email"
            name="email"
            placeholder="Type here"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className={"flex flex-col gap-6"}>
          <label className={"bodyText"} htmlFor="message">Message</label>
          <input
            className={"border border-[#CCC] rounded-lg bg-[#fafafa] p-4 bodyText"}
            type="text"
            id="message"
            name="message"
            placeholder="Type here"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>

        <button className={"w-fit bg-[#B6D43E] hover:bg-[#38F] text-white uppercase py-4 px-20 rounded-full cursor-pointer"} type="submit">Test us</button>
      </form>

      {successMessage && <p className={"successMessage bodyText mt-20"}>{successMessage}</p>}
      {errorMessage && <p className={"errorMessage bodyText mt-20"}>{errorMessage}</p>}
    </div>
  );
}

export default ContactForm;
