import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ProgressBar = () => {
    const progressBarRef = useRef(null);

    useEffect(() => {
        const progressBar = progressBarRef.current;

        gsap.fromTo(progressBar,
            { scaleX: 0 },
            {
                scaleX: 1,
                ease: 'none',
                scrollTrigger: {
                    trigger: 'body',
                    start: 'top top',
                    end: 'bottom bottom',
                    scrub: true,
                },
            }
        );
    }, []);

    return (
        <div className="progress-bar-container" style={containerStyle}>
            <div className="progress-bar" ref={progressBarRef} style={progressBarStyle}></div>
        </div>
    );
};

const containerStyle = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '5px',
    backgroundColor: 'transparent',
    zIndex: 1000,
};

const progressBarStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: '#B6D43E',
    transformOrigin: 'left center',
};

export default ProgressBar;
