import React from 'react';
import logo from "./i/logo.svg";
import eas from "./i/eas.svg";
import {Link} from "react-router-dom";

const EAS = () => {
  return (
    <section id="section1" className="flex">
      <div className="container mx-auto max-w-5xl">
        <div className="flex flex-col pt-12 pb-16 ">
          <div>
            <Link to="/" className="cursor-pointer">
              <img src={logo} className="mb-12 app-logo w-48" alt="logo"/>
            </Link>


            <p className="leading-[1.3] text-[21px] pb-5">
              <stong>PROJECT SUMMARY</stong>
            </p>
            <p>Through this project, we are developing an international service offering platform, including media
              planning, creative process management, and production process management, which will enable us to
              successfully serve international clients. Additionally, we will develop and test two service directions in
              export markets, with a strong emphasis on sustainability within the marketing flow.</p>

            <p className="leading-[1.3] text-[21px] pt-11 pb-5">
              <stong>OBJECTIVE</stong>
            </p>
            <p>The strategic goal of the project is to transform the company either entirely or significantly into an
              export-oriented agency.</p>


            <p className="leading-[1.3] text-[21px] pt-11 pb-5">
              <stong>RESULT</stong>
            </p>
            <p>Growth in exports and added value.</p>


            <p className="leading-[1.3] text-[21px] pt-11 pb-5">
              <stong>FUND NAME</stong>
            </p>
            <p>2021-2027 Cohesion Policy Fund</p>

            <p className="leading-[1.3] text-[21px] pt-11 pb-5">
              <stong>SUPPORT AMOUNT</stong>
            </p>
            <p>100,000 euros</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EAS;
