import './App.css';
import React, { useEffect } from 'react';
import { gsap } from 'gsap';

import ProgressBar from "./components/ProgressBar";
import ContactForm from "./components/ContactForm";
import graph from './i/graph1.svg'
import logo from "./logo.svg";
import Circles1 from "./i/Circles1";
import LargeCircles from "./i/LargeCircles";
import PHScreens from "./i/ph-screens.png";
import Icon from "./i/icon.svg";
import Icon1 from "./i/icon-1.svg";
import Icon2 from "./i/icon-2.svg";
import Icon3 from "./i/icon-3.svg";
import Possible from "./i/possible.svg";
import PossibleMob from "./i/possible_mob.svg";
import Solution from "./i/solution.svg";
import SolutionMob from "./i/solution_mob.svg";
import SolutionMobile from "./i/solution_mobile.svg";
// Logos
import Greenly from "./i/logos/greenly-logo.svg";
import Pepsi from "./i/logos/Pepsi New 2023.svg";
import Tesco from "./i/logos/Tesco_Logo.svg";
import Scope from "./i/logos/scope3 1.svg";
import Toyota from "./i/logos/Toyota_EU.svg";
import Volkswagen from "./i/logos/volkswagen-1-logo-svgrepo-com.svg";

function Home() {

  useEffect(() => {
    const handleScroll = () => {
      const y = window.scrollY;
      const speed = 0.2;
      const spin = y * speed;
      const spinners = document.querySelectorAll('.spinner');

      spinners.forEach(spinner => {
        gsap.to(spinner, { rotation: spin, transformOrigin: "50% 50%", duration: 1 });
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className="App">


      <div className="bottom-0 right-0 fixed bg-white z-50 p-4 shadow"><a
        className="text-blue-500 underline cursor-pointer" href="/eas"><img
        src="https://www.nexdstudio.com/48h/static/media/eas.bf536f9a806e42b470c7de6b956e61b4.svg"
        alt="EAS" className="eas max-w-[180px]"/></a></div>

      <ProgressBar/>

      <section id={'hero'}>
        <div className="container mx-auto px-4">
          <div className={'relative h-screen flex items-center text-left'}>
            <div>
              <img src={logo} alt="Logo"/>
              <h1 className={'mt-10 lg:mt-12'}>
                AUTOMATED DIGITAL<br/>
                ASSET PRODUCTION
              </h1>
            </div>
          </div>
        </div>
        <LargeCircles/>
      </section>

      <section>
        <div className="container mx-auto px-4">
          <header className={'mb-10 lg:mb-28'}>
            <h2 className={'headLine'}>
              TECHNOLOGY BUILT BY <br/>
              PROFESSIONALS TO REDEFINE<br/>
              EFFICIENCY IN DIGITAL DESIGN</h2>
            <p className={'bodyText mt-10 lg:mt-12'}>Nexd Studio was born in an international marcom group serving
              hundreds of brands on every continent.</p>
          </header>

          <div className="flex flex-col lg:flex-row">
            <div className={'lg:w-8/12'}>

              <img className={"hidden lg:block"} src={graph} alt=""/>
              <img className={"lg:hidden mx-auto"} src={PossibleMob} alt=""/>

            </div>
            <div className={'lg:w-4/12 lg:my-auto'}>
              <h3 className={'subHeadLine mb-9 mt-9 lg:mt-0'}>NEXD STUDIO</h3>
              <p className={'mb-4 bodyText'}>Nexd Studio automated production tech & service was built for group
                in-house use to improve efficiency.</p>
              <p className={'bodyText'}><strong>It quickly grew out as a separate service due to extremely high value of
                the service.</strong></p>
            </div>
          </div>

          <div className="flex justify-end">
            <Circles1/>

          </div>

        </div>
      </section>

      <section>
        <div className="container mx-auto px-4">

          <header className={'mb-10 lg:mb-28'}>
            <h2 className={'headLine'}>
              NEXD STUDIO DELIVERS DAY TO<br/>
              DAY DIGITAL ASSETS PRODUCTION
            </h2>
          </header>

          <div className="flex flex-col lg:flex-row gap-4 lg:gap-20">
            <div className={'lg:w-4/12'}>
              <ul className={"arrowlist"}>
                <li>Still display banners</li>
                <li>HTML5 banners</li>
                <li>Interactive banners</li>
                <li>Video ads</li>
                <li>Social ads</li>
                <li>OOH digital formats</li>
                <li>Video OOH</li>
              </ul>
            </div>
            <div className={'lg:w-8/12'}>
              <img src={PHScreens} alt=""/>
            </div>
          </div>

          <div className="flex justify-end">
            <svg width="318" height="166" viewBox="0 0 318 166" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1239_14561)">
                <path
                  d="M304.877 28.9312C312.119 28.9312 317.989 23.0782 317.989 15.8582C317.989 8.63815 312.119 2.78516 304.877 2.78516C297.636 2.78516 291.766 8.63815 291.766 15.8582C291.766 23.0782 297.636 28.9312 304.877 28.9312Z"
                  fill="#FFD9F6"/>
                <path
                  d="M32.1155 126.212C39.3569 126.212 45.2271 120.359 45.2271 113.139C45.2271 105.919 39.3569 100.066 32.1155 100.066C24.8742 100.066 19.0039 105.919 19.0039 113.139C19.0039 120.359 24.8742 126.212 32.1155 126.212Z"
                  fill="#D4F7F5"/>
                <path
                  d="M272.673 165.999C277.283 165.999 281.021 162.272 281.021 157.675C281.021 153.078 277.283 149.352 272.673 149.352C268.062 149.352 264.324 153.078 264.324 157.675C264.324 162.272 268.062 165.999 272.673 165.999Z"
                  fill="#FFDED4"/>
                <path
                  d="M304.065 26.9039C311.306 26.9039 317.176 21.0509 317.176 13.8308C317.176 6.6108 311.306 0.757812 304.065 0.757812C296.823 0.757812 290.953 6.6108 290.953 13.8308C290.953 21.0509 296.823 26.9039 304.065 26.9039Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M31.2913 124.189C38.5327 124.189 44.4029 118.336 44.4029 111.116C44.4029 103.896 38.5327 98.043 31.2913 98.043C24.05 98.043 18.1797 103.896 18.1797 111.116C18.1797 118.336 24.05 124.189 31.2913 124.189Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M271.86 163.976C276.471 163.976 280.208 160.249 280.208 155.652C280.208 151.055 276.471 147.328 271.86 147.328C267.249 147.328 263.512 151.055 263.512 155.652C263.512 160.249 267.249 163.976 271.86 163.976Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path className={"spinner"}
                      d="M7.01953 92.9381C12.4531 85.9813 20.9335 81.5156 30.4498 81.5156C37.6607 81.5156 44.2724 84.0776 49.4216 88.3509M23.3186 139.855C10.3695 136.675 0.761719 125.009 0.761719 111.116C0.761719 109.749 0.853124 108.402 1.03594 107.086M38.8367 139.52C37.1812 140.006 35.4547 140.35 33.6875 140.542M56.2562 96.4609C58.7343 100.785 60.146 105.787 60.146 111.114C60.146 120.45 55.8093 128.784 49.0352 134.212"
                      stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_1239_14561">
                  <rect width="318" height="166" fill="white"/>
                </clipPath>
              </defs>
            </svg>


          </div>
        </div>
      </section>


      <section>
        <div className="container mx-auto px-4">

          <header className={'mb-10 lg:mb-28'}>
            <h2 className={'headLine'}>
              PRODUCTION OF DIGITAL ASSETS<br/> COMPETITIVE IN EVERY ASPECT
            </h2>
            <p className={"mt-10 bodyText"}>Working with Nexd Studio:</p>
          </header>

          <div className="grid lg:grid-cols-2 gap-4 lg:gap-20">

            <div className={"flex gap-4 lg:gap-8 items-center"}>
              <img src={Icon} alt=""/>
              <p className={"bodyText"}><strong className={"nexd-sea"}>is 5x faster</strong> than your current designer
                set up.</p>
            </div>

            <div className={"flex gap-4 lg:gap-8 items-center"}>
              <img src={Icon1} alt=""/>
              <p className={"bodyText"}><strong className={"nexd-sea"}>is kinder to the planet</strong> by reducing CO2
                impact.</p>
            </div>

            <div className={"flex gap-4 lg:gap-8 items-center"}>
              <img src={Icon2} alt=""/>
              <p className={"bodyText"}><strong className={"nexd-sea"}>is more flexible</strong> than manual production,
                any changes to the campaign can be done rapidly.</p>
            </div>

            <div className={"flex gap-4 lg:gap-8 items-center"}>
              <img src={Icon3} alt=""/>
              <p className={"bodyText"}><strong className={"nexd-sea"}>creates better ROI by increasing CTRs</strong> –
                in addition to the still assets we will produce interactive formats to the same placements.</p>
            </div>


          </div>

          <div className={"flex justify-center mb-10"}>
            <svg width="364" height="211" viewBox="0 0 364 211" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1239_14527)">
                <path
                  d="M136.533 192.206C136.533 202.417 144.796 210.694 154.989 210.694C165.182 210.694 173.445 202.417 173.445 192.206C173.445 181.996 165.182 173.719 154.989 173.719C144.796 173.719 136.533 181.996 136.533 192.206Z"
                  fill="#D4F7F5"/>
                <path
                  d="M336.172 136.551C336.172 143.053 341.435 148.324 347.926 148.324C354.417 148.324 359.68 143.053 359.68 136.551C359.68 130.049 354.417 124.777 347.926 124.777C341.435 124.777 336.172 130.049 336.172 136.551Z"
                  fill="#FFDED4"/>
                <path
                  d="M27.0609 41.8995C27.0609 47.095 31.2655 51.3067 36.4523 51.3067C41.6391 51.3067 45.8437 47.095 45.8437 41.8995C45.8437 36.704 41.6391 32.4922 36.4523 32.4922C31.2655 32.4922 27.0609 36.704 27.0609 41.8995Z"
                  fill="#FFDED4"/>
                <path
                  d="M139.392 191.07C139.392 201.28 147.655 209.557 157.848 209.557C168.041 209.557 176.305 201.28 176.305 191.07C176.305 180.859 168.041 172.582 157.848 172.582C147.655 172.582 139.392 180.859 139.392 191.07Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M339.051 135.395C339.051 141.897 344.313 147.168 350.805 147.168C357.296 147.168 362.559 141.897 362.559 135.395C362.559 128.892 357.296 123.621 350.805 123.621C344.313 123.621 339.051 128.892 339.051 135.395Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M30.9007 40.3604C30.9007 45.5559 35.1054 49.7677 40.2921 49.7677C45.4789 49.7677 49.6836 45.5559 49.6836 40.3604C49.6836 35.1649 45.4789 30.9531 40.2921 30.9531C35.1054 30.9531 30.9007 35.1649 30.9007 40.3604Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path className={"spinner"}
                      d="M3.43875 52.6713C2.15199 48.8045 1.44141 44.6492 1.44141 40.3399C1.44141 18.8513 18.8415 1.42187 40.2939 1.42188C61.7464 1.42188 79.1465 18.8513 79.1465 40.3399C79.1465 49.8434 75.7471 58.5388 70.1199 65.2913M58.041 74.9688C52.7211 77.7198 46.6906 79.2588 40.2952 79.2588"
                      stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_1239_14527">
                  <rect width="211" height="364" fill="white" transform="translate(364) rotate(90)"/>
                </clipPath>
              </defs>
            </svg>
          </div>


        </div>
      </section>


      <section>
        <div className="container mx-auto px-4">

          <header className={'mb-10 lg:mb-28'}>
            <h2 className={'headLine'}>
              TESTED AND CERTIFIED<br/>
              IN EVERY ASPECT
            </h2>
          </header>

          <div className="grid lg:grid-cols-2 gap-4 lg:gap-20">
            <div>
              <p className={"bodyText mb-6"}><strong className={"nexd-navy"}>Nexd Studio production service
                clients:</strong></p>
              <div className={"flex flex-wrap gap-5"}>
                <img src={Pepsi} alt=""/>
                <img src={Tesco} alt=""/>
                <img src={Toyota} alt=""/>
                <img src={Volkswagen} alt=""/>
              </div>
            </div>
            <div>
              <p className={"bodyText mb-6"}><strong className={"nexd-navy"}>Carbon metrics in partnership
                with:</strong></p>
              <div className={"flex flex-wrap gap-5"}>
                <img src={Greenly} alt=""/>
              </div>
            </div>
            <div>
              <p className={"bodyText mb-6"}><strong className={"nexd-navy"}>Nexd platform service clients:</strong></p>
              <div className={"flex flex-wrap gap-5"}>
                <img src={Pepsi} alt=""/>
                <img src={Tesco} alt=""/>
                <img src={Toyota} alt=""/>
                <img src={Volkswagen} alt=""/>
              </div>
            </div>
            <div>
              <p className={"bodyText mb-6"}><strong className={"nexd-navy"}>Carbon calculations & data:</strong></p>
              <div className={"flex flex-wrap gap-5"}>
                <img src={Scope} alt=""/>
              </div>
            </div>
          </div>

          <div className={"flex justify-end mb-10 mt-20"}>
            <svg width="364" height="211" viewBox="0 0 364 211" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1239_14527)">
                <path
                  d="M136.533 192.206C136.533 202.417 144.796 210.694 154.989 210.694C165.182 210.694 173.445 202.417 173.445 192.206C173.445 181.996 165.182 173.719 154.989 173.719C144.796 173.719 136.533 181.996 136.533 192.206Z"
                  fill="#D4F7F5"/>
                <path
                  d="M336.172 136.551C336.172 143.053 341.435 148.324 347.926 148.324C354.417 148.324 359.68 143.053 359.68 136.551C359.68 130.049 354.417 124.777 347.926 124.777C341.435 124.777 336.172 130.049 336.172 136.551Z"
                  fill="#FFDED4"/>
                <path
                  d="M27.0609 41.8995C27.0609 47.095 31.2655 51.3067 36.4523 51.3067C41.6391 51.3067 45.8437 47.095 45.8437 41.8995C45.8437 36.704 41.6391 32.4922 36.4523 32.4922C31.2655 32.4922 27.0609 36.704 27.0609 41.8995Z"
                  fill="#FFDED4"/>
                <path
                  d="M139.392 191.07C139.392 201.28 147.655 209.557 157.848 209.557C168.041 209.557 176.305 201.28 176.305 191.07C176.305 180.859 168.041 172.582 157.848 172.582C147.655 172.582 139.392 180.859 139.392 191.07Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M339.051 135.395C339.051 141.897 344.313 147.168 350.805 147.168C357.296 147.168 362.559 141.897 362.559 135.395C362.559 128.892 357.296 123.621 350.805 123.621C344.313 123.621 339.051 128.892 339.051 135.395Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M30.9007 40.3604C30.9007 45.5559 35.1054 49.7677 40.2921 49.7677C45.4789 49.7677 49.6836 45.5559 49.6836 40.3604C49.6836 35.1649 45.4789 30.9531 40.2921 30.9531C35.1054 30.9531 30.9007 35.1649 30.9007 40.3604Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path className={"spinner"}
                      d="M3.43875 52.6713C2.15199 48.8045 1.44141 44.6492 1.44141 40.3399C1.44141 18.8513 18.8415 1.42187 40.2939 1.42188C61.7464 1.42188 79.1465 18.8513 79.1465 40.3399C79.1465 49.8434 75.7471 58.5388 70.1199 65.2913M58.041 74.9688C52.7211 77.7198 46.6906 79.2588 40.2952 79.2588"
                      stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_1239_14527">
                  <rect width="211" height="364" fill="white" transform="translate(364) rotate(90)"/>
                </clipPath>
              </defs>
            </svg>
          </div>


        </div>
      </section>


      <section>
        <div className="container mx-auto px-4">

          <header className={'mb-10 lg:mb-28 max-w-5xl'}>
            <h2 className={'headLine'}>
              HOW IS IT EVEN POSSIBLE?
            </h2>
            <p className={"mt-10 bodyText"}>The industry standard today is manual formatting of each ad separately, that
              generates working hours and production costs.</p>
          </header>

          <img className={"hidden lg:block"} src={Possible} alt=""/>
          <img className={"lg:hidden mx-auto"} src={SolutionMob} alt=""/>


          <div className={"flex justify-end mb-10"}>
            <svg width="165" height="285" viewBox="0 0 165 285" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1239_14583)">
                <path
                  d="M14.6992 106.899C6.71478 106.899 0.242146 113.369 0.242145 121.35C0.242144 129.331 6.71478 135.801 14.6992 135.801C22.6836 135.801 29.1563 129.331 29.1563 121.35C29.1563 113.369 22.6836 106.899 14.6992 106.899Z"
                  fill="#D4F7F5"/>
                <path
                  d="M58.219 263.212C53.1342 263.212 49.0122 267.332 49.0122 272.414C49.0122 277.497 53.1342 281.617 58.219 281.617C63.3038 281.617 67.4258 277.497 67.4258 272.414C67.4258 267.332 63.3038 263.212 58.219 263.212Z"
                  fill="#FFDED4"/>
                <path
                  d="M132.233 21.1882C128.171 21.1882 124.877 24.4803 124.877 28.5413C124.877 32.6024 128.171 35.8945 132.233 35.8945C136.296 35.8945 139.59 32.6024 139.59 28.5413C139.59 24.4803 136.296 21.1882 132.233 21.1882Z"
                  fill="#FFDED4"/>
                <path
                  d="M15.5859 109.141C7.60149 109.141 1.12885 115.611 1.12885 123.592C1.12885 131.573 7.60149 138.043 15.5859 138.043C23.5703 138.043 30.043 131.573 30.043 123.592C30.043 115.611 23.5703 109.141 15.5859 109.141Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M59.1213 265.466C54.0366 265.466 49.9146 269.586 49.9146 274.668C49.9146 279.751 54.0366 283.871 59.1213 283.871C64.2061 283.871 68.3281 279.751 68.3281 274.668C68.3281 269.586 64.2061 265.466 59.1213 265.466Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M133.437 24.196C129.374 24.196 126.08 27.4881 126.08 31.5492C126.08 35.6102 129.374 38.9023 133.437 38.9023C137.499 38.9023 140.793 35.6102 140.793 31.5492C140.793 27.4881 137.499 24.196 133.437 24.196Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path className={"spinner"}
                      d="M123.809 2.69276C126.833 1.68527 130.083 1.12891 133.452 1.12891C150.256 1.12891 163.886 14.7526 163.886 31.5492C163.886 48.3457 150.256 61.9694 133.452 61.9694C126.021 61.9694 119.221 59.3078 113.941 54.9019M106.374 45.4421C104.223 41.2768 103.02 36.5551 103.02 31.5477"
                      stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_1239_14583">
                  <rect width="165" height="285" fill="white" transform="translate(165 285) rotate(-180)"/>
                </clipPath>
              </defs>
            </svg>

          </div>


        </div>
      </section>

      <section>
        <div className="container mx-auto px-4">

          <header className={'mb-10 lg:mb-28 max-w-5xl'}>
            <h2 className={'headLine'}>
              NEXD STUDIO SOLUTION
            </h2>
            <p className={"mt-10 bodyText"}>We eliminate all the unnecessary workloads from the process, add automations
              and our
              unique technology to create a service that is unbeatable in terms of speed, quality and performance
              enhancement.</p>
          </header>


          <img className={"hidden lg:block"} src={Solution} alt=""/>
          <img className={"lg:hidden mx-auto mb-20"} src={SolutionMobile} alt=""/>


        </div>
      </section>

      <section className={"lg:mt-20"}>
        <div className="container mx-auto px-4">

          <div className="grid lg:grid-cols-2 gap-4 lg:gap-40">
            <div>
              <header className={'mb-10 lg:mb-20'}>
                <h2 className={'headLine'}>
                  SUMMARY
                </h2>
                <p className={"mt-10 bodyText "}>With Nexd Studio service you will get:</p>
              </header>

              <ul className={"arrowlist"}>
                <li><p><strong className={"nexd-sea"}>5x times faster production</strong> of digital ad formats</p></li>
                <li><p><strong className={"nexd-sea"}>Rapid updates</strong> of all campaign
                  creatives when needed</p>
                </li>
                <li><p><strong className={"nexd-sea"}>Up to 80% CO2 emission reduction</strong> from digital advertising
                  (certified by Greenly Earth & Scope3).</p>
                </li>
                <li><p><strong className={"nexd-sea"}>Up to 5x higher CTR-s</strong> if the interactive formats are used
                  instead still banners.</p></li>
              </ul>

            </div>
            <div>
              <svg className={"max-w-full h-auto"} width="497" height="727" viewBox="0 0 497 727" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1240_15452)">
                  <path
                    d="M155.512 211.047C162.403 211.047 172.125 207.728 172.125 197.22C172.125 188.436 166.401 185.73 154.346 183.147C150.103 182.228 148.131 181.982 148.131 179.951C148.131 178.103 149.542 176.203 154.223 176.203C158.905 176.203 160.343 177.893 161.026 180.152C161.123 180.476 161.439 180.686 161.772 180.686H170.854C171.328 180.686 171.713 180.266 171.643 179.793C170.635 172.709 165.91 166.43 154.215 166.43C146.035 166.43 137.602 170.975 137.602 180.441C137.602 189.347 144.799 191.93 151.813 193.41C159.01 194.943 161.596 195.127 161.596 197.649C161.596 199.926 159.685 201.274 155.442 201.274C150.261 201.274 148.28 199.794 147.245 196.616C147.14 196.283 146.842 196.055 146.491 196.055H137.409C136.927 196.055 136.541 196.484 136.62 196.957C137.777 203.98 142.879 211.047 155.503 211.047H155.512Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M175.711 210.241H185.092C185.425 210.241 185.732 210.031 185.837 209.706L188.546 201.86C188.66 201.545 188.958 201.326 189.291 201.326H204.282C204.615 201.326 204.922 201.536 205.028 201.86L207.736 209.706C207.85 210.022 208.149 210.241 208.482 210.241H217.862C218.414 210.241 218.791 209.698 218.607 209.181L203.555 167.751C203.441 167.436 203.143 167.234 202.81 167.234H190.755C190.422 167.234 190.124 167.445 190.01 167.751L174.957 209.181C174.773 209.698 175.15 210.241 175.703 210.241H175.711ZM192.544 190.336L196.05 180.16C196.296 179.451 197.304 179.451 197.541 180.16L201.047 190.336C201.223 190.852 200.846 191.378 200.302 191.378H193.289C192.745 191.378 192.368 190.844 192.544 190.336Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M229.839 209.689C229.944 210.022 230.251 210.241 230.593 210.241H242.595C242.937 210.241 243.244 210.022 243.349 209.689L256.447 168.259C256.604 167.751 256.227 167.234 255.693 167.234H246.286C245.935 167.234 245.628 167.462 245.532 167.795L237.414 195.047C237.186 195.8 236.125 195.8 235.897 195.047L227.779 167.795C227.683 167.462 227.367 167.234 227.025 167.234H217.618C217.084 167.234 216.707 167.751 216.864 168.259L229.839 209.689Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M263.406 210.243H291.478C291.916 210.243 292.267 209.892 292.267 209.455V201.083C292.267 200.645 291.916 200.295 291.478 200.295H273.865C273.427 200.295 273.076 199.944 273.076 199.506V194.322C273.076 193.884 273.427 193.534 273.865 193.534H289.759C290.198 193.534 290.548 193.184 290.548 192.746V184.374C290.548 183.936 290.198 183.586 289.759 183.586H273.865C273.427 183.586 273.076 183.236 273.076 182.798V177.99C273.076 177.552 273.427 177.202 273.865 177.202H291.478C291.916 177.202 292.267 176.852 292.267 176.414V168.042C292.267 167.604 291.916 167.254 291.478 167.254H263.406C262.968 167.254 262.617 167.604 262.617 168.042V209.472C262.617 209.91 262.968 210.26 263.406 210.26V210.243Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M315.211 177.182H325.319C325.757 177.182 326.108 177.533 326.108 177.971V209.453C326.108 209.89 326.459 210.241 326.897 210.241H335.778C336.216 210.241 336.567 209.89 336.567 209.453V177.971C336.567 177.533 336.918 177.182 337.356 177.182H347.525C347.964 177.182 348.314 176.832 348.314 176.394V168.023C348.314 167.585 347.964 167.234 347.525 167.234H315.211C314.773 167.234 314.422 167.585 314.422 168.023V176.394C314.422 176.832 314.773 177.182 315.211 177.182Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M363.822 167.234H354.941C354.506 167.234 354.152 167.587 354.152 168.023V209.461C354.152 209.897 354.506 210.249 354.941 210.249H363.822C364.258 210.249 364.611 209.897 364.611 209.461V168.023C364.611 167.587 364.258 167.234 363.822 167.234Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M373.711 210.241H382.592C383.03 210.241 383.381 209.89 383.381 209.453V184.495C383.381 183.707 384.398 183.409 384.836 184.066L394.146 198.261H395.128L404.439 184.066C404.868 183.409 405.894 183.715 405.894 184.495V209.453C405.894 209.89 406.245 210.241 406.683 210.241H415.564C416.002 210.241 416.353 209.89 416.353 209.453V168.023C416.353 167.585 416.002 167.234 415.564 167.234H404.903C404.64 167.234 404.395 167.366 404.246 167.585L395.295 180.992C394.979 181.465 394.295 181.465 393.98 180.992L385.029 167.585C384.88 167.366 384.634 167.234 384.371 167.234H373.711C373.273 167.234 372.922 167.585 372.922 168.023V209.453C372.922 209.89 373.273 210.241 373.711 210.241Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M425.75 210.243H453.821C454.26 210.243 454.61 209.892 454.61 209.455V201.083C454.61 200.645 454.26 200.295 453.821 200.295H436.209C435.77 200.295 435.42 199.944 435.42 199.506V194.322C435.42 193.884 435.77 193.534 436.209 193.534H452.094C452.533 193.534 452.883 193.184 452.883 192.746V184.374C452.883 183.936 452.533 183.586 452.094 183.586H436.209C435.77 183.586 435.42 183.236 435.42 182.798V177.99C435.42 177.552 435.77 177.202 436.209 177.202H453.821C454.26 177.202 454.61 176.852 454.61 176.414V168.042C454.61 167.604 454.26 167.254 453.821 167.254H425.75C425.312 167.254 424.961 167.604 424.961 168.042V209.472C424.961 209.91 425.312 210.26 425.75 210.26V210.243Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M2.28633 276.289H25.4307C25.8165 276.289 26.1233 275.983 26.1233 275.597V268.241C26.1233 267.856 25.8165 267.549 25.4307 267.549H11.474C11.0882 267.549 10.7814 267.243 10.7814 266.858V239.211C10.7814 238.826 10.4745 238.52 10.0888 238.52H2.28633C1.90059 238.52 1.59375 238.826 1.59375 239.211V275.606C1.59375 275.991 1.90059 276.298 2.28633 276.298V276.289Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M31.9543 276.289H56.6066C56.9923 276.289 57.2992 275.983 57.2992 275.597V268.241C57.2992 267.856 56.9923 267.549 56.6066 267.549H41.1332C40.7474 267.549 40.4406 267.243 40.4406 266.858V262.304C40.4406 261.919 40.7474 261.612 41.1332 261.612H55.0899C55.4757 261.612 55.7825 261.306 55.7825 260.92V253.564C55.7825 253.179 55.4757 252.872 55.0899 252.872H41.1332C40.7474 252.872 40.4406 252.566 40.4406 252.181V247.951C40.4406 247.566 40.7474 247.259 41.1332 247.259H56.6066C56.9923 247.259 57.2992 246.953 57.2992 246.567V239.211C57.2992 238.826 56.9923 238.52 56.6066 238.52H31.9543C31.5686 238.52 31.2617 238.826 31.2617 239.211V275.606C31.2617 275.991 31.5686 276.298 31.9543 276.298V276.289Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M77.7786 276.997C83.8277 276.997 92.3666 274.081 92.3666 264.851C92.3666 257.136 87.3432 254.754 76.7529 252.494C73.027 251.689 71.3 251.47 71.3 249.692C71.3 248.072 72.5448 246.399 76.6477 246.399C80.7506 246.399 82.0218 247.888 82.6267 249.867C82.7144 250.147 82.9861 250.331 83.2842 250.331H91.262C91.6828 250.331 92.016 249.964 91.9546 249.552C91.0691 243.334 86.9224 237.809 76.6477 237.809C69.4589 237.809 62.0597 241.802 62.0597 250.112C62.0597 257.941 68.3806 260.201 74.5437 261.497C80.8646 262.845 83.1352 263.012 83.1352 265.218C83.1352 267.215 81.4607 268.406 77.7348 268.406C73.1848 268.406 71.4402 267.101 70.5372 264.316C70.4408 264.027 70.1778 263.826 69.871 263.826H61.8932C61.4723 263.826 61.1304 264.203 61.2006 264.623C62.2175 270.797 66.6974 276.997 77.7874 276.997H77.7786Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M113.388 276.997C119.437 276.997 127.976 274.081 127.976 264.851C127.976 257.136 122.953 254.754 112.362 252.494C108.636 251.689 106.909 251.47 106.909 249.692C106.909 248.072 108.154 246.399 112.257 246.399C116.36 246.399 117.631 247.888 118.236 249.867C118.324 250.147 118.596 250.331 118.894 250.331H126.871C127.292 250.331 127.625 249.964 127.564 249.552C126.679 243.334 122.532 237.809 112.257 237.809C105.068 237.809 97.6691 241.802 97.6691 250.112C97.6691 257.941 103.99 260.201 110.153 261.497C116.474 262.845 118.745 263.012 118.745 265.218C118.745 267.215 117.07 268.406 113.344 268.406C108.794 268.406 107.05 267.101 106.147 264.316C106.05 264.027 105.787 263.826 105.48 263.826H97.5025C97.0817 263.826 96.7398 264.203 96.81 264.623C97.8269 270.797 102.307 276.997 113.397 276.997H113.388Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M164.665 276.997C170.714 276.997 179.253 274.081 179.253 264.851C179.253 257.136 174.23 254.754 163.64 252.494C159.914 251.689 158.187 251.47 158.187 249.692C158.187 248.072 159.432 246.399 163.534 246.399C167.637 246.399 168.909 247.888 169.513 249.867C169.601 250.147 169.873 250.331 170.171 250.331H178.149C178.57 250.331 178.903 249.964 178.841 249.552C177.956 243.334 173.809 237.809 163.534 237.809C156.346 237.809 148.946 241.802 148.946 250.112C148.946 257.941 155.267 260.201 161.43 261.497C167.751 262.845 170.022 263.012 170.022 265.218C170.022 267.215 168.347 268.406 164.622 268.406C160.072 268.406 158.327 267.101 157.424 264.316C157.328 264.027 157.065 263.826 156.758 263.826H148.78C148.359 263.826 148.017 264.203 148.087 264.623C149.104 270.797 153.584 276.997 164.674 276.997H164.665Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M201.942 275.597V247.951C201.942 247.566 202.249 247.259 202.634 247.259H211.568C211.954 247.259 212.26 246.953 212.26 246.567V239.211C212.26 238.826 211.954 238.52 211.568 238.52H183.181C182.795 238.52 182.488 238.826 182.488 239.211V246.567C182.488 246.953 182.795 247.259 183.181 247.259H192.062C192.447 247.259 192.754 247.566 192.754 247.951V275.597C192.754 275.983 193.061 276.289 193.447 276.289H201.241C201.626 276.289 201.933 275.983 201.933 275.597H201.942Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M238.157 275.991C238.289 276.184 238.499 276.298 238.736 276.298H248.265C248.827 276.298 249.16 275.659 248.835 275.203L240.2 263.057C239.946 262.698 240.086 262.181 240.489 262.024C244.654 260.369 247.231 256.831 247.231 251.147C247.231 243.266 242.26 238.52 234.265 238.52H218.099C217.713 238.52 217.406 238.826 217.406 239.211V275.606C217.406 275.991 217.713 276.298 218.099 276.298H225.893C226.278 276.298 226.585 275.991 226.585 275.606V263.88C226.585 263.495 226.892 263.188 227.278 263.188H229.084C229.312 263.188 229.531 263.302 229.662 263.495L238.166 276L238.157 275.991ZM227.278 255.141C226.892 255.141 226.585 254.834 226.585 254.449V247.96C226.585 247.574 226.892 247.268 227.278 247.268H233.23C236.308 247.268 237.929 248.617 237.929 251.261C237.929 253.906 236.413 255.149 233.23 255.149H227.278V255.141Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M255.427 276.289H280.079C280.465 276.289 280.772 275.983 280.772 275.597V268.241C280.772 267.856 280.465 267.549 280.079 267.549H264.606C264.22 267.549 263.913 267.243 263.913 266.858V262.304C263.913 261.919 264.22 261.612 264.606 261.612H278.563C278.948 261.612 279.255 261.306 279.255 260.92V253.564C279.255 253.179 278.948 252.872 278.563 252.872H264.606C264.22 252.872 263.913 252.566 263.913 252.181V247.951C263.913 247.566 264.22 247.259 264.606 247.259H280.079C280.465 247.259 280.772 246.953 280.772 246.567V239.211C280.772 238.826 280.465 238.52 280.079 238.52H255.427C255.041 238.52 254.734 238.826 254.734 239.211V275.606C254.734 275.991 255.041 276.298 255.427 276.298V276.289Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M301.207 268.414C296.657 268.414 294.913 267.109 294.01 264.324C293.913 264.035 293.65 263.834 293.344 263.834H285.366C284.945 263.834 284.603 264.21 284.673 264.631C285.69 270.805 290.17 277.005 301.26 277.005C307.309 277.005 315.848 274.088 315.848 264.858C315.848 257.143 310.825 254.761 300.234 252.502C296.508 251.696 294.781 251.478 294.781 249.7C294.781 248.08 296.026 246.407 300.129 246.407C304.232 246.407 305.503 247.896 306.108 249.875C306.196 250.155 306.468 250.339 306.766 250.339H314.743C315.164 250.339 315.497 249.971 315.436 249.56C314.551 243.342 310.404 237.816 300.129 237.816C292.94 237.816 285.541 241.81 285.541 250.12C285.541 257.949 291.862 260.208 298.025 261.504C304.346 262.853 306.617 263.019 306.617 265.226C306.617 267.223 304.942 268.414 301.216 268.414H301.207Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M321.146 250.112C321.146 257.941 327.467 260.201 333.63 261.497C339.95 262.845 342.221 263.012 342.221 265.218C342.221 267.215 340.547 268.406 336.821 268.406C332.271 268.406 330.526 267.101 329.623 264.316C329.527 264.027 329.264 263.826 328.957 263.826H320.979C320.558 263.826 320.216 264.203 320.287 264.623C321.303 270.797 325.783 276.997 336.873 276.997C342.922 276.997 351.461 274.081 351.461 264.851C351.461 257.136 346.438 254.754 335.848 252.494C332.122 251.689 330.395 251.47 330.395 249.692C330.395 248.072 331.64 246.399 335.742 246.399C339.845 246.399 341.116 247.888 341.721 249.867C341.809 250.147 342.081 250.331 342.379 250.331H350.357C350.778 250.331 351.111 249.964 351.049 249.552C350.164 243.334 346.017 237.809 335.742 237.809C328.554 237.809 321.154 241.802 321.154 250.112H321.146Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M37.468 307.016C37.091 307.016 36.793 307.322 36.793 307.69V343.121C36.793 343.498 37.0998 343.796 37.468 343.796H53.4149C60.779 343.796 65.9865 339.96 65.9865 333.024C65.9865 329.32 64.5575 326.036 61.5592 324.399C61.1034 324.145 61.0683 323.523 61.4979 323.234C63.5932 321.789 64.7241 319.766 64.7241 316.692C64.7241 310.177 59.8322 307.024 53.4149 307.024H37.468V307.016ZM56.9304 332.236C56.9304 334.128 56.194 335.284 53.1957 335.284H46.4014C46.0244 335.284 45.7264 334.977 45.7264 334.609V329.863C45.7264 329.486 46.0332 329.189 46.4014 329.189H53.1957C56.194 329.189 56.9304 330.187 56.9304 332.236ZM55.6767 318.26C55.6767 320.204 54.5721 321.045 52.5733 321.045H46.4102C46.0332 321.045 45.7351 320.738 45.7351 320.37V316.202C45.7351 315.825 46.042 315.528 46.4102 315.528H52.205C54.3617 315.528 55.6767 316 55.6767 318.26Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M71.5078 307.69V343.121C71.5078 343.498 71.8146 343.796 72.1829 343.796H96.1864C96.5634 343.796 96.8615 343.489 96.8615 343.121V335.958C96.8615 335.581 96.5546 335.284 96.1864 335.284H81.125C80.748 335.284 80.45 334.977 80.45 334.609V330.178C80.45 329.802 80.7568 329.504 81.125 329.504H94.7136C95.0906 329.504 95.3886 329.197 95.3886 328.83V321.666C95.3886 321.29 95.0818 320.992 94.7136 320.992H81.125C80.748 320.992 80.45 320.686 80.45 320.318V316.202C80.45 315.825 80.7568 315.528 81.125 315.528H96.1864C96.5634 315.528 96.8615 315.221 96.8615 314.853V307.69C96.8615 307.313 96.5546 307.016 96.1864 307.016H72.1829C71.8059 307.016 71.5078 307.322 71.5078 307.69Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M100.687 315.528H109.331C109.708 315.528 110.006 315.834 110.006 316.202V343.121C110.006 343.498 110.313 343.796 110.681 343.796H118.273C118.65 343.796 118.948 343.489 118.948 343.121V316.202C118.948 315.825 119.255 315.528 119.623 315.528H128.32C128.697 315.528 128.995 315.221 128.995 314.853V307.69C128.995 307.313 128.688 307.016 128.32 307.016H100.687C100.31 307.016 100.012 307.322 100.012 307.69V314.853C100.012 315.23 100.319 315.528 100.687 315.528Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M151.612 343.114V316.194C151.612 315.818 151.919 315.52 152.287 315.52H160.984C161.361 315.52 161.659 315.213 161.659 314.845V307.682C161.659 307.306 161.352 307.008 160.984 307.008H133.351C132.974 307.008 132.676 307.314 132.676 307.682V314.845C132.676 315.222 132.983 315.52 133.351 315.52H141.995C142.372 315.52 142.67 315.826 142.67 316.194V343.114C142.67 343.49 142.977 343.788 143.345 343.788H150.937C151.314 343.788 151.612 343.481 151.612 343.114Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M176.273 315.528H191.335C191.712 315.528 192.01 315.221 192.01 314.853V307.69C192.01 307.313 191.703 307.016 191.335 307.016H167.331C166.954 307.016 166.656 307.322 166.656 307.69V343.121C166.656 343.498 166.963 343.796 167.331 343.796H191.335C191.712 343.796 192.01 343.489 192.01 343.121V335.958C192.01 335.581 191.703 335.284 191.335 335.284H176.273C175.896 335.284 175.598 334.977 175.598 334.609V330.178C175.598 329.802 175.905 329.504 176.273 329.504H189.862C190.239 329.504 190.537 329.197 190.537 328.83V321.666C190.537 321.29 190.23 320.992 189.862 320.992H176.273C175.896 320.992 175.598 320.686 175.598 320.318V316.202C175.598 315.825 175.905 315.528 176.273 315.528Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M218.826 343.788H228.102C228.654 343.788 228.969 343.166 228.654 342.719L220.246 330.897C220.001 330.547 220.133 330.048 220.536 329.89C224.595 328.279 227.102 324.829 227.102 319.303C227.102 311.632 222.263 307.008 214.478 307.008H198.741C198.364 307.008 198.066 307.314 198.066 307.682V343.114C198.066 343.49 198.373 343.788 198.741 343.788H206.334C206.71 343.788 207.009 343.481 207.009 343.114V331.694C207.009 331.318 207.315 331.02 207.684 331.02H209.437C209.665 331.02 209.867 331.134 209.998 331.318L218.274 343.49C218.397 343.674 218.607 343.788 218.835 343.788H218.826ZM213.47 323.2H207.675C207.298 323.2 207 322.893 207 322.525V316.203C207 315.826 207.307 315.529 207.675 315.529H213.47C216.468 315.529 218.046 316.842 218.046 319.417C218.046 321.991 216.573 323.2 213.47 323.2Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M272.657 329.898C276.716 328.287 279.223 324.836 279.223 319.311C279.223 311.639 274.384 307.016 266.599 307.016H250.863C250.486 307.016 250.188 307.322 250.188 307.69V343.121C250.188 343.498 250.494 343.796 250.863 343.796H258.455C258.832 343.796 259.13 343.489 259.13 343.121V331.702C259.13 331.325 259.437 331.028 259.805 331.028H261.558C261.786 331.028 261.988 331.142 262.119 331.325L270.395 343.498C270.518 343.682 270.728 343.796 270.956 343.796H280.231C280.784 343.796 281.099 343.174 280.784 342.727L272.376 330.905C272.131 330.555 272.262 330.056 272.666 329.898H272.657ZM265.6 323.199H259.805C259.428 323.199 259.13 322.892 259.13 322.525V316.202C259.13 315.825 259.437 315.528 259.805 315.528H265.6C268.598 315.528 270.176 316.841 270.176 319.416C270.176 321.99 268.703 323.199 265.6 323.199Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M286.531 307.69V343.121C286.531 343.498 286.838 343.796 287.206 343.796H311.21C311.587 343.796 311.885 343.489 311.885 343.121V335.958C311.885 335.581 311.578 335.284 311.21 335.284H296.148C295.771 335.284 295.473 334.977 295.473 334.609V330.178C295.473 329.802 295.78 329.504 296.148 329.504H309.737C310.114 329.504 310.412 329.197 310.412 328.83V321.666C310.412 321.29 310.105 320.992 309.737 320.992H296.148C295.771 320.992 295.473 320.686 295.473 320.318V316.202C295.473 315.825 295.78 315.528 296.148 315.528H311.21C311.587 315.528 311.885 315.221 311.885 314.853V307.69C311.885 307.313 311.578 307.016 311.21 307.016H287.206C286.829 307.016 286.531 307.322 286.531 307.69Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M331.771 336.116C327.344 336.116 325.643 334.846 324.767 332.132C324.679 331.851 324.416 331.65 324.118 331.65H316.351C315.938 331.65 315.614 332.018 315.675 332.421C316.666 338.428 321.023 344.47 331.824 344.47C337.715 344.47 346.026 341.633 346.026 332.648C346.026 325.135 341.134 322.823 330.825 320.616C327.195 319.828 325.512 319.618 325.512 317.884C325.512 316.307 326.722 314.679 330.719 314.679C334.717 314.679 335.944 316.123 336.541 318.059C336.628 318.33 336.891 318.514 337.18 318.514H344.948C345.351 318.514 345.684 318.155 345.623 317.752C344.755 311.701 340.722 306.324 330.728 306.324C323.732 306.324 316.526 310.212 316.526 318.304C316.526 325.923 322.68 328.13 328.677 329.391C334.831 330.704 337.04 330.862 337.04 333.016C337.04 334.96 335.41 336.116 331.78 336.116H331.771Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M351.547 307.69L351.599 328.768C351.599 339.224 358.227 344.479 367.064 344.479C375.901 344.479 382.529 339.224 382.529 328.768L382.581 307.69C382.581 307.313 382.283 307.016 381.906 307.016H374.157C373.78 307.016 373.481 307.322 373.481 307.69V328.243C373.481 334.023 370.904 335.967 367.064 335.967C363.224 335.967 360.647 334.023 360.647 328.243V307.69C360.647 307.313 360.34 307.016 359.972 307.016H352.222C351.845 307.016 351.547 307.322 351.547 307.69Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M413.564 343.114V335.95C413.564 335.574 413.258 335.276 412.889 335.276H399.301C398.924 335.276 398.626 334.969 398.626 334.602V307.682C398.626 307.306 398.319 307.008 397.951 307.008H390.359C389.982 307.008 389.684 307.314 389.684 307.682V343.114C389.684 343.49 389.99 343.788 390.359 343.788H412.889C413.266 343.788 413.564 343.481 413.564 343.114Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M409.883 307.69V314.853C409.883 315.23 410.19 315.528 410.558 315.528H419.202C419.579 315.528 419.877 315.834 419.877 316.202V343.121C419.877 343.498 420.184 343.796 420.552 343.796H428.144C428.521 343.796 428.819 343.489 428.819 343.121V316.202C428.819 315.825 429.126 315.528 429.494 315.528H438.191C438.568 315.528 438.866 315.221 438.866 314.853V307.69C438.866 307.313 438.559 307.016 438.191 307.016H410.558C410.181 307.016 409.883 307.322 409.883 307.69Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M457.277 320.624C453.647 319.836 451.964 319.625 451.964 317.891C451.964 316.315 453.174 314.686 457.171 314.686C461.169 314.686 462.397 316.131 462.993 318.067C463.08 318.338 463.343 318.522 463.633 318.522H471.4C471.803 318.522 472.136 318.163 472.075 317.76C471.207 311.709 467.174 306.332 457.18 306.332C450.184 306.332 442.978 310.22 442.978 318.312C442.978 325.93 449.132 328.137 455.129 329.398C461.283 330.712 463.492 330.87 463.492 333.024C463.492 334.968 461.862 336.124 458.232 336.124C453.805 336.124 452.104 334.854 451.228 332.139C451.14 331.859 450.877 331.658 450.579 331.658H442.811C442.399 331.658 442.075 332.025 442.136 332.428C443.127 338.436 447.484 344.478 458.276 344.478C464.167 344.478 472.478 341.641 472.478 332.656C472.478 325.142 467.586 322.83 457.277 320.624Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M203.416 358.772H210.271C210.648 358.772 210.946 358.465 210.946 358.097V355.19C210.946 354.813 210.64 354.516 210.271 354.516H198.945C198.568 354.516 198.27 354.822 198.27 355.19V372.231C198.27 372.608 198.576 372.906 198.945 372.906H202.066C202.443 372.906 202.741 372.599 202.741 372.231V366.618C202.741 366.241 203.047 365.944 203.416 365.944H209.535C209.912 365.944 210.21 365.637 210.21 365.269V362.362C210.21 361.985 209.903 361.688 209.535 361.688H203.416C203.039 361.688 202.741 361.381 202.741 361.013V359.446C202.741 359.069 203.047 358.772 203.416 358.772Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M214.381 372.906H217.502C217.879 372.906 218.177 372.599 218.177 372.231V367.196C218.177 366.819 218.484 366.522 218.852 366.522H219.212C219.44 366.522 219.641 366.636 219.773 366.819L223.709 372.608C223.832 372.792 224.042 372.906 224.27 372.906H228.075C228.627 372.906 228.943 372.284 228.627 371.837L225.042 366.793C224.796 366.452 224.928 365.979 225.305 365.804C227.128 364.945 228.233 363.264 228.233 360.663C228.233 356.828 225.813 354.516 221.921 354.516H214.39C214.013 354.516 213.715 354.822 213.715 355.19V372.231C213.715 372.608 214.022 372.906 214.39 372.906H214.381ZM218.177 359.446C218.177 359.069 218.484 358.772 218.852 358.772H221.412C222.911 358.772 223.7 359.428 223.7 360.716C223.7 362.003 222.964 362.607 221.412 362.607H218.852C218.475 362.607 218.177 362.301 218.177 361.933V359.446Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M239.927 373.249C245.371 373.249 249.553 369.124 249.553 363.712C249.553 358.3 245.371 354.176 239.927 354.176C234.483 354.176 230.301 358.3 230.301 363.712C230.301 369.124 234.483 373.249 239.927 373.249ZM239.927 358.432C242.872 358.432 245.029 360.639 245.029 363.712C245.029 366.786 242.872 368.993 239.927 368.993C236.981 368.993 234.824 366.786 234.824 363.712C234.824 360.639 236.981 358.432 239.927 358.432Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M261.811 367.783L265.169 362.66C265.537 362.099 266.413 362.362 266.413 363.028V372.231C266.413 372.608 266.72 372.906 267.089 372.906H270.209C270.586 372.906 270.885 372.599 270.885 372.231V355.19C270.885 354.813 270.578 354.516 270.209 354.516H266.168C265.94 354.516 265.73 354.629 265.607 354.813L262.162 359.971C261.89 360.374 261.302 360.374 261.039 359.971L257.594 354.813C257.471 354.629 257.261 354.516 257.033 354.516H252.991C252.614 354.516 252.316 354.822 252.316 355.19V372.231C252.316 372.608 252.623 372.906 252.991 372.906H256.112C256.489 372.906 256.787 372.599 256.787 372.231V363.028C256.787 362.353 257.664 362.099 258.032 362.66L261.39 367.783H261.811Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M291.347 358.432C293.144 358.432 294.556 359.255 295.345 360.639C295.467 360.857 295.686 361.006 295.941 361.006H299.404C299.877 361.006 300.201 360.533 300.044 360.096C298.781 356.593 295.52 354.176 291.356 354.176C285.833 354.176 281.809 358.3 281.809 363.712C281.809 369.124 285.833 373.249 291.356 373.249C295.529 373.249 298.781 370.832 300.044 367.329C300.201 366.891 299.877 366.418 299.404 366.418H295.941C295.686 366.418 295.467 366.567 295.345 366.786C294.556 368.17 293.144 368.993 291.347 368.993C288.296 368.993 286.323 366.786 286.323 363.712C286.323 360.639 288.296 358.432 291.347 358.432Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M307.616 354.962L301.427 372.004C301.269 372.441 301.594 372.906 302.058 372.906H305.346C305.635 372.906 305.889 372.722 305.986 372.45L306.985 369.552C307.082 369.28 307.336 369.096 307.625 369.096H313.552C313.841 369.096 314.095 369.28 314.192 369.552L315.191 372.45C315.287 372.722 315.542 372.906 315.831 372.906H319.119C319.583 372.906 319.916 372.441 319.75 372.004L313.56 354.962C313.464 354.7 313.21 354.516 312.929 354.516H308.248C307.967 354.516 307.713 354.691 307.616 354.962ZM308.932 363.947L309.957 360.97C310.168 360.365 311.027 360.365 311.237 360.97L312.263 363.947C312.412 364.385 312.088 364.84 311.623 364.84H309.571C309.107 364.84 308.782 364.385 308.932 363.947Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M323.136 372.906H326.257C326.634 372.906 326.932 372.599 326.932 372.231V363.028C326.932 362.353 327.809 362.099 328.177 362.66L331.535 367.783H331.955L335.313 362.66C335.681 362.099 336.558 362.362 336.558 363.028V372.231C336.558 372.608 336.865 372.906 337.233 372.906H340.354C340.731 372.906 341.029 372.599 341.029 372.231V355.19C341.029 354.813 340.722 354.516 340.354 354.516H336.313C336.085 354.516 335.874 354.629 335.751 354.813L332.306 359.971C332.034 360.374 331.447 360.374 331.184 359.971L327.739 354.813C327.616 354.629 327.405 354.516 327.177 354.516H323.136C322.759 354.516 322.461 354.822 322.461 355.19V372.231C322.461 372.608 322.768 372.906 323.136 372.906Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M359.229 360.821C359.229 356.749 356.494 354.516 352.601 354.516H345.386C345.009 354.516 344.711 354.822 344.711 355.19V372.231C344.711 372.608 345.018 372.906 345.386 372.906H348.507C348.884 372.906 349.182 372.599 349.182 372.231V367.905C349.182 367.529 349.489 367.231 349.857 367.231H352.601C356.494 367.231 359.229 364.893 359.229 360.821ZM352.417 362.975H349.857C349.48 362.975 349.182 362.669 349.182 362.301V359.446C349.182 359.069 349.489 358.772 349.857 358.772H352.417C353.942 358.772 354.705 359.481 354.705 360.873C354.705 362.266 353.942 362.975 352.417 362.975Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M370.144 354.516H365.463C365.182 354.516 364.928 354.691 364.831 354.962L358.642 372.004C358.484 372.441 358.809 372.906 359.273 372.906H362.561C362.85 372.906 363.104 372.722 363.201 372.45L364.2 369.552C364.297 369.28 364.551 369.096 364.84 369.096H370.766C371.056 369.096 371.31 369.28 371.406 369.552L372.406 372.45C372.502 372.722 372.757 372.906 373.046 372.906H376.333C376.798 372.906 377.131 372.441 376.965 372.004L370.775 354.962C370.679 354.7 370.425 354.516 370.144 354.516ZM368.829 364.84H366.778C366.313 364.84 365.989 364.385 366.138 363.947L367.163 360.97C367.374 360.365 368.233 360.365 368.443 360.97L369.469 363.947C369.618 364.385 369.294 364.84 368.829 364.84Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M383.589 354.516H380.468C380.095 354.516 379.793 354.818 379.793 355.19V372.231C379.793 372.604 380.095 372.906 380.468 372.906H383.589C383.962 372.906 384.264 372.604 384.264 372.231V355.19C384.264 354.818 383.962 354.516 383.589 354.516Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M396.26 373.247C398.25 373.247 399.855 372.642 400.924 371.732C401.371 371.355 402.046 371.653 402.046 372.231C402.046 372.607 402.353 372.905 402.721 372.905H405.185C405.562 372.905 405.86 372.599 405.86 372.231V363.412C405.86 363.036 405.553 362.738 405.185 362.738H396.304C395.927 362.738 395.629 363.044 395.629 363.412V365.794C395.629 366.171 395.936 366.469 396.304 366.469H399.89C400.459 366.469 400.793 367.143 400.416 367.572C399.521 368.605 398.145 369.122 396.593 369.122C393.306 369.122 391.675 366.758 391.675 363.815C391.675 360.584 393.648 358.429 396.698 358.429C398.487 358.429 399.898 359.165 400.687 360.426C400.819 360.628 401.029 360.768 401.266 360.768H404.729C405.202 360.768 405.535 360.286 405.36 359.839C403.414 354.708 396.891 352.046 389.545 356.258C389.457 356.31 389.334 356.433 389.282 356.52C384.083 365.453 389.133 373.238 396.243 373.238L396.26 373.247Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M420.683 372.906H424.698C425.075 372.906 425.373 372.599 425.373 372.231V355.19C425.373 354.813 425.066 354.516 424.698 354.516H421.577C421.2 354.516 420.902 354.822 420.902 355.19V362.178C420.902 362.178 420.902 362.222 420.902 362.239L421.244 366.101L414.546 354.848C414.423 354.647 414.204 354.516 413.968 354.516H409.952C409.575 354.516 409.277 354.822 409.277 355.19V372.231C409.277 372.608 409.584 372.906 409.952 372.906H413.073C413.45 372.906 413.748 372.599 413.748 372.231V365.243C413.748 365.243 413.748 365.199 413.748 365.182L413.38 361.329L420.104 372.582C420.227 372.783 420.446 372.914 420.683 372.914V372.906Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M443.232 367.338C443.232 363.581 440.786 362.425 435.631 361.322C433.816 360.928 432.975 360.822 432.975 359.956C432.975 359.167 433.58 358.353 435.579 358.353C437.393 358.353 438.095 358.975 438.419 359.842C438.515 360.104 438.77 360.271 439.041 360.271H442.285C442.715 360.271 443.03 359.877 442.951 359.456C442.39 356.61 440.33 354.176 435.579 354.176C432.081 354.176 428.477 356.12 428.477 360.166C428.477 363.975 431.555 365.078 434.553 365.709C437.63 366.366 438.735 366.445 438.735 367.522C438.735 368.494 437.919 369.072 436.105 369.072C433.992 369.072 433.124 368.494 432.659 367.259C432.563 367.005 432.308 366.839 432.037 366.839H428.802C428.372 366.839 428.048 367.233 428.135 367.653C428.767 370.508 430.985 373.249 436.122 373.249C439.068 373.249 443.223 371.83 443.223 367.338H443.232Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M371.091 242.451H377.008C377.394 242.451 377.701 242.145 377.701 241.759V239.36C377.701 238.974 377.394 238.668 377.008 238.668H367.111C366.725 238.668 366.418 238.974 366.418 239.36V254.343C366.418 254.729 366.725 255.035 367.111 255.035H369.706C370.091 255.035 370.398 254.729 370.398 254.343V249.536C370.398 249.15 370.705 248.844 371.091 248.844H376.351C376.737 248.844 377.043 248.537 377.043 248.152V245.752C377.043 245.367 376.737 245.061 376.351 245.061H371.091C370.705 245.061 370.398 244.754 370.398 244.369V243.16C370.398 242.775 370.705 242.469 371.091 242.469V242.451Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M390.579 248.669C392.14 247.889 393.086 246.409 393.086 244.141C393.086 240.726 390.93 238.668 387.467 238.668H380.857C380.471 238.668 380.164 238.974 380.164 239.36V254.343C380.164 254.729 380.471 255.035 380.857 255.035H383.452C383.837 255.035 384.144 254.729 384.144 254.343V249.352H385.021C385.249 249.352 385.468 249.466 385.599 249.658L389.054 254.737C389.185 254.93 389.396 255.044 389.632 255.044H392.788C393.349 255.044 393.683 254.405 393.358 253.949L390.334 249.693C390.088 249.343 390.22 248.861 390.597 248.669H390.579ZM387.02 245.866H384.837C384.451 245.866 384.144 245.56 384.144 245.175V243.143C384.144 242.758 384.451 242.451 384.837 242.451H387.02C388.352 242.451 389.054 243.038 389.054 244.185C389.054 245.332 388.396 245.866 387.02 245.866Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M403.5 255.34C408.348 255.34 412.074 251.671 412.074 246.846C412.074 242.021 408.348 238.352 403.5 238.352C398.652 238.352 394.926 242.021 394.926 246.846C394.926 251.671 398.652 255.34 403.5 255.34ZM403.5 242.152C406.121 242.152 408.041 244.114 408.041 246.855C408.041 249.596 406.121 251.557 403.5 251.557C400.878 251.557 398.959 249.596 398.959 246.855C398.959 244.114 400.878 242.152 403.5 242.152Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M426.336 238.974L423.373 243.414C423.101 243.826 422.496 243.826 422.216 243.414L419.253 238.974C419.121 238.782 418.911 238.668 418.674 238.668H415.22C414.834 238.668 414.527 238.974 414.527 239.36V254.343C414.527 254.729 414.834 255.035 415.22 255.035H417.815C418.201 255.035 418.507 254.729 418.507 254.343V246.549C418.507 245.858 419.402 245.595 419.779 246.173L422.602 250.473H422.979L425.802 246.173C426.178 245.595 427.073 245.866 427.073 246.549V254.343C427.073 254.729 427.38 255.035 427.765 255.035H430.36C430.746 255.035 431.053 254.729 431.053 254.343V239.36C431.053 238.974 430.746 238.668 430.36 238.668H426.906C426.678 238.668 426.459 238.782 426.327 238.974H426.336Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M366.418 275.938C366.418 276.323 366.725 276.63 367.111 276.63H372.528C377.306 276.63 380.91 273.101 380.91 268.442C380.91 263.783 377.306 260.254 372.528 260.254H367.111C366.725 260.254 366.418 260.56 366.418 260.946V275.929V275.938ZM370.398 264.738C370.398 264.352 370.705 264.046 371.091 264.046H372.528C376.324 264.046 378.42 267.829 375.693 272.496C375.571 272.707 375.334 272.838 375.088 272.838H371.091C370.705 272.838 370.398 272.531 370.398 272.146V264.738Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M383.484 260.947V275.93C383.484 276.316 383.791 276.622 384.177 276.622H394.075C394.46 276.622 394.767 276.316 394.767 275.93V273.531C394.767 273.145 394.46 272.839 394.075 272.839H388.157C387.771 272.839 387.465 272.532 387.465 272.147V270.965C387.465 270.58 387.771 270.273 388.157 270.273H393.417C393.803 270.273 394.11 269.967 394.11 269.581V267.182C394.11 266.797 393.803 266.49 393.417 266.49H388.157C387.771 266.49 387.465 266.184 387.465 265.798V264.756C387.465 264.371 387.771 264.064 388.157 264.064H394.075C394.46 264.064 394.767 263.758 394.767 263.373V260.973C394.767 260.588 394.46 260.281 394.075 260.281H384.177C383.791 260.281 383.484 260.588 383.484 260.973V260.947Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M399.898 276.629C400.197 276.629 400.46 276.445 400.556 276.165L401.406 273.704C401.503 273.424 401.766 273.24 402.064 273.24H407.21C407.508 273.24 407.771 273.424 407.867 273.704L408.718 276.165C408.814 276.445 409.077 276.629 409.375 276.629H412.102C412.584 276.629 412.917 276.147 412.751 275.701L407.306 260.717C407.21 260.446 406.947 260.262 406.658 260.262H402.616C402.327 260.262 402.064 260.446 401.967 260.717L396.523 275.701C396.357 276.156 396.699 276.629 397.172 276.629H399.898ZM403.186 268.528L403.966 266.252C404.185 265.63 405.062 265.63 405.281 266.252L406.062 268.528C406.219 268.975 405.877 269.448 405.404 269.448H403.835C403.361 269.448 403.019 268.975 403.177 268.528H403.186Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M429.8 268.442C429.8 263.792 426.197 260.254 421.419 260.254H416.001C415.615 260.254 415.309 260.56 415.309 260.946V275.929C415.309 276.314 415.615 276.621 416.001 276.621H421.419C426.197 276.621 429.8 273.092 429.8 268.433V268.442ZM424.575 272.496C424.452 272.707 424.216 272.838 423.97 272.838H419.973C419.587 272.838 419.28 272.531 419.28 272.146V264.738C419.28 264.352 419.587 264.046 419.973 264.046H421.41C425.206 264.046 427.302 267.829 424.575 272.496Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M433.071 276.629H442.312C442.697 276.629 443.004 276.322 443.004 275.937V273.538C443.004 273.152 442.697 272.846 442.312 272.846H437.052C436.666 272.846 436.359 272.539 436.359 272.154V260.954C436.359 260.568 436.052 260.262 435.666 260.262H433.071C432.686 260.262 432.379 260.568 432.379 260.954V275.937C432.379 276.322 432.686 276.629 433.071 276.629Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M448.518 260.254H445.923C445.541 260.254 445.23 260.564 445.23 260.946V275.938C445.23 276.32 445.541 276.63 445.923 276.63H448.518C448.901 276.63 449.211 276.32 449.211 275.938V260.946C449.211 260.564 448.901 260.254 448.518 260.254Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M453.069 276.629H455.664C456.049 276.629 456.356 276.322 456.356 275.937V269.807C456.356 269.807 456.356 269.763 456.356 269.746L456.032 266.313L461.984 276.287C462.107 276.497 462.335 276.629 462.581 276.629H466.008C466.394 276.629 466.701 276.322 466.701 275.937V260.954C466.701 260.568 466.394 260.262 466.008 260.262H463.413C463.028 260.262 462.721 260.568 462.721 260.954V267.084C462.721 267.084 462.721 267.127 462.721 267.145L463.019 270.578L457.084 260.603C456.961 260.393 456.733 260.262 456.488 260.262H453.06C452.674 260.262 452.367 260.568 452.367 260.954V275.937C452.367 276.322 452.674 276.629 453.06 276.629H453.069Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M470.556 276.63H480.454C480.839 276.63 481.146 276.323 481.146 275.938V273.539C481.146 273.153 480.839 272.847 480.454 272.847H474.536C474.15 272.847 473.843 272.54 473.843 272.155V270.973C473.843 270.587 474.15 270.281 474.536 270.281H479.796C480.182 270.281 480.489 269.974 480.489 269.589V267.19C480.489 266.804 480.182 266.498 479.796 266.498H474.536C474.15 266.498 473.843 266.191 473.843 265.806V264.764C473.843 264.379 474.15 264.072 474.536 264.072H480.454C480.839 264.072 481.146 263.766 481.146 263.38V260.981C481.146 260.596 480.839 260.289 480.454 260.289H470.556C470.17 260.289 469.863 260.596 469.863 260.981V275.964C469.863 276.35 470.17 276.656 470.556 276.656V276.63Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M490.019 276.925C492.641 276.925 496.34 275.664 496.34 271.662C496.34 268.317 494.166 267.293 489.572 266.303C487.959 265.953 487.205 265.856 487.205 265.086C487.205 264.385 487.74 263.658 489.52 263.658C491.098 263.658 491.729 264.184 492.027 264.928C492.132 265.191 492.386 265.366 492.667 265.366H495.376C495.814 265.366 496.156 264.963 496.06 264.534C495.534 262.047 493.693 259.945 489.52 259.945C486.407 259.945 483.199 261.679 483.199 265.278C483.199 268.667 485.934 269.648 488.608 270.217C491.343 270.804 492.334 270.874 492.334 271.829C492.334 272.696 491.606 273.212 489.993 273.212C488.134 273.212 487.354 272.713 486.942 271.636C486.846 271.382 486.583 271.225 486.311 271.225H483.611C483.164 271.225 482.83 271.636 482.927 272.074C483.523 274.57 485.513 276.934 490.01 276.934L490.019 276.925Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M68.1689 406.701C71.0356 406.701 73.2712 408.076 74.4459 410.361C74.5336 410.536 74.7089 410.65 74.9018 410.65H81.249C81.5909 410.65 81.8451 410.317 81.7487 409.993C80.0129 404.214 74.858 400.168 68.1689 400.168C59.6914 400.168 53.5195 406.499 53.5195 414.801C53.5195 423.103 59.6914 429.434 68.1689 429.434C74.858 429.434 80.0129 425.389 81.7487 419.609C81.8451 419.285 81.5909 418.952 81.249 418.952H74.9018C74.7089 418.952 74.5336 419.066 74.4459 419.241C73.2712 421.527 71.0356 422.902 68.1689 422.902C63.4874 422.902 60.4629 419.512 60.4629 414.801C60.4629 410.09 63.4874 406.701 68.1689 406.701Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M114.501 414.801C114.501 406.499 108.084 400.168 99.7291 400.168C91.3743 400.168 84.957 406.499 84.957 414.801C84.957 423.103 91.3743 429.434 99.7291 429.434C108.084 429.434 114.501 423.103 114.501 414.801ZM91.9004 414.801C91.9004 410.081 95.2054 406.701 99.7291 406.701C104.253 406.701 107.558 410.09 107.558 414.801C107.558 419.512 104.253 422.902 99.7291 422.902C95.2054 422.902 91.9004 419.512 91.9004 414.801Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M121.759 435.284L117.113 439.339C116.999 439.435 116.938 439.575 116.938 439.733V441.826C116.938 442.115 117.165 442.342 117.455 442.342H129.167C129.457 442.342 129.684 442.115 129.684 441.826V439.058C129.684 438.769 129.457 438.542 129.167 438.542H123.495L123.539 438.515L125.064 437.176C127.651 434.916 129.369 433.489 129.369 430.923C129.369 428.077 127.23 425.59 123.513 425.59C120.085 425.59 117.542 427.674 117.157 431.037C117.122 431.352 117.358 431.632 117.674 431.632H120.69C120.935 431.632 121.146 431.466 121.198 431.229C121.435 430.021 122.092 429.408 123.32 429.408C124.828 429.408 125.319 430.327 125.319 431.124C125.319 432.114 124.828 432.604 121.742 435.31L121.759 435.284Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M152.822 407.223H164.377C164.666 407.223 164.894 406.996 164.894 406.707V401.216C164.894 400.927 164.666 400.699 164.377 400.699H145.966C145.677 400.699 145.449 400.927 145.449 401.216V428.398C145.449 428.687 145.677 428.915 145.966 428.915H151.788C152.077 428.915 152.305 428.687 152.305 428.398V418.748C152.305 418.459 152.533 418.231 152.822 418.231H163.246C163.535 418.231 163.763 418.003 163.763 417.714V412.224C163.763 411.935 163.535 411.707 163.246 411.707H152.822C152.533 411.707 152.305 411.479 152.305 411.19V407.749C152.305 407.46 152.533 407.232 152.822 407.232V407.223Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M182.69 400.168C174.335 400.168 167.918 406.499 167.918 414.801C167.918 423.103 174.335 429.434 182.69 429.434C191.045 429.434 197.462 423.103 197.462 414.801C197.462 406.499 191.045 400.168 182.69 400.168ZM182.69 422.902C178.166 422.902 174.861 419.512 174.861 414.801C174.861 410.09 178.166 406.701 182.69 406.701C187.214 406.701 190.519 410.09 190.519 414.801C190.519 419.512 187.214 422.902 182.69 422.902Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M215.46 429.434C223.814 429.434 230.232 423.103 230.232 414.801C230.232 406.499 223.814 400.168 215.46 400.168C207.105 400.168 200.688 406.499 200.688 414.801C200.688 423.103 207.105 429.434 215.46 429.434ZM215.46 406.701C219.983 406.701 223.288 410.09 223.288 414.801C223.288 419.512 219.983 422.902 215.46 422.902C210.936 422.902 207.631 419.512 207.631 414.801C207.631 410.09 210.936 406.701 215.46 406.701Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M240.515 407.74V428.389C240.515 428.678 240.743 428.906 241.032 428.906H246.853C247.142 428.906 247.37 428.678 247.37 428.389V407.74C247.37 407.451 247.598 407.223 247.888 407.223H254.559C254.848 407.223 255.076 406.996 255.076 406.707V401.216C255.076 400.927 254.848 400.699 254.559 400.699H233.361C233.072 400.699 232.844 400.927 232.844 401.216V406.707C232.844 406.996 233.072 407.223 233.361 407.223H239.989C240.278 407.223 240.506 407.451 240.506 407.74H240.515Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M281.194 410.368C281.194 404.124 276.995 400.691 271.025 400.691H259.435C259.146 400.691 258.918 400.919 258.918 401.208V428.39C258.918 428.679 259.146 428.907 259.435 428.907H265.256C265.546 428.907 265.774 428.679 265.774 428.39V420.719C265.774 420.43 266.002 420.202 266.291 420.202H271.016C276.986 420.202 281.186 416.612 281.186 410.368H281.194ZM266.3 413.669C266.01 413.669 265.782 413.442 265.782 413.153V407.741C265.782 407.452 266.01 407.224 266.3 407.224H270.744C273.085 407.224 274.251 408.31 274.251 410.447C274.251 412.584 273.076 413.669 270.744 413.669H266.3Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M285.639 401.207V428.389C285.639 428.678 285.867 428.906 286.156 428.906H291.977C292.266 428.906 292.494 428.678 292.494 428.389V419.632C292.494 419.343 292.722 419.115 293.012 419.115H294.353C294.528 419.115 294.686 419.203 294.782 419.343L301.13 428.687C301.226 428.827 301.384 428.915 301.559 428.915H308.678C309.099 428.915 309.344 428.442 309.099 428.1L302.646 419.028C302.453 418.756 302.559 418.371 302.865 418.257C305.978 417.014 307.898 414.378 307.898 410.131C307.898 404.246 304.189 400.699 298.21 400.699H286.138C285.849 400.699 285.621 400.927 285.621 401.216L285.639 401.207ZM292.494 407.74C292.494 407.451 292.722 407.223 293.012 407.223H297.456C299.753 407.223 300.963 408.23 300.963 410.209C300.963 412.189 299.832 413.108 297.456 413.108H293.012C292.722 413.108 292.494 412.88 292.494 412.591V407.74Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M319.057 400.691H313.236C312.95 400.691 312.719 400.923 312.719 401.208V428.39C312.719 428.676 312.95 428.907 313.236 428.907H319.057C319.343 428.907 319.574 428.676 319.574 428.39V401.208C319.574 400.923 319.343 400.691 319.057 400.691Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M325.027 401.209V428.391C325.027 428.68 325.255 428.908 325.545 428.908H331.366C331.655 428.908 331.883 428.68 331.883 428.391V417.121C331.883 417.121 331.883 417.086 331.883 417.068L331.541 413.416C331.488 412.865 332.216 412.628 332.505 413.101L341.79 428.654C341.886 428.812 342.053 428.908 342.237 428.908H349.215C349.504 428.908 349.732 428.68 349.732 428.391V401.209C349.732 400.92 349.504 400.692 349.215 400.692H343.394C343.105 400.692 342.877 400.92 342.877 401.209V412.479C342.877 412.479 342.877 412.506 342.877 412.523L343.201 416.201C343.254 416.753 342.517 416.981 342.237 416.508L332.979 400.938C332.882 400.78 332.716 400.684 332.532 400.684H325.553C325.264 400.684 325.036 400.911 325.036 401.2L325.027 401.209Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M353.87 407.223H360.497C360.787 407.223 361.015 407.451 361.015 407.74V428.389C361.015 428.678 361.243 428.906 361.532 428.906H367.353C367.642 428.906 367.87 428.678 367.87 428.389V407.74C367.87 407.451 368.098 407.223 368.388 407.223H375.059C375.348 407.223 375.576 406.996 375.576 406.707V401.216C375.576 400.927 375.348 400.699 375.059 400.699H353.861C353.572 400.699 353.344 400.927 353.344 401.216V406.707C353.344 406.996 353.572 407.223 353.861 407.223H353.87Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M149.7 455.512C149.753 455.749 149.963 455.906 150.2 455.906H153.619C153.856 455.906 154.066 455.74 154.119 455.512L155.249 450.836C155.249 450.836 155.249 450.801 155.258 450.784L155.96 445.81L156.301 445.827L157.02 450.784C157.02 450.784 157.02 450.819 157.029 450.827L158.16 455.504C158.213 455.74 158.423 455.898 158.66 455.898H162.061C162.298 455.898 162.508 455.731 162.561 455.504L165.717 442.429C165.796 442.105 165.55 441.79 165.217 441.79H162.771C162.535 441.79 162.324 441.956 162.263 442.184L160.746 448.507C160.746 448.507 160.746 448.542 160.738 448.559L160.317 451.896L159.878 448.559C159.878 448.559 159.878 448.524 159.87 448.507L158.353 442.175C158.3 441.939 158.09 441.781 157.844 441.781H154.364C154.127 441.781 153.917 441.948 153.856 442.175L152.339 448.507C152.339 448.507 152.339 448.542 152.33 448.559L151.909 451.817L151.489 448.559C151.489 448.559 151.488 448.524 151.48 448.507L149.963 442.175C149.91 441.939 149.7 441.781 149.455 441.781H146.991C146.658 441.781 146.413 442.096 146.491 442.421L149.647 455.495L149.7 455.512Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M170.723 441.797H168.33C168.044 441.797 167.812 442.028 167.812 442.314V455.388C167.812 455.673 168.044 455.905 168.33 455.905H170.723C171.009 455.905 171.24 455.673 171.24 455.388V442.314C171.24 442.028 171.009 441.797 170.723 441.797Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M174.062 455.388C174.062 455.677 174.29 455.905 174.58 455.905H182.707C182.996 455.905 183.224 455.677 183.224 455.388V453.155C183.224 452.866 182.996 452.638 182.707 452.638H178.016C177.727 452.638 177.499 452.411 177.499 452.122V442.314C177.499 442.025 177.271 441.797 176.982 441.797H174.589C174.299 441.797 174.071 442.025 174.071 442.314V455.388H174.062Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M185.145 442.321V455.396C185.145 455.685 185.372 455.912 185.662 455.912H193.789C194.078 455.912 194.306 455.685 194.306 455.396V453.163C194.306 452.874 194.078 452.646 193.789 452.646H189.098C188.809 452.646 188.581 452.418 188.581 452.129V442.321C188.581 442.032 188.353 441.805 188.064 441.805H185.671C185.381 441.805 185.153 442.032 185.153 442.321H185.145Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M205.704 455.388V451.526C205.704 451.237 205.932 451.009 206.221 451.009H206.502C206.677 451.009 206.835 451.097 206.932 451.237L209.956 455.677C210.053 455.817 210.21 455.905 210.386 455.905H213.305C213.726 455.905 213.971 455.432 213.726 455.09L210.973 451.22C210.789 450.957 210.885 450.598 211.175 450.458C212.569 449.792 213.419 448.514 213.419 446.517C213.419 443.575 211.56 441.797 208.58 441.797H202.802C202.513 441.797 202.285 442.025 202.285 442.314V455.388C202.285 455.677 202.513 455.905 202.802 455.905H205.196C205.485 455.905 205.713 455.677 205.713 455.388H205.704ZM205.704 445.58C205.704 445.291 205.932 445.063 206.221 445.063H208.185C209.334 445.063 209.939 445.571 209.939 446.552C209.939 447.533 209.378 448.006 208.185 448.006H206.221C205.932 448.006 205.704 447.778 205.704 447.489V445.58Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M220.168 445.063H225.428C225.717 445.063 225.945 444.836 225.945 444.547V442.314C225.945 442.025 225.717 441.797 225.428 441.797H216.74C216.451 441.797 216.223 442.025 216.223 442.314V455.388C216.223 455.677 216.451 455.905 216.74 455.905H225.428C225.717 455.905 225.945 455.677 225.945 455.388V453.155C225.945 452.866 225.717 452.638 225.428 452.638H220.168C219.878 452.638 219.65 452.411 219.65 452.122V450.939C219.65 450.65 219.878 450.423 220.168 450.423H224.858C225.147 450.423 225.375 450.195 225.375 449.906V447.673C225.375 447.384 225.147 447.156 224.858 447.156H220.168C219.878 447.156 219.65 446.929 219.65 446.64V445.58C219.65 445.291 219.878 445.063 220.168 445.063Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M240.551 448.855C240.551 444.844 237.448 441.797 233.327 441.797H228.576C228.287 441.797 228.059 442.025 228.059 442.314V455.388C228.059 455.677 228.287 455.905 228.576 455.905H233.327C237.448 455.905 240.551 452.857 240.551 448.846V448.855ZM231.495 452.122V445.58C231.495 445.291 231.723 445.063 232.012 445.063H233.327C236.606 445.063 238.412 448.347 236.028 452.384C235.931 452.542 235.756 452.638 235.572 452.638H232.004C231.714 452.638 231.486 452.411 231.486 452.122H231.495Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M245.741 441.797H243.287C242.997 441.797 242.77 442.033 242.77 442.314L242.787 450.142C242.787 454.153 245.329 456.167 248.722 456.167C252.115 456.167 254.657 454.153 254.657 450.142L254.675 442.314C254.675 442.025 254.447 441.797 254.158 441.797H251.703C251.414 441.797 251.186 442.025 251.186 442.314V449.941C251.186 452.157 250.195 452.901 248.722 452.901C247.249 452.901 246.259 452.157 246.259 449.941V442.314C246.259 442.025 246.031 441.797 245.741 441.797Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M264.223 444.802C265.599 444.802 266.686 445.432 267.291 446.5C267.387 446.667 267.554 446.781 267.747 446.781H270.403C270.763 446.781 271.017 446.422 270.894 446.08C269.921 443.392 267.423 441.535 264.223 441.535C259.988 441.535 256.902 444.696 256.902 448.847C256.902 452.998 259.988 456.16 264.223 456.16C267.423 456.16 269.921 454.312 270.894 451.615C271.017 451.273 270.763 450.914 270.403 450.914H267.747C267.554 450.914 267.387 451.028 267.291 451.194C266.686 452.254 265.599 452.893 264.223 452.893C261.882 452.893 260.365 451.203 260.365 448.839C260.365 446.474 261.882 444.784 264.223 444.784V444.802Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M282.432 441.797H273.744C273.454 441.797 273.227 442.025 273.227 442.314V455.388C273.227 455.677 273.454 455.905 273.744 455.905H282.432C282.721 455.905 282.949 455.677 282.949 455.388V453.155C282.949 452.866 282.721 452.638 282.432 452.638H277.172C276.882 452.638 276.654 452.411 276.654 452.122V450.939C276.654 450.65 276.882 450.423 277.172 450.423H281.862C282.151 450.423 282.379 450.195 282.379 449.906V447.673C282.379 447.384 282.151 447.156 281.862 447.156H277.172C276.882 447.156 276.654 446.929 276.654 446.64V445.58C276.654 445.291 276.882 445.063 277.172 445.063H282.432C282.721 445.063 282.949 444.836 282.949 444.547V442.314C282.949 442.025 282.721 441.797 282.432 441.797Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M201.239 495.986H184.6C184.275 495.986 184.012 496.249 184.012 496.573V501.897C184.012 502.221 184.275 502.484 184.6 502.484H192.578C193.033 502.484 193.323 502.983 193.095 503.368C191.639 505.846 188.878 507.108 185.704 507.108C179.98 507.108 177.139 502.992 177.139 497.869C177.139 492.247 180.576 488.49 185.888 488.49C189.141 488.49 191.675 489.882 193.007 492.255C193.112 492.439 193.305 492.562 193.516 492.562H200.722C201.116 492.562 201.406 492.185 201.292 491.809C198.311 482.281 186.563 477.106 173.308 484.794C173.229 484.838 173.133 484.943 173.08 485.013C163.875 500.627 172.686 514.28 185.108 514.28C189.202 514.28 192.358 512.8 194.156 510.724C194.506 510.313 195.181 510.567 195.181 511.11V513.097C195.181 513.421 195.444 513.684 195.769 513.684H201.23C201.555 513.684 201.818 513.421 201.818 513.097V496.573C201.818 496.249 201.555 495.986 201.23 495.986H201.239Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M229.495 481.668H208.595C208.271 481.668 208.008 481.931 208.008 482.255V513.097C208.008 513.421 208.271 513.684 208.595 513.684H229.495C229.82 513.684 230.083 513.421 230.083 513.097V506.862C230.083 506.538 229.82 506.275 229.495 506.275H216.38C216.056 506.275 215.793 506.013 215.793 505.689V501.836C215.793 501.512 216.056 501.249 216.38 501.249H228.207C228.531 501.249 228.794 500.986 228.794 500.662V494.427C228.794 494.103 228.531 493.84 228.207 493.84H216.38C216.056 493.84 215.793 493.578 215.793 493.254V489.672C215.793 489.348 216.056 489.085 216.38 489.085H229.495C229.82 489.085 230.083 488.823 230.083 488.499V482.263C230.083 481.939 229.82 481.677 229.495 481.677V481.668Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M257.472 481.668H233.416C233.091 481.668 232.828 481.931 232.828 482.255V488.49C232.828 488.814 233.091 489.076 233.416 489.076H240.937C241.262 489.076 241.525 489.339 241.525 489.663V513.097C241.525 513.421 241.788 513.684 242.112 513.684H248.722C249.047 513.684 249.31 513.421 249.31 513.097V489.663C249.31 489.339 249.573 489.076 249.897 489.076H257.463C257.787 489.076 258.05 488.814 258.05 488.49V482.255C258.05 481.931 257.787 481.668 257.463 481.668H257.472Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M274.777 497.678C274.777 507.1 282.063 514.281 291.54 514.281C301.016 514.281 308.302 507.1 308.302 497.678C308.302 488.255 301.016 481.074 291.54 481.074C282.063 481.074 274.777 488.255 274.777 497.678ZM300.42 497.678C300.42 503.028 296.668 506.873 291.54 506.873C286.411 506.873 282.659 503.028 282.659 497.678C282.659 492.327 286.411 488.483 291.54 488.483C296.668 488.483 300.42 492.327 300.42 497.678Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M341.134 513.098V482.256C341.134 481.932 340.871 481.669 340.547 481.669H333.936C333.612 481.669 333.349 481.932 333.349 482.256V495.041C333.349 495.041 333.349 495.076 333.349 495.094L333.717 499.271C333.77 499.892 332.946 500.155 332.621 499.621L322.11 481.949C322.005 481.774 321.812 481.66 321.601 481.66H313.685C313.361 481.66 313.098 481.923 313.098 482.247V513.089C313.098 513.413 313.361 513.676 313.685 513.676H320.295C320.62 513.676 320.883 513.413 320.883 513.089V500.304C320.883 500.304 320.883 500.269 320.883 500.252L320.488 496.101C320.427 495.479 321.26 495.207 321.575 495.742L332.113 513.387C332.218 513.562 332.411 513.676 332.621 513.676H340.538C340.862 513.676 341.125 513.413 341.125 513.089L341.134 513.098Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M368.106 513.097V506.862C368.106 506.538 367.843 506.275 367.519 506.275H355.693C355.368 506.275 355.105 506.013 355.105 505.689V482.255C355.105 481.931 354.842 481.668 354.518 481.668H347.908C347.583 481.668 347.32 481.931 347.32 482.255V513.097C347.32 513.421 347.583 513.684 347.908 513.684H367.519C367.843 513.684 368.106 513.421 368.106 513.097Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M394.854 481.668H387.481C387.253 481.668 387.052 481.799 386.955 482.001L381.371 493.604C381.16 494.051 380.52 494.051 380.31 493.604L374.726 482.001C374.629 481.799 374.419 481.668 374.2 481.668H366.783C366.345 481.668 366.055 482.132 366.257 482.526L376.882 503.412C376.926 503.491 376.944 503.587 376.944 503.675V513.097C376.944 513.421 377.207 513.684 377.531 513.684H384.141C384.466 513.684 384.729 513.421 384.729 513.097V503.578C384.729 503.482 384.746 503.394 384.79 503.316L395.371 482.526C395.573 482.132 395.284 481.668 394.845 481.668H394.854Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M106.14 539.658C105.702 539.422 105.667 538.826 106.079 538.537C108.086 537.154 109.165 535.218 109.165 532.276C109.165 526.041 104.483 523.02 98.3376 523.02H83.0745C82.7151 523.02 82.4258 523.309 82.4258 523.668V557.575C82.4258 557.934 82.7151 558.223 83.0745 558.223H98.3376C105.386 558.223 110.366 554.554 110.366 547.916C110.366 544.369 108.998 541.226 106.131 539.658H106.14ZM90.9997 531.82C90.9997 531.461 91.289 531.172 91.6485 531.172H97.1979C99.2581 531.172 100.521 531.628 100.521 533.791C100.521 535.647 99.4597 536.453 97.5486 536.453H91.6485C91.289 536.453 90.9997 536.164 90.9997 535.805V531.82ZM98.1447 550.079H91.6397C91.2803 550.079 90.991 549.79 90.991 549.431V544.886C90.991 544.527 91.2803 544.238 91.6397 544.238H98.1447C101.011 544.238 101.722 545.193 101.722 547.154C101.722 548.967 101.02 550.07 98.1447 550.07V550.079Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M139.278 523.03H116.309C115.949 523.03 115.66 523.319 115.66 523.678V557.586C115.66 557.945 115.949 558.234 116.309 558.234H139.278C139.637 558.234 139.927 557.945 139.927 557.586V550.729C139.927 550.37 139.637 550.081 139.278 550.081H124.865C124.506 550.081 124.217 549.792 124.217 549.433V545.195C124.217 544.835 124.506 544.546 124.865 544.546H137.867C138.226 544.546 138.515 544.258 138.515 543.898V537.042C138.515 536.683 138.226 536.394 137.867 536.394H124.865C124.506 536.394 124.217 536.105 124.217 535.746V531.805C124.217 531.446 124.506 531.157 124.865 531.157H139.278C139.637 531.157 139.927 530.868 139.927 530.509V523.652C139.927 523.293 139.637 523.004 139.278 523.004V523.03Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M175.124 523.029H167.856C167.497 523.029 167.207 523.318 167.207 523.677V537.732C167.207 537.732 167.207 537.768 167.207 537.785L167.61 542.374C167.672 543.057 166.76 543.355 166.409 542.759L154.855 523.336C154.741 523.143 154.53 523.02 154.302 523.02H145.606C145.246 523.02 144.957 523.309 144.957 523.669V557.576C144.957 557.935 145.246 558.224 145.606 558.224H152.873C153.233 558.224 153.522 557.935 153.522 557.576V543.521C153.522 543.521 153.522 543.477 153.522 543.46L153.093 538.897C153.031 538.214 153.943 537.916 154.294 538.503L165.875 557.9C165.989 558.093 166.199 558.215 166.427 558.215H175.124C175.483 558.215 175.772 557.927 175.772 557.567V523.66C175.772 523.301 175.483 523.012 175.124 523.012V523.029Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M206.184 523.03H183.215C182.856 523.03 182.566 523.319 182.566 523.678V557.586C182.566 557.945 182.856 558.234 183.215 558.234H206.184C206.544 558.234 206.833 557.945 206.833 557.586V550.729C206.833 550.37 206.544 550.081 206.184 550.081H191.772C191.412 550.081 191.123 549.792 191.123 549.433V545.195C191.123 544.835 191.412 544.546 191.772 544.546H204.773C205.132 544.546 205.422 544.258 205.422 543.898V537.042C205.422 536.683 205.132 536.394 204.773 536.394H191.772C191.412 536.394 191.123 536.105 191.123 535.746V531.805C191.123 531.446 191.412 531.157 191.772 531.157H206.184C206.544 531.157 206.833 530.868 206.833 530.509V523.652C206.833 523.293 206.544 523.004 206.184 523.004V523.03Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M235.731 523.029H212.762C212.403 523.029 212.113 523.318 212.113 523.677V557.585C212.113 557.944 212.403 558.233 212.762 558.233H220.03C220.389 558.233 220.678 557.944 220.678 557.585V545.553C220.678 545.194 220.968 544.905 221.327 544.905H234.328C234.688 544.905 234.977 544.616 234.977 544.257V537.4C234.977 537.041 234.688 536.752 234.328 536.752H221.327C220.968 536.752 220.678 536.463 220.678 536.104V531.813C220.678 531.454 220.968 531.165 221.327 531.165H235.74C236.099 531.165 236.389 530.876 236.389 530.517V523.66C236.389 523.301 236.099 523.012 235.74 523.012L235.731 523.029Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M249.572 523.027H242.313C241.955 523.027 241.664 523.317 241.664 523.675V557.574C241.664 557.932 241.955 558.222 242.313 558.222H249.572C249.93 558.222 250.22 557.932 250.22 557.574V523.675C250.22 523.317 249.93 523.027 249.572 523.027Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M282.097 523.027H255.657C255.297 523.027 255.008 523.316 255.008 523.675V530.532C255.008 530.891 255.297 531.18 255.657 531.18H263.924C264.283 531.18 264.572 531.469 264.572 531.828V557.592C264.572 557.951 264.862 558.24 265.221 558.24H272.489C272.848 558.24 273.138 557.951 273.138 557.592V531.828C273.138 531.469 273.427 531.18 273.786 531.18H282.106C282.466 531.18 282.755 530.891 282.755 530.532V523.675C282.755 523.316 282.466 523.027 282.106 523.027H282.097Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M300.36 536.05C296.888 535.297 295.275 535.095 295.275 533.431C295.275 531.925 296.432 530.366 300.255 530.366C304.077 530.366 305.26 531.75 305.822 533.598C305.9 533.86 306.155 534.036 306.435 534.036H313.869C314.255 534.036 314.571 533.694 314.518 533.309C313.694 527.512 309.828 522.371 300.263 522.371C293.566 522.371 286.675 526.093 286.675 533.834C286.675 541.129 292.566 543.239 298.3 544.448C304.191 545.709 306.304 545.858 306.304 547.916C306.304 549.772 304.743 550.884 301.272 550.884C297.037 550.884 295.407 549.667 294.565 547.075C294.477 546.803 294.232 546.611 293.951 546.611H286.517C286.122 546.611 285.807 546.961 285.877 547.346C286.824 553.1 290.997 558.88 301.324 558.88C306.961 558.88 314.913 556.165 314.913 547.565C314.913 540.376 310.231 538.16 300.369 536.05H300.36Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M347.595 523.836C347.692 523.433 347.385 523.039 346.964 523.039H344.5C344.202 523.039 343.939 523.24 343.869 523.538L342.247 530.307C342.247 530.307 342.239 530.351 342.239 530.378L341.783 533.985L341.309 530.378C341.309 530.378 341.309 530.334 341.301 530.307L339.679 523.538C339.609 523.249 339.346 523.039 339.047 523.039H335.418C335.12 523.039 334.857 523.24 334.787 523.538L333.165 530.307C333.165 530.307 333.156 530.351 333.156 530.378L332.7 533.898L332.244 530.378C332.244 530.378 332.244 530.334 332.236 530.307L330.614 523.538C330.544 523.249 330.281 523.039 329.983 523.039H327.493C327.072 523.039 326.765 523.433 326.862 523.836L330.237 537.795C330.307 538.084 330.57 538.285 330.868 538.285H334.427C334.725 538.285 334.988 538.084 335.059 537.795L336.26 532.803C336.26 532.803 336.26 532.759 336.268 532.742L337.031 527.365L337.399 527.383L338.18 532.733C338.18 532.733 338.18 532.768 338.188 532.794L339.389 537.786C339.459 538.075 339.723 538.276 340.021 538.276H343.562C343.86 538.276 344.123 538.075 344.194 537.786L347.569 523.827L347.595 523.836Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M352.915 523.027H350.504C350.146 523.027 349.855 523.317 349.855 523.675V537.634C349.855 537.992 350.146 538.282 350.504 538.282H352.915C353.273 538.282 353.564 537.992 353.564 537.634V523.675C353.564 523.317 353.273 523.027 352.915 523.027Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M357.263 538.283H365.872C366.231 538.283 366.521 537.994 366.521 537.635V535.393C366.521 535.034 366.231 534.745 365.872 534.745H360.971C360.612 534.745 360.323 534.456 360.323 534.097V523.668C360.323 523.309 360.033 523.02 359.674 523.02H357.254C356.895 523.02 356.605 523.309 356.605 523.668V537.626C356.605 537.985 356.895 538.274 357.254 538.274L357.263 538.283Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M371.657 523.028H369.246C368.887 523.028 368.598 523.317 368.598 523.676V537.635C368.598 537.994 368.887 538.283 369.246 538.283H377.855C378.215 538.283 378.504 537.994 378.504 537.635V535.393C378.504 535.034 378.215 534.745 377.855 534.745H372.955C372.595 534.745 372.306 534.456 372.306 534.097V523.668C372.306 523.309 372.017 523.02 371.657 523.02V523.028Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M396.512 538.282H399.712C400.071 538.282 400.361 537.993 400.361 537.634V523.675C400.361 523.316 400.071 523.027 399.712 523.027H397.301C396.942 523.027 396.652 523.316 396.652 523.675V529.385C396.652 529.385 396.652 529.42 396.652 529.438L396.933 532.634L391.401 523.343C391.287 523.15 391.077 523.027 390.849 523.027H387.649C387.289 523.027 387 523.316 387 523.675V537.634C387 537.993 387.289 538.282 387.649 538.282H390.068C390.428 538.282 390.717 537.993 390.717 537.634V531.925C390.717 531.925 390.717 531.881 390.717 531.863L390.41 528.667L395.96 537.958C396.074 538.151 396.284 538.273 396.512 538.273V538.282Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M410.635 538.573C415.15 538.573 418.622 535.149 418.622 530.665C418.622 526.182 415.15 522.758 410.635 522.758C406.12 522.758 402.648 526.182 402.648 530.665C402.648 535.149 406.12 538.573 410.635 538.573ZM410.635 526.278C413.081 526.278 414.869 528.108 414.869 530.657C414.869 533.205 413.081 535.035 410.635 535.035C408.189 535.035 406.401 533.205 406.401 530.657C406.401 528.108 408.189 526.278 410.635 526.278Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M431.407 523.027H420.676C420.317 523.027 420.027 523.316 420.027 523.675V525.917C420.027 526.276 420.317 526.565 420.676 526.565H423.525C423.885 526.565 424.174 526.854 424.174 527.213V537.643C424.174 538.002 424.463 538.291 424.823 538.291H427.234C427.593 538.291 427.882 538.002 427.882 537.643V527.213C427.882 526.854 428.172 526.565 428.531 526.565H431.407C431.766 526.565 432.055 526.276 432.055 525.917V523.675C432.055 523.316 431.766 523.027 431.407 523.027Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M335.049 546.392C336.522 546.392 337.679 547.058 338.337 548.179C338.46 548.389 338.67 548.529 338.907 548.529H341.598C342.054 548.529 342.37 548.074 342.212 547.645C341.133 544.807 338.46 542.863 335.049 542.863C330.464 542.863 327.133 546.287 327.133 550.771C327.133 555.255 330.473 558.679 335.049 558.679C338.46 558.679 341.133 556.735 342.212 553.897C342.37 553.477 342.054 553.013 341.598 553.013H338.907C338.67 553.013 338.46 553.153 338.337 553.363C337.679 554.484 336.513 555.15 335.049 555.15C332.516 555.15 330.885 553.319 330.885 550.771C330.885 548.223 332.524 546.392 335.049 546.392Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M352.1 542.863C347.585 542.863 344.113 546.287 344.113 550.771C344.113 555.255 347.585 558.679 352.1 558.679C356.615 558.679 360.086 555.255 360.086 550.771C360.086 546.287 356.615 542.863 352.1 542.863ZM352.1 555.15C349.654 555.15 347.865 553.319 347.865 550.771C347.865 548.223 349.654 546.392 352.1 546.392C354.546 546.392 356.334 548.223 356.334 550.771C356.334 553.319 354.546 555.15 352.1 555.15Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M368.046 548.792C366.538 548.468 365.845 548.38 365.845 547.662C365.845 547.005 366.345 546.331 368.002 546.331C369.466 546.331 370.053 546.822 370.334 547.513C370.43 547.759 370.667 547.925 370.93 547.925H373.455C373.867 547.925 374.174 547.548 374.095 547.146C373.604 544.834 371.886 542.863 368.002 542.863C365.1 542.863 362.111 544.475 362.111 547.829C362.111 550.99 364.662 551.901 367.152 552.426C369.703 552.969 370.623 553.039 370.623 553.932C370.623 554.738 369.948 555.22 368.44 555.22C366.713 555.22 365.986 554.755 365.6 553.757C365.512 553.521 365.267 553.372 365.012 553.372H362.496C362.084 553.372 361.769 553.757 361.865 554.16C362.417 556.489 364.267 558.687 368.467 558.687C370.912 558.687 374.358 557.514 374.358 553.783C374.358 550.666 372.333 549.711 368.055 548.792H368.046Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M387.036 543.145H376.305C375.946 543.145 375.656 543.434 375.656 543.793V546.034C375.656 546.393 375.946 546.682 376.305 546.682H379.154C379.514 546.682 379.803 546.971 379.803 547.33V557.76C379.803 558.119 380.092 558.408 380.452 558.408H382.863C383.222 558.408 383.511 558.119 383.511 557.76V547.33C383.511 546.971 383.801 546.682 384.16 546.682H387.036C387.395 546.682 387.684 546.393 387.684 546.034V543.793C387.684 543.434 387.395 543.145 387.036 543.145Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M410.942 543.145H407.725C407.505 543.145 407.304 543.25 407.19 543.434L404.428 547.567C404.174 547.952 403.604 547.952 403.35 547.567L400.588 543.434C400.466 543.25 400.264 543.145 400.054 543.145H396.836C396.477 543.145 396.188 543.434 396.188 543.793V557.751C396.188 558.11 396.477 558.399 396.836 558.399H399.256C399.615 558.399 399.905 558.11 399.905 557.751V550.492C399.905 549.852 400.737 549.599 401.088 550.141L403.718 554.152H404.069L406.699 550.141C407.05 549.607 407.882 549.852 407.882 550.492V557.751C407.882 558.11 408.172 558.399 408.531 558.399H410.951C411.31 558.399 411.6 558.11 411.6 557.751V543.793C411.6 543.434 411.31 543.145 410.951 543.145H410.942Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M421.971 542.863C417.456 542.863 413.984 546.287 413.984 550.771C413.984 555.255 417.456 558.679 421.971 558.679C426.486 558.679 429.958 555.255 429.958 550.771C429.958 546.287 426.486 542.863 421.971 542.863ZM421.971 555.15C419.525 555.15 417.737 553.319 417.737 550.771C417.737 548.223 419.525 546.392 421.971 546.392C424.417 546.392 426.205 548.223 426.205 550.771C426.205 553.319 424.417 555.15 421.971 555.15Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M441.819 553.417C441.591 553.093 441.714 552.646 442.064 552.462C443.52 551.735 444.405 550.36 444.405 548.241C444.405 545.062 442.397 543.145 439.171 543.145H433.008C432.649 543.145 432.359 543.434 432.359 543.793V557.751C432.359 558.11 432.649 558.399 433.008 558.399H435.419C435.778 558.399 436.068 558.11 436.068 557.751V553.101H436.883C437.093 553.101 437.295 553.206 437.418 553.382L440.635 558.11C440.758 558.286 440.96 558.391 441.17 558.391H444.116C444.642 558.391 444.949 557.795 444.642 557.375L441.819 553.408V553.417ZM438.75 549.861H436.716C436.357 549.861 436.068 549.572 436.068 549.213V547.322C436.068 546.963 436.357 546.674 436.716 546.674H438.75C439.995 546.674 440.644 547.217 440.644 548.285C440.644 549.353 440.03 549.853 438.75 549.853V549.861Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M457.301 543.143H448.078C447.719 543.143 447.43 543.432 447.43 543.791V557.75C447.43 558.109 447.719 558.398 448.078 558.398H457.301C457.661 558.398 457.95 558.109 457.95 557.75V555.508C457.95 555.149 457.661 554.86 457.301 554.86H451.787C451.427 554.86 451.138 554.571 451.138 554.212V553.109C451.138 552.75 451.427 552.461 451.787 552.461H456.687C457.047 552.461 457.336 552.172 457.336 551.813V549.571C457.336 549.212 457.047 548.923 456.687 548.923H451.787C451.427 548.923 451.138 548.634 451.138 548.275V547.303C451.138 546.944 451.427 546.655 451.787 546.655H457.301C457.661 546.655 457.95 546.366 457.95 546.007V543.765C457.95 543.406 457.661 543.117 457.301 543.117V543.143Z"
                    stroke="#FFD9F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M153.822 212.52C160.713 212.52 170.436 209.201 170.436 198.692C170.436 189.909 164.711 187.203 152.656 184.62C148.413 183.7 146.441 183.455 146.441 181.423C146.441 179.576 147.852 177.675 152.534 177.675C156.821 177.675 158.451 179.173 159.205 181.222C159.407 181.774 159.924 182.159 160.511 182.159H168.463C169.331 182.159 169.988 181.371 169.831 180.521C168.594 173.752 163.808 167.902 152.525 167.902C144.345 167.902 135.912 172.447 135.912 181.914C135.912 190.82 143.109 193.403 150.123 194.883C157.32 196.416 159.907 196.599 159.907 199.122C159.907 201.398 157.995 202.747 153.752 202.747C148.764 202.747 146.739 201.372 145.678 198.438C145.476 197.896 144.959 197.528 144.381 197.528H136.447C135.57 197.528 134.904 198.333 135.079 199.192C136.473 205.961 141.654 212.52 153.822 212.52Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M174.872 211.722H182.981C183.568 211.722 184.094 211.346 184.287 210.785L186.716 203.744C186.908 203.184 187.434 202.807 188.022 202.807H202.163C202.75 202.807 203.276 203.184 203.469 203.744L205.897 210.785C206.09 211.346 206.616 211.722 207.204 211.722H215.313C216.277 211.722 216.944 210.768 216.619 209.866L201.996 169.618C201.795 169.066 201.277 168.707 200.69 168.707H189.468C188.881 168.707 188.364 169.075 188.162 169.618L173.548 209.866C173.223 210.768 173.89 211.722 174.854 211.722H174.872ZM191.125 191.02L193.799 183.27C194.229 182.027 195.991 182.027 196.42 183.27L199.094 191.02C199.401 191.922 198.735 192.859 197.779 192.859H192.44C191.485 192.859 190.818 191.922 191.125 191.02Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M228.015 210.751C228.199 211.329 228.734 211.723 229.339 211.723H240.464C241.069 211.723 241.604 211.329 241.788 210.751L254.509 170.503C254.789 169.61 254.123 168.699 253.185 168.699H245.04C244.427 168.699 243.883 169.102 243.708 169.689L236.291 194.594C235.897 195.916 234.029 195.916 233.635 194.594L226.218 169.689C226.043 169.102 225.499 168.699 224.885 168.699H216.732C215.794 168.699 215.128 169.61 215.408 170.494L228.015 210.742V210.751Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M262.311 211.723H289.19C289.953 211.723 290.575 211.101 290.575 210.34V203.159C290.575 202.397 289.953 201.775 289.19 201.775H272.77C272.007 201.775 271.385 201.153 271.385 200.391V196.398C271.385 195.636 272.007 195.015 272.77 195.015H287.472C288.234 195.015 288.857 194.393 288.857 193.631V186.45C288.857 185.688 288.234 185.066 287.472 185.066H272.77C272.007 185.066 271.385 184.445 271.385 183.683V180.066C271.385 179.304 272.007 178.682 272.77 178.682H289.19C289.953 178.682 290.575 178.061 290.575 177.299V170.118C290.575 169.356 289.953 168.734 289.19 168.734H262.311C261.548 168.734 260.926 169.356 260.926 170.118V210.366C260.926 211.128 261.548 211.749 262.311 211.749V211.723Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M314.108 178.655H323.024C323.786 178.655 324.409 179.277 324.409 180.039V210.33C324.409 211.092 325.031 211.713 325.794 211.713H333.483C334.245 211.713 334.868 211.092 334.868 210.33V180.039C334.868 179.277 335.49 178.655 336.253 178.655H345.23C345.993 178.655 346.615 178.033 346.615 177.272V170.091C346.615 169.329 345.993 168.707 345.23 168.707H314.108C313.345 168.707 312.723 169.329 312.723 170.091V177.272C312.723 178.033 313.345 178.655 314.108 178.655Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M361.535 168.707H353.846C353.081 168.707 352.461 169.327 352.461 170.091V210.338C352.461 211.103 353.081 211.722 353.846 211.722H361.535C362.3 211.722 362.92 211.103 362.92 210.338V170.091C362.92 169.327 362.3 168.707 361.535 168.707Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M372.609 211.722H380.297C381.06 211.722 381.682 211.1 381.682 210.338V187.973C381.682 186.598 383.471 186.064 384.225 187.211L392.439 199.734H393.421L401.645 187.211C402.398 186.064 404.187 186.598 404.187 187.973V210.338C404.187 211.1 404.809 211.722 405.572 211.722H413.261C414.023 211.722 414.646 211.1 414.646 210.338V170.091C414.646 169.329 414.023 168.707 413.261 168.707H403.521C403.056 168.707 402.626 168.935 402.363 169.32L394.087 181.72C393.535 182.543 392.325 182.543 391.782 181.72L383.506 169.32C383.252 168.935 382.813 168.707 382.349 168.707H372.6C371.837 168.707 371.215 169.329 371.215 170.091V210.338C371.215 211.1 371.837 211.722 372.6 211.722H372.609Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M424.655 211.723H451.534C452.296 211.723 452.919 211.101 452.919 210.34V203.159C452.919 202.397 452.296 201.775 451.534 201.775H435.114C434.351 201.775 433.728 201.153 433.728 200.391V196.398C433.728 195.636 434.351 195.015 435.114 195.015H449.807C450.569 195.015 451.192 194.393 451.192 193.631V186.45C451.192 185.688 450.569 185.066 449.807 185.066H435.114C434.351 185.066 433.728 184.445 433.728 183.683V180.066C433.728 179.304 434.351 178.682 435.114 178.682H451.534C452.296 178.682 452.919 178.061 452.919 177.299V170.118C452.919 169.356 452.296 168.734 451.534 168.734H424.655C423.892 168.734 423.27 169.356 423.27 170.118V210.366C423.27 211.128 423.892 211.749 424.655 211.749V211.723Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M1.21859 277.478H23.311C23.986 277.478 24.5296 276.936 24.5296 276.261V269.947C24.5296 269.273 23.986 268.73 23.311 268.73H10.3975C9.72241 268.73 9.17886 268.187 9.17886 267.513V240.909C9.17886 240.234 8.63532 239.691 7.96028 239.691H1.21859C0.543543 239.691 0 240.234 0 240.909V276.261C0 276.936 0.543543 277.478 1.21859 277.478Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M30.8866 277.479H54.4956C55.1707 277.479 55.7142 276.936 55.7142 276.262V269.948C55.7142 269.274 55.1707 268.731 54.4956 268.731H40.0742C39.3991 268.731 38.8556 268.188 38.8556 267.514V264.011C38.8556 263.337 39.3991 262.794 40.0742 262.794H52.9877C53.6628 262.794 54.2063 262.251 54.2063 261.576V255.263C54.2063 254.588 53.6628 254.045 52.9877 254.045H40.0742C39.3991 254.045 38.8556 253.502 38.8556 252.828V249.649C38.8556 248.975 39.3991 248.432 40.0742 248.432H54.4956C55.1707 248.432 55.7142 247.889 55.7142 247.215V240.901C55.7142 240.227 55.1707 239.684 54.4956 239.684H30.8866C30.2115 239.684 29.668 240.227 29.668 240.901V276.253C29.668 276.928 30.2115 277.471 30.8866 277.471V277.479Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M76.1842 278.179C82.2333 278.179 90.7722 275.263 90.7722 266.033C90.7722 258.318 85.7488 255.936 75.1585 253.677C71.4326 252.871 69.7055 252.652 69.7055 250.875C69.7055 249.255 70.9504 247.582 75.0533 247.582C78.823 247.582 80.252 248.896 80.9095 250.7C81.0849 251.19 81.5408 251.523 82.058 251.523H89.0452C89.8079 251.523 90.3865 250.831 90.2462 250.087C89.1591 244.14 84.9598 239 75.0445 239C67.8557 239 60.4565 242.993 60.4565 251.304C60.4565 259.133 66.7774 261.392 72.9405 262.688C79.2614 264.037 81.532 264.203 81.532 266.41C81.532 268.406 79.8575 269.597 76.1316 269.597C71.757 269.597 69.9773 268.389 69.0393 265.814C68.8639 265.333 68.4081 265.009 67.8996 265.009H60.93C60.1585 265.009 59.5711 265.718 59.7289 266.471C60.9563 272.417 65.5062 278.179 76.193 278.179H76.1842Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M111.794 278.179C117.843 278.179 126.382 275.263 126.382 266.033C126.382 258.318 121.358 255.936 110.768 253.677C107.042 252.871 105.315 252.652 105.315 250.875C105.315 249.255 106.56 247.582 110.663 247.582C114.432 247.582 115.861 248.896 116.519 250.7C116.694 251.19 117.15 251.523 117.667 251.523H124.655C125.417 251.523 125.996 250.831 125.856 250.087C124.768 244.14 120.569 239 110.654 239C103.465 239 96.0659 242.993 96.0659 251.304C96.0659 259.133 102.387 261.392 108.55 262.688C114.871 264.037 117.141 264.203 117.141 266.41C117.141 268.406 115.467 269.597 111.741 269.597C107.366 269.597 105.587 268.389 104.649 265.814C104.473 265.333 104.017 265.009 103.509 265.009H96.5393C95.7678 265.009 95.1805 265.718 95.3383 266.471C96.5656 272.417 101.116 278.179 111.802 278.179H111.794Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M163.071 278.179C169.12 278.179 177.659 275.263 177.659 266.033C177.659 258.318 172.636 255.936 162.045 253.677C158.319 252.871 156.592 252.652 156.592 250.875C156.592 249.255 157.837 247.582 161.94 247.582C165.71 247.582 167.139 248.896 167.796 250.7C167.972 251.19 168.427 251.523 168.945 251.523H175.932C176.695 251.523 177.273 250.831 177.133 250.087C176.046 244.14 171.847 239 161.931 239C154.742 239 147.343 242.993 147.343 251.304C147.343 259.133 153.664 261.392 159.827 262.688C166.148 264.037 168.419 264.203 168.419 266.41C168.419 268.406 166.744 269.597 163.018 269.597C158.644 269.597 156.864 268.389 155.926 265.814C155.751 265.333 155.295 265.009 154.786 265.009H147.817C147.045 265.009 146.458 265.718 146.616 266.471C147.843 272.417 152.393 278.179 163.08 278.179H163.071Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M200.356 276.261V249.657C200.356 248.983 200.899 248.44 201.575 248.44H209.456C210.131 248.44 210.674 247.897 210.674 247.223V240.909C210.674 240.234 210.131 239.691 209.456 239.691H182.121C181.446 239.691 180.902 240.234 180.902 240.909V247.223C180.902 247.897 181.446 248.44 182.121 248.44H189.95C190.625 248.44 191.168 248.983 191.168 249.657V276.261C191.168 276.936 191.712 277.478 192.387 277.478H199.137C199.812 277.478 200.356 276.936 200.356 276.261Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M236.407 276.947C236.635 277.28 237.012 277.481 237.415 277.481H245.647C246.638 277.481 247.216 276.369 246.638 275.564L238.967 264.775C238.52 264.144 238.774 263.277 239.484 262.953C243.297 261.202 245.629 257.734 245.629 252.331C245.629 244.45 240.659 239.703 232.663 239.703H217.023C216.348 239.703 215.805 240.246 215.805 240.92V276.273C215.805 276.947 216.348 277.49 217.023 277.49H223.774C224.449 277.49 224.992 276.947 224.992 276.273V265.589C224.992 264.915 225.536 264.372 226.211 264.372H227.21C227.614 264.372 227.991 264.573 228.219 264.906L236.407 276.956V276.947ZM226.211 256.324C225.536 256.324 224.992 255.781 224.992 255.107V249.66C224.992 248.986 225.536 248.443 226.211 248.443H231.638C234.715 248.443 236.337 249.791 236.337 252.436C236.337 255.081 234.82 256.324 231.638 256.324H226.211Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M254.359 277.479H277.968C278.643 277.479 279.187 276.936 279.187 276.262V269.948C279.187 269.274 278.643 268.731 277.968 268.731H263.547C262.872 268.731 262.328 268.188 262.328 267.514V264.011C262.328 263.337 262.872 262.794 263.547 262.794H276.46C277.135 262.794 277.679 262.251 277.679 261.576V255.263C277.679 254.588 277.135 254.045 276.46 254.045H263.547C262.872 254.045 262.328 253.502 262.328 252.828V249.649C262.328 248.975 262.872 248.432 263.547 248.432H277.968C278.643 248.432 279.187 247.889 279.187 247.215V240.901C279.187 240.227 278.643 239.684 277.968 239.684H254.359C253.684 239.684 253.141 240.227 253.141 240.901V276.253C253.141 276.928 253.684 277.471 254.359 277.471V277.479Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M299.616 269.597C295.241 269.597 293.462 268.389 292.524 265.814C292.348 265.333 291.892 265.009 291.384 265.009H284.414C283.643 265.009 283.055 265.718 283.213 266.471C284.441 272.417 288.991 278.179 299.677 278.179C305.726 278.179 314.265 275.263 314.265 266.033C314.265 258.318 309.242 255.936 298.652 253.677C294.926 252.871 293.199 252.652 293.199 250.875C293.199 249.255 294.444 247.582 298.546 247.582C302.316 247.582 303.745 248.896 304.403 250.7C304.578 251.19 305.034 251.523 305.551 251.523H312.538C313.301 251.523 313.88 250.831 313.739 250.087C312.652 244.14 308.453 239 298.538 239C291.349 239 283.95 242.993 283.95 251.304C283.95 259.133 290.271 261.392 296.434 262.688C302.755 264.037 305.025 264.203 305.025 266.41C305.025 268.406 303.351 269.597 299.625 269.597H299.616Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M319.55 251.304C319.55 259.133 325.871 261.392 332.034 262.688C338.355 264.037 340.626 264.203 340.626 266.41C340.626 268.406 338.951 269.597 335.225 269.597C330.851 269.597 329.071 268.389 328.133 265.814C327.958 265.333 327.502 265.009 326.993 265.009H320.024C319.252 265.009 318.665 265.718 318.823 266.471C320.05 272.417 324.6 278.179 335.287 278.179C341.336 278.179 349.875 275.263 349.875 266.033C349.875 258.318 344.851 255.936 334.261 253.677C330.535 252.871 328.808 252.652 328.808 250.875C328.808 249.255 330.053 247.582 334.156 247.582C337.926 247.582 339.355 248.896 340.012 250.7C340.187 251.19 340.643 251.523 341.161 251.523H348.148C348.91 251.523 349.489 250.831 349.349 250.087C348.262 244.14 344.062 239 334.147 239C326.958 239 319.559 242.993 319.559 251.304H319.55Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M36.3984 308.149C35.7409 308.149 35.2148 308.684 35.2148 309.332V343.738C35.2148 344.395 35.7496 344.921 36.3984 344.921H51.8367C59.2009 344.921 64.4084 341.085 64.4084 334.149C64.4084 330.682 63.1635 327.581 60.5422 325.856C59.8672 325.41 59.8146 324.473 60.4458 323.956C62.1991 322.546 63.1459 320.611 63.1459 317.809C63.1459 311.293 58.2541 308.141 51.8367 308.141H36.3984V308.149ZM55.361 333.37C55.361 335.261 54.6246 336.417 51.6263 336.417H45.3405C44.683 336.417 44.157 335.883 44.157 335.235V331.505C44.157 330.848 44.6918 330.322 45.3405 330.322H51.6263C54.6246 330.322 55.361 331.321 55.361 333.37ZM54.0986 319.394C54.0986 321.338 52.994 322.178 50.9951 322.178H45.3405C44.683 322.178 44.157 321.644 44.157 320.996V317.844C44.157 317.187 44.6918 316.661 45.3405 316.661H50.6269C52.7836 316.661 54.0986 317.134 54.0986 319.394Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M69.9336 309.332V343.738C69.9336 344.395 70.4684 344.921 71.1171 344.921H94.095C94.7525 344.921 95.2785 344.386 95.2785 343.738V337.591C95.2785 336.934 94.7437 336.409 94.095 336.409H80.0505C79.393 336.409 78.867 335.874 78.867 335.226V331.811C78.867 331.154 79.4018 330.629 80.0505 330.629H92.6134C93.2709 330.629 93.7969 330.095 93.7969 329.447V323.299C93.7969 322.642 93.2621 322.117 92.6134 322.117H80.0505C79.393 322.117 78.867 321.583 78.867 320.935V317.835C78.867 317.178 79.4018 316.653 80.0505 316.653H94.095C94.7525 316.653 95.2785 316.118 95.2785 315.47V309.323C95.2785 308.666 94.7437 308.141 94.095 308.141H71.1171C70.4596 308.141 69.9336 308.675 69.9336 309.323V309.332Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M99.6279 316.66H107.255C107.913 316.66 108.439 317.195 108.439 317.843V343.737C108.439 344.394 108.973 344.92 109.622 344.92H116.197C116.855 344.92 117.381 344.385 117.381 343.737V317.843C117.381 317.186 117.915 316.66 118.564 316.66H126.244C126.901 316.66 127.427 316.126 127.427 315.478V309.331C127.427 308.674 126.893 308.148 126.244 308.148H99.6366C98.9791 308.148 98.4531 308.683 98.4531 309.331V315.478C98.4531 316.135 98.9879 316.66 99.6366 316.66H99.6279Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M150.045 343.745V317.85C150.045 317.194 150.58 316.668 151.228 316.668H158.908C159.566 316.668 160.092 316.134 160.092 315.486V309.338C160.092 308.682 159.557 308.156 158.908 308.156H132.301C131.643 308.156 131.117 308.69 131.117 309.338V315.486C131.117 316.143 131.652 316.668 132.301 316.668H139.928C140.585 316.668 141.111 317.202 141.111 317.85V343.745C141.111 344.402 141.646 344.927 142.295 344.927H148.87C149.528 344.927 150.054 344.393 150.054 343.745H150.045Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M175.216 316.66H189.251C189.909 316.66 190.435 316.126 190.435 315.478V309.331C190.435 308.674 189.9 308.148 189.251 308.148H166.273C165.616 308.148 165.09 308.683 165.09 309.331V343.737C165.09 344.394 165.625 344.92 166.273 344.92H189.251C189.909 344.92 190.435 344.385 190.435 343.737V337.59C190.435 336.933 189.9 336.408 189.251 336.408H175.216C174.558 336.408 174.032 335.874 174.032 335.225V331.81C174.032 331.153 174.567 330.628 175.216 330.628H187.778C188.436 330.628 188.962 330.094 188.962 329.446V323.298C188.962 322.641 188.427 322.116 187.778 322.116H175.216C174.558 322.116 174.032 321.582 174.032 320.934V317.834C174.032 317.177 174.567 316.652 175.216 316.652V316.66Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M217.521 344.928H225.534C226.498 344.928 227.059 343.843 226.498 343.054L219.029 332.555C218.59 331.942 218.845 331.101 219.528 330.786C223.246 329.078 225.516 325.707 225.516 320.443C225.516 312.772 220.677 308.148 212.892 308.148H197.664C197.006 308.148 196.48 308.683 196.48 309.331V343.737C196.48 344.394 197.015 344.92 197.664 344.92H204.239C204.897 344.92 205.423 344.385 205.423 343.737V333.334C205.423 332.677 205.957 332.152 206.606 332.152H207.579C207.974 332.152 208.342 332.344 208.561 332.668L216.53 344.394C216.749 344.718 217.118 344.911 217.512 344.911L217.521 344.928ZM211.901 324.332H206.615C205.957 324.332 205.431 323.797 205.431 323.149V317.843C205.431 317.186 205.966 316.66 206.615 316.66H211.901C214.9 316.66 216.478 317.974 216.478 320.549C216.478 323.123 215.005 324.332 211.901 324.332Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M271.665 330.778C275.382 329.07 277.653 325.699 277.653 320.436C277.653 312.764 272.814 308.141 265.029 308.141H249.801C249.143 308.141 248.617 308.675 248.617 309.323V343.73C248.617 344.386 249.152 344.912 249.801 344.912H256.376C257.033 344.912 257.559 344.378 257.559 343.73V333.326C257.559 332.669 258.094 332.144 258.743 332.144H259.716C260.11 332.144 260.479 332.337 260.698 332.661L268.667 344.386C268.886 344.71 269.254 344.903 269.649 344.903H277.662C278.626 344.903 279.187 343.817 278.626 343.029L271.157 332.529C270.718 331.916 270.973 331.076 271.656 330.76L271.665 330.778ZM264.029 324.333H258.743C258.085 324.333 257.559 323.798 257.559 323.15V317.844C257.559 317.187 258.094 316.661 258.743 316.661H264.029C267.028 316.661 268.606 317.975 268.606 320.549C268.606 323.124 267.133 324.333 264.029 324.333Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M284.957 309.332V343.738C284.957 344.395 285.492 344.921 286.141 344.921H309.118C309.776 344.921 310.302 344.386 310.302 343.738V337.591C310.302 336.934 309.767 336.409 309.118 336.409H295.074C294.416 336.409 293.89 335.874 293.89 335.226V331.811C293.89 331.154 294.425 330.629 295.074 330.629H307.637C308.294 330.629 308.82 330.095 308.82 329.447V323.299C308.82 322.642 308.286 322.117 307.637 322.117H295.074C294.416 322.117 293.89 321.583 293.89 320.935V317.835C293.89 317.178 294.425 316.653 295.074 316.653H309.118C309.776 316.653 310.302 316.118 310.302 315.47V309.323C310.302 308.666 309.767 308.141 309.118 308.141H286.141C285.483 308.141 284.957 308.675 284.957 309.323V309.332Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M330.195 337.258C325.934 337.258 324.198 336.084 323.287 333.571C323.12 333.107 322.673 332.791 322.173 332.791H315.388C314.634 332.791 314.064 333.483 314.222 334.21C315.414 339.999 319.841 345.612 330.247 345.612C336.139 345.612 344.45 342.775 344.45 333.79C344.45 326.276 339.558 323.964 329.248 321.757C325.619 320.969 323.935 320.759 323.935 319.025C323.935 317.449 325.145 315.82 329.143 315.82C332.816 315.82 334.201 317.099 334.85 318.85C335.025 319.323 335.464 319.647 335.972 319.647H342.775C343.512 319.647 344.081 318.973 343.95 318.246C342.898 312.457 338.804 307.457 329.152 307.457C322.156 307.457 314.949 311.345 314.949 319.437C314.949 327.055 321.104 329.262 327.1 330.523C333.254 331.837 335.464 331.995 335.464 334.149C335.464 336.093 333.833 337.249 330.204 337.249L330.195 337.258Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M349.98 309.332L350.033 329.893C350.033 340.349 356.661 345.604 365.498 345.604C374.335 345.604 380.962 340.349 380.962 329.893L381.015 309.332C381.015 308.675 380.489 308.141 379.832 308.141H373.107C372.45 308.141 371.924 308.675 371.924 309.323V329.368C371.924 335.148 369.346 337.092 365.507 337.092C361.667 337.092 359.089 335.148 359.089 329.368V309.323C359.089 308.666 358.554 308.141 357.906 308.141H351.182C350.524 308.141 349.998 308.675 349.998 309.332H349.98Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M411.989 343.745V337.598C411.989 336.941 411.454 336.416 410.806 336.416H398.243C397.585 336.416 397.059 335.881 397.059 335.233V309.338C397.059 308.682 396.525 308.156 395.876 308.156H389.301C388.643 308.156 388.117 308.69 388.117 309.338V343.745C388.117 344.402 388.652 344.927 389.301 344.927H410.806C411.463 344.927 411.989 344.393 411.989 343.745Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M408.305 309.331V315.478C408.305 316.135 408.839 316.66 409.488 316.66H417.115C417.773 316.66 418.299 317.195 418.299 317.843V343.737C418.299 344.394 418.834 344.92 419.482 344.92H426.058C426.715 344.92 427.241 344.385 427.241 343.737V317.843C427.241 317.186 427.776 316.66 428.425 316.66H436.104C436.762 316.66 437.288 316.126 437.288 315.478V309.331C437.288 308.674 436.753 308.148 436.104 308.148H409.497C408.839 308.148 408.313 308.683 408.313 309.331H408.305Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M455.7 321.757C452.071 320.969 450.388 320.759 450.388 319.025C450.388 317.449 451.597 315.82 455.595 315.82C459.268 315.82 460.653 317.099 461.302 318.85C461.478 319.323 461.916 319.647 462.424 319.647H469.227C469.964 319.647 470.534 318.973 470.402 318.246C469.35 312.457 465.256 307.457 455.604 307.457C448.608 307.457 441.402 311.345 441.402 319.437C441.402 327.055 447.556 329.262 453.552 330.523C459.707 331.837 461.916 331.995 461.916 334.149C461.916 336.093 460.285 337.249 456.656 337.249C452.395 337.249 450.659 336.075 449.748 333.562C449.581 333.098 449.134 332.783 448.634 332.783H441.849C441.095 332.783 440.525 333.474 440.683 334.201C441.875 339.99 446.302 345.603 456.708 345.603C462.6 345.603 470.911 342.766 470.911 333.781C470.911 326.267 466.019 323.955 455.709 321.749L455.7 321.757Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M203.233 359.026H209.071C209.729 359.026 210.255 358.491 210.255 357.843V355.952C210.255 355.295 209.72 354.77 209.071 354.77H198.762C198.104 354.77 197.578 355.304 197.578 355.952V371.969C197.578 372.625 198.113 373.151 198.762 373.151H200.866C201.523 373.151 202.049 372.617 202.049 371.969V367.371C202.049 366.714 202.584 366.189 203.233 366.189H208.335C208.993 366.189 209.519 365.655 209.519 365.007V363.115C209.519 362.458 208.984 361.933 208.335 361.933H203.233C202.575 361.933 202.049 361.399 202.049 360.751V360.199C202.049 359.542 202.584 359.017 203.233 359.017V359.026Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M214.2 373.16H216.304C216.962 373.16 217.488 372.626 217.488 371.978V367.451C217.488 367.074 217.795 366.777 218.163 366.777H218.522C218.75 366.777 218.952 366.89 219.083 367.074L222.87 372.644C223.09 372.968 223.458 373.16 223.852 373.16H226.395C227.359 373.16 227.92 372.075 227.359 371.286L224.676 367.521C224.282 366.96 224.431 366.181 225.018 365.831C226.588 364.92 227.526 363.309 227.526 360.909C227.526 357.074 225.106 354.762 221.214 354.762H214.191C213.534 354.762 213.008 355.296 213.008 355.944V371.961C213.008 372.618 213.543 373.143 214.191 373.143L214.2 373.16ZM217.479 360.217C217.479 359.561 218.014 359.035 218.662 359.035H220.714C222.213 359.035 223.002 359.692 223.002 360.979C223.002 362.267 222.266 362.871 220.714 362.871H218.662C218.005 362.871 217.479 362.337 217.479 361.689V360.217Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M239.228 373.503C244.672 373.503 248.854 369.378 248.854 363.966C248.854 358.554 244.672 354.43 239.228 354.43C233.783 354.43 229.602 358.554 229.602 363.966C229.602 369.378 233.783 373.503 239.228 373.503ZM239.228 358.686C242.173 358.686 244.33 360.892 244.33 363.966C244.33 367.04 242.173 369.247 239.228 369.247C236.282 369.247 234.125 367.04 234.125 363.966C234.125 360.892 236.282 358.686 239.228 358.686Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M261.113 368.04L263.541 364.344C264.19 363.363 265.715 363.819 265.715 364.992V371.98C265.715 372.637 266.25 373.162 266.899 373.162H269.003C269.66 373.162 270.186 372.628 270.186 371.98V355.963C270.186 355.307 269.652 354.781 269.003 354.781H265.75C265.356 354.781 264.988 354.983 264.768 355.307L261.902 359.606C261.428 360.307 260.403 360.307 259.929 359.606L257.062 355.307C256.843 354.974 256.475 354.781 256.081 354.781H252.828C252.171 354.781 251.645 355.315 251.645 355.963V371.98C251.645 372.637 252.179 373.162 252.828 373.162H254.932C255.59 373.162 256.116 372.628 256.116 371.98V364.992C256.116 363.819 257.65 363.363 258.29 364.344L260.718 368.04H261.139H261.113Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M290.645 358.686C292.372 358.686 293.748 359.448 294.546 360.744C294.756 361.076 295.133 361.26 295.519 361.26H297.93C298.789 361.26 299.376 360.367 299.017 359.588C297.579 356.496 294.502 354.43 290.645 354.43C285.122 354.43 281.098 358.554 281.098 363.966C281.098 369.378 285.122 373.503 290.645 373.503C294.502 373.503 297.579 371.436 299.017 368.345C299.376 367.565 298.798 366.672 297.93 366.672H295.519C295.125 366.672 294.748 366.856 294.546 367.189C293.739 368.476 292.372 369.247 290.645 369.247C287.594 369.247 285.621 367.04 285.621 363.966C285.621 360.892 287.594 358.686 290.645 358.686Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M306.804 355.549L300.983 371.566C300.702 372.336 301.272 373.151 302.096 373.151H304.296C304.805 373.151 305.252 372.827 305.419 372.354L306.181 370.138C306.348 369.657 306.795 369.341 307.303 369.341H312.502C313.011 369.341 313.458 369.665 313.624 370.138L314.387 372.354C314.554 372.836 315.001 373.151 315.509 373.151H317.71C318.534 373.151 319.104 372.336 318.823 371.566L313.002 355.549C312.835 355.085 312.388 354.77 311.888 354.77H307.926C307.426 354.77 306.979 355.085 306.812 355.549H306.804ZM308.469 363.527L308.785 362.625C309.153 361.556 310.661 361.556 311.029 362.625L311.345 363.527C311.608 364.297 311.038 365.094 310.223 365.094H309.6C308.785 365.094 308.215 364.297 308.478 363.527H308.469Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M322.942 373.162H325.046C325.704 373.162 326.23 372.628 326.23 371.98V364.992C326.23 363.819 327.764 363.363 328.404 364.344L330.833 368.04H331.253L333.682 364.344C334.33 363.363 335.856 363.819 335.856 364.992V371.98C335.856 372.637 336.391 373.162 337.039 373.162H339.143C339.801 373.162 340.327 372.628 340.327 371.98V355.963C340.327 355.307 339.792 354.781 339.143 354.781H335.891C335.497 354.781 335.128 354.983 334.909 355.307L332.042 359.606C331.569 360.307 330.543 360.307 330.07 359.606L327.203 355.307C326.984 354.974 326.616 354.781 326.221 354.781H322.969C322.311 354.781 321.785 355.315 321.785 355.963V371.98C321.785 372.637 322.32 373.162 322.969 373.162H322.942Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M358.53 361.075C358.53 357.003 355.794 354.77 351.902 354.77H345.195C344.538 354.77 344.012 355.304 344.012 355.952V371.969C344.012 372.625 344.547 373.151 345.195 373.151H347.299C347.957 373.151 348.483 372.617 348.483 371.969V368.658C348.483 368.002 349.018 367.476 349.666 367.476H351.902C355.794 367.476 358.53 365.138 358.53 361.066V361.075ZM351.718 363.229H349.666C349.009 363.229 348.483 362.695 348.483 362.047V360.208C348.483 359.551 349.018 359.026 349.666 359.026H351.718C353.243 359.026 354.006 359.735 354.006 361.127C354.006 362.52 353.243 363.229 351.718 363.229Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M369.083 354.77H365.12C364.62 354.77 364.173 355.085 364.007 355.549L358.186 371.566C357.905 372.336 358.475 373.151 359.299 373.151H361.499C362.008 373.151 362.455 372.827 362.622 372.354L363.384 370.138C363.551 369.657 363.998 369.341 364.507 369.341H369.705C370.214 369.341 370.661 369.665 370.827 370.138L371.59 372.354C371.757 372.836 372.204 373.151 372.712 373.151H374.913C375.737 373.151 376.307 372.336 376.026 371.566L370.205 355.549C370.038 355.085 369.591 354.77 369.092 354.77H369.083ZM367.417 365.094H366.795C365.979 365.094 365.409 364.297 365.672 363.527L365.979 362.625C366.348 361.556 367.855 361.556 368.224 362.625L368.53 363.527C368.793 364.297 368.224 365.094 367.408 365.094H367.417Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M382.381 354.77H380.277C379.624 354.77 379.094 355.299 379.094 355.952V371.977C379.094 372.63 379.624 373.16 380.277 373.16H382.381C383.035 373.16 383.565 372.63 383.565 371.977V355.952C383.565 355.299 383.035 354.77 382.381 354.77Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M395.56 373.502C397.551 373.502 399.155 372.898 400.224 371.987C400.61 371.663 401.162 371.838 401.312 372.259C401.478 372.74 401.925 373.161 402.434 373.161H403.977C404.634 373.161 405.16 372.626 405.16 371.978V364.176C405.16 363.519 404.625 362.994 403.977 362.994H396.113C395.455 362.994 394.929 363.528 394.929 364.176V365.542C394.929 366.199 395.464 366.724 396.113 366.724H397.542C398.874 366.724 399.19 368.563 397.945 369.027C397.323 369.255 396.63 369.377 395.894 369.377C392.606 369.377 390.975 367.013 390.975 364.071C390.975 360.839 392.948 358.685 395.999 358.685C397.726 358.685 399.093 359.368 399.9 360.541C400.11 360.848 400.47 361.023 400.847 361.023H403.258C404.134 361.023 404.722 360.095 404.327 359.307C402.048 354.727 395.894 352.546 388.985 356.443C388.828 356.531 388.608 356.75 388.521 356.907C383.445 365.787 388.477 373.502 395.552 373.502H395.56Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M420.273 373.162H423.491C424.148 373.162 424.674 372.628 424.674 371.98V355.963C424.674 355.307 424.139 354.781 423.491 354.781H421.386C420.729 354.781 420.203 355.315 420.203 355.963V362.531L420.545 366.367L413.996 355.359C413.786 355 413.391 354.781 412.979 354.781H409.762C409.104 354.781 408.578 355.315 408.578 355.963V371.98C408.578 372.637 409.113 373.162 409.762 373.162H411.866C412.523 373.162 413.049 372.628 413.049 371.98V365.412L412.681 361.577L419.256 372.585C419.467 372.944 419.861 373.162 420.273 373.162Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M442.532 367.592C442.532 363.835 440.086 362.679 434.931 361.576C433.116 361.181 432.275 361.076 432.275 360.209C432.275 359.421 432.879 358.607 434.878 358.607C436.562 358.607 437.289 359.141 437.64 359.912C437.824 360.306 438.245 360.525 438.674 360.525H440.936C441.725 360.525 442.304 359.763 442.085 359.01C441.339 356.479 439.244 354.43 434.878 354.43C431.38 354.43 427.777 356.374 427.777 360.42C427.777 364.229 430.854 365.332 433.853 365.963C436.93 366.62 438.034 366.698 438.034 367.776C438.034 368.748 437.219 369.326 435.404 369.326C433.458 369.326 432.564 368.835 432.073 367.784C431.88 367.373 431.494 367.093 431.038 367.093H428.759C427.97 367.093 427.383 367.854 427.619 368.608C428.443 371.2 430.705 373.503 435.422 373.503C438.368 373.503 442.523 372.084 442.523 367.592H442.532Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M370.023 241.889H374.897C375.572 241.889 376.116 241.347 376.116 240.672V239.315C376.116 238.641 375.572 238.098 374.897 238.098H366.043C365.368 238.098 364.824 238.641 364.824 239.315V253.256C364.824 253.931 365.368 254.473 366.043 254.473H367.586C368.261 254.473 368.804 253.931 368.804 253.256V249.491C368.804 248.816 369.348 248.273 370.023 248.273H374.24C374.915 248.273 375.458 247.73 375.458 247.056V245.699C375.458 245.025 374.915 244.482 374.24 244.482H370.023C369.348 244.482 368.804 243.939 368.804 243.264V243.098C368.804 242.424 369.348 241.881 370.023 241.881V241.889Z"
                    fill="#B5DD81"/>
                  <path
                    d="M389.406 247.862C390.712 247.039 391.493 245.629 391.493 243.571C391.493 240.156 389.336 238.098 385.873 238.098H379.789C379.114 238.098 378.57 238.641 378.57 239.315V253.256C378.57 253.931 379.114 254.473 379.789 254.473H381.332C382.007 254.473 382.55 253.931 382.55 253.256V248.79H383.147C383.55 248.79 383.927 248.992 384.155 249.324L387.293 253.939C387.521 254.272 387.898 254.473 388.302 254.473H390.169C391.159 254.473 391.738 253.361 391.159 252.556L389.055 249.596C388.652 249.027 388.819 248.238 389.406 247.862ZM385.426 245.305H383.76C383.085 245.305 382.542 244.762 382.542 244.088V243.107C382.542 242.432 383.085 241.889 383.76 241.889H385.426C386.759 241.889 387.46 242.476 387.46 243.623C387.46 244.771 386.802 245.305 385.426 245.305Z"
                    fill="#B5DD81"/>
                  <path
                    d="M401.914 254.782C406.762 254.782 410.488 251.113 410.488 246.287C410.488 241.462 406.762 237.793 401.914 237.793C397.066 237.793 393.34 241.462 393.34 246.287C393.34 251.113 397.066 254.782 401.914 254.782ZM401.914 241.585C404.535 241.585 406.455 243.546 406.455 246.287C406.455 249.028 404.535 250.99 401.914 250.99C399.293 250.99 397.373 249.028 397.373 246.287C397.373 243.546 399.293 241.585 401.914 241.585Z"
                    fill="#B5DD81"/>
                  <path
                    d="M424.593 238.641L422.218 242.196C421.735 242.914 420.675 242.914 420.193 242.196L417.817 238.641C417.589 238.299 417.212 238.098 416.8 238.098H414.152C413.477 238.098 412.934 238.641 412.934 239.315V253.256C412.934 253.931 413.477 254.473 414.152 254.473H415.695C416.37 254.473 416.914 253.931 416.914 253.256V247.739C416.914 246.531 418.483 246.058 419.149 247.074L421.008 249.911H421.385L423.243 247.074C423.91 246.067 425.479 246.531 425.479 247.739V253.256C425.479 253.931 426.022 254.473 426.698 254.473H428.241C428.916 254.473 429.459 253.931 429.459 253.256V239.315C429.459 238.641 428.916 238.098 428.241 238.098H425.593C425.19 238.098 424.804 238.299 424.576 238.641H424.593Z"
                    fill="#B5DD81"/>
                  <path
                    d="M364.824 274.846C364.824 275.52 365.368 276.063 366.043 276.063H370.935C375.713 276.063 379.316 272.534 379.316 267.875C379.316 263.217 375.713 259.688 370.935 259.688H366.043C365.368 259.688 364.824 260.23 364.824 260.905V274.846ZM368.804 264.697C368.804 264.022 369.348 263.479 370.023 263.479H370.935C374.643 263.479 376.73 267.096 374.275 271.615C374.056 272.018 373.644 272.28 373.188 272.28H370.023C369.348 272.28 368.804 271.737 368.804 271.063V264.705V264.697Z"
                    fill="#B5DD81"/>
                  <path
                    d="M381.891 260.913V274.855C381.891 275.529 382.434 276.072 383.109 276.072H391.964C392.639 276.072 393.182 275.529 393.182 274.855V273.498C393.182 272.823 392.639 272.28 391.964 272.28H387.089C386.414 272.28 385.871 271.737 385.871 271.063V270.923C385.871 270.249 386.414 269.706 387.089 269.706H391.306C391.981 269.706 392.525 269.163 392.525 268.488V267.131C392.525 266.457 391.981 265.914 391.306 265.914H387.089C386.414 265.914 385.871 265.371 385.871 264.697C385.871 264.022 386.414 263.479 387.089 263.479H391.964C392.639 263.479 393.182 262.936 393.182 262.262V260.905C393.182 260.23 392.639 259.688 391.964 259.688H383.109C382.434 259.688 381.891 260.23 381.891 260.905V260.913Z"
                    fill="#B5DD81"/>
                  <path
                    d="M397.927 276.063C398.444 276.063 398.909 275.731 399.075 275.24L399.68 273.489C399.847 272.998 400.312 272.666 400.829 272.666H405.23C405.747 272.666 406.212 272.998 406.378 273.489L406.983 275.24C407.15 275.731 407.614 276.063 408.132 276.063H409.745C410.586 276.063 411.174 275.223 410.893 274.435L405.826 260.493C405.651 260.011 405.195 259.688 404.677 259.688H401.372C400.864 259.688 400.399 260.011 400.224 260.493L395.157 274.435C394.867 275.231 395.455 276.063 396.305 276.063H397.918H397.927ZM401.837 267.271L401.89 267.131C402.267 266.036 403.818 266.036 404.195 267.131L404.248 267.271C404.52 268.059 403.932 268.882 403.099 268.882H403.003C402.17 268.882 401.583 268.059 401.855 267.271H401.837Z"
                    fill="#B5DD81"/>
                  <path
                    d="M428.206 267.875C428.206 263.225 424.603 259.688 419.825 259.688H414.933C414.258 259.688 413.715 260.23 413.715 260.905V274.846C413.715 275.52 414.258 276.063 414.933 276.063H419.825C424.603 276.063 428.206 272.534 428.206 267.875ZM423.165 271.615C422.946 272.018 422.534 272.28 422.078 272.28H418.914C418.239 272.28 417.695 271.737 417.695 271.063V264.705C417.695 264.031 418.239 263.488 418.914 263.488H419.825C423.534 263.488 425.62 267.105 423.165 271.623V271.615Z"
                    fill="#B5DD81"/>
                  <path
                    d="M432.004 276.063H440.201C440.876 276.063 441.419 275.52 441.419 274.846V273.489C441.419 272.814 440.876 272.271 440.201 272.271H435.984C435.309 272.271 434.765 271.729 434.765 271.054V260.905C434.765 260.23 434.222 259.688 433.547 259.688H432.004C431.329 259.688 430.785 260.23 430.785 260.905V274.846C430.785 275.52 431.329 276.063 432.004 276.063Z"
                    fill="#B5DD81"/>
                  <path
                    d="M446.398 259.695H444.855C444.182 259.695 443.637 260.24 443.637 260.913V274.854C443.637 275.526 444.182 276.071 444.855 276.071H446.398C447.071 276.071 447.617 275.526 447.617 274.854V260.913C447.617 260.24 447.071 259.695 446.398 259.695Z"
                    fill="#B5DD81"/>
                  <path
                    d="M451.991 276.063H453.534C454.209 276.063 454.753 275.52 454.753 274.846V269.154L454.428 265.747L460.232 275.468C460.451 275.836 460.846 276.063 461.275 276.063H463.888C464.563 276.063 465.106 275.52 465.106 274.846V260.905C465.106 260.23 464.563 259.688 463.888 259.688H462.345C461.67 259.688 461.126 260.23 461.126 260.905V266.597L461.433 270.003L455.656 260.283C455.436 259.915 455.042 259.688 454.612 259.688H452C451.325 259.688 450.781 260.23 450.781 260.905V274.846C450.781 275.52 451.325 276.063 452 276.063H451.991Z"
                    fill="#B5DD81"/>
                  <path
                    d="M469.488 276.06H478.343C479.018 276.06 479.561 275.517 479.561 274.843V273.486C479.561 272.811 479.018 272.269 478.343 272.269H473.468C472.793 272.269 472.25 271.726 472.25 271.051V270.911C472.25 270.237 472.793 269.694 473.468 269.694H477.685C478.36 269.694 478.904 269.151 478.904 268.477V267.119C478.904 266.445 478.36 265.902 477.685 265.902H473.468C472.793 265.902 472.25 265.359 472.25 264.685C472.25 264.011 472.793 263.468 473.468 263.468H478.343C479.018 263.468 479.561 262.925 479.561 262.25V260.893C479.561 260.219 479.018 259.676 478.343 259.676H469.488C468.813 259.676 468.27 260.219 468.27 260.893V274.834C468.27 275.509 468.813 276.052 469.488 276.052V276.06Z"
                    fill="#B5DD81"/>
                  <path
                    d="M488.426 276.367C491.047 276.367 494.747 275.106 494.747 271.104C494.747 267.759 492.573 266.734 487.979 265.744C486.366 265.394 485.612 265.298 485.612 264.527C485.612 263.827 486.147 263.1 487.926 263.1C489.364 263.1 490.013 263.538 490.346 264.177C490.548 264.571 490.969 264.807 491.407 264.807H493.099C493.949 264.807 494.519 263.958 494.239 263.161C493.493 261.051 491.635 259.387 487.918 259.387C484.805 259.387 481.597 261.121 481.597 264.72C481.597 268.109 484.332 269.09 487.006 269.659C489.741 270.246 490.732 270.316 490.732 271.27C490.732 272.137 490.004 272.654 488.391 272.654C486.778 272.654 485.928 272.251 485.481 271.393C485.261 270.964 484.849 270.666 484.367 270.666H482.71C481.851 270.666 481.272 271.533 481.579 272.338C482.395 274.51 484.411 276.376 488.417 276.376L488.426 276.367Z"
                    fill="#B5DD81"/>
                  <path
                    d="M66.7587 407.365C69.5378 407.365 71.7208 408.652 72.9218 410.815C73.0884 411.113 73.3865 411.314 73.7284 411.314H79.2953C79.9177 411.314 80.3649 410.701 80.1632 410.115C78.2696 404.624 73.2374 400.832 66.7587 400.832C58.2812 400.832 52.1094 407.163 52.1094 415.465C52.1094 423.767 58.2812 430.098 66.7587 430.098C73.2374 430.098 78.2696 426.307 80.1632 420.816C80.3649 420.229 79.9265 419.616 79.3041 419.616C77.691 419.616 74.9119 419.616 73.7371 419.616C73.3952 419.616 73.0972 419.817 72.9306 420.115C71.7295 422.278 69.5466 423.566 66.7675 423.566C62.086 423.566 59.0615 420.177 59.0615 415.465C59.0615 410.754 62.086 407.365 66.7675 407.365H66.7587Z"
                    fill="#B5DD81"/>
                  <path
                    d="M113.083 415.465C113.083 407.163 106.666 400.832 98.3112 400.832C89.9564 400.832 83.5391 407.163 83.5391 415.465C83.5391 423.767 89.9564 430.098 98.3112 430.098C106.666 430.098 113.083 423.767 113.083 415.465ZM90.4912 415.465C90.4912 410.745 93.7962 407.365 98.3199 407.365C102.844 407.365 106.149 410.754 106.149 415.465C106.149 420.177 102.844 423.566 98.3199 423.566C93.7962 423.566 90.4912 420.177 90.4912 415.465Z"
                    fill="#B5DD81"/>
                  <path
                    d="M120.35 435.95L115.835 439.882C115.634 440.057 115.52 440.302 115.52 440.565V442.089C115.52 442.588 115.923 442.999 116.431 442.999H127.363C127.863 442.999 128.275 442.597 128.275 442.089V440.101C128.275 439.602 127.872 439.19 127.363 439.19H122.086L122.13 439.164L123.655 437.824C126.241 435.565 127.96 434.137 127.96 431.571C127.96 428.725 125.821 426.238 122.103 426.238C118.833 426.238 116.37 428.139 115.818 431.239C115.721 431.782 116.168 432.281 116.721 432.281H119.009C119.412 432.281 119.754 431.992 119.859 431.598C120.14 430.564 120.788 430.048 121.919 430.048C123.427 430.048 123.918 430.967 123.918 431.764C123.918 432.754 123.427 433.244 120.341 435.95H120.35Z"
                    fill="#B5DD81"/>
                  <path
                    d="M151.799 407.892H162.573C163.073 407.892 163.485 407.489 163.485 406.981V402.27C163.485 401.771 163.082 401.359 162.573 401.359H144.943C144.443 401.359 144.031 401.762 144.031 402.27V428.673C144.031 429.172 144.435 429.584 144.943 429.584H149.984C150.484 429.584 150.896 429.181 150.896 428.673V419.811C150.896 419.312 151.299 418.9 151.807 418.9H161.451C161.951 418.9 162.363 418.497 162.363 417.989V413.278C162.363 412.779 161.959 412.367 161.451 412.367H151.807C151.308 412.367 150.896 411.964 150.896 411.456V408.803C150.896 408.304 151.299 407.892 151.807 407.892H151.799Z"
                    fill="#D6E7FF"/>
                  <path
                    d="M181.28 400.832C172.925 400.832 166.508 407.163 166.508 415.465C166.508 423.767 172.925 430.098 181.28 430.098C189.635 430.098 196.052 423.767 196.052 415.465C196.052 407.163 189.635 400.832 181.28 400.832ZM181.28 423.566C176.756 423.566 173.451 420.177 173.451 415.465C173.451 410.754 176.756 407.365 181.28 407.365C185.804 407.365 189.109 410.754 189.109 415.465C189.109 420.177 185.804 423.566 181.28 423.566Z"
                    fill="#D6E7FF"/>
                  <path
                    d="M214.049 430.098C222.404 430.098 228.822 423.767 228.822 415.465C228.822 407.163 222.404 400.832 214.049 400.832C205.695 400.832 199.277 407.163 199.277 415.465C199.277 423.767 205.695 430.098 214.049 430.098ZM214.049 407.365C218.573 407.365 221.878 410.754 221.878 415.465C221.878 420.177 218.573 423.566 214.049 423.566C209.526 423.566 206.221 420.177 206.221 415.465C206.221 410.754 209.526 407.365 214.049 407.365Z"
                    fill="#D6E7FF"/>
                  <path
                    d="M239.108 408.795V428.665C239.108 429.164 239.511 429.576 240.019 429.576H245.06C245.56 429.576 245.972 429.173 245.972 428.665V408.795C245.972 408.296 246.375 407.884 246.884 407.884H252.775C253.275 407.884 253.687 407.482 253.687 406.974V402.262C253.687 401.763 253.283 401.352 252.775 401.352H232.357C231.857 401.352 231.445 401.754 231.445 402.262V406.974C231.445 407.473 231.849 407.884 232.357 407.884H238.205C238.704 407.884 239.116 408.287 239.116 408.795H239.108Z"
                    fill="#D6E7FF"/>
                  <path
                    d="M279.784 411.036C279.784 404.792 275.585 401.359 269.615 401.359H258.42C257.92 401.359 257.508 401.762 257.508 402.27V428.673C257.508 429.172 257.911 429.584 258.42 429.584H263.46C263.96 429.584 264.372 429.181 264.372 428.673V421.781C264.372 421.282 264.776 420.87 265.284 420.87H269.624C275.594 420.87 279.793 417.28 279.793 411.036H279.784ZM265.284 414.337C264.784 414.337 264.372 413.935 264.372 413.427V408.794C264.372 408.295 264.776 407.883 265.284 407.883H269.334C271.675 407.883 272.841 408.969 272.841 411.106C272.841 413.243 271.666 414.329 269.334 414.329H265.284V414.337Z"
                    fill="#D6E7FF"/>
                  <path
                    d="M284.23 402.27V428.673C284.23 429.172 284.634 429.584 285.142 429.584H290.183C290.683 429.584 291.095 429.181 291.095 428.673V420.695C291.095 420.196 291.498 419.784 292.007 419.784H292.752C293.05 419.784 293.339 419.933 293.506 420.178L299.625 429.181C299.792 429.426 300.072 429.575 300.379 429.575H306.533C307.27 429.575 307.699 428.743 307.278 428.139L301.545 420.082C301.212 419.609 301.405 418.961 301.931 418.725C304.78 417.42 306.525 414.828 306.525 410.791C306.525 404.906 302.816 401.359 296.837 401.359H285.16C284.66 401.359 284.248 401.762 284.248 402.27H284.23ZM291.086 408.794C291.086 408.295 291.489 407.883 291.998 407.883H296.048C298.345 407.883 299.555 408.891 299.555 410.87C299.555 412.849 298.424 413.768 296.048 413.768H291.998C291.498 413.768 291.086 413.365 291.086 412.858V408.785V408.794Z"
                    fill="#D6E7FF"/>
                  <path
                    d="M317.252 401.359H312.22C311.717 401.359 311.309 401.767 311.309 402.27V428.664C311.309 429.167 311.717 429.575 312.22 429.575H317.252C317.756 429.575 318.164 429.167 318.164 428.664V402.27C318.164 401.767 317.756 401.359 317.252 401.359Z"
                    fill="#D6E7FF"/>
                  <path
                    d="M323.61 402.27V428.673C323.61 429.172 324.014 429.584 324.522 429.584H329.563C330.063 429.584 330.475 429.181 330.475 428.673V417.726L330.291 415.817C330.203 414.854 331.483 414.434 331.974 415.266L340.259 429.137C340.425 429.408 340.723 429.584 341.039 429.584H347.403C347.903 429.584 348.315 429.181 348.315 428.673V402.27C348.315 401.771 347.912 401.359 347.403 401.359H342.363C341.863 401.359 341.451 401.762 341.451 402.27V413.208L341.626 415.169C341.714 416.133 340.434 416.544 339.934 415.712L331.658 401.806C331.492 401.534 331.194 401.359 330.878 401.359H324.513C324.014 401.359 323.602 401.762 323.602 402.27H323.61Z"
                    fill="#D6E7FF"/>
                  <path
                    d="M352.856 407.892H358.704C359.203 407.892 359.615 408.295 359.615 408.803V428.673C359.615 429.172 360.019 429.584 360.527 429.584H365.568C366.068 429.584 366.48 429.181 366.48 428.673V408.803C366.48 408.304 366.883 407.892 367.392 407.892H373.283C373.783 407.892 374.195 407.489 374.195 406.981V402.27C374.195 401.771 373.791 401.359 373.283 401.359H352.865C352.365 401.359 351.953 401.762 351.953 402.27V406.981C351.953 407.481 352.356 407.892 352.865 407.892H352.856Z"
                    fill="#D6E7FF"/>
                  <path
                    d="M148.21 455.007C148.307 455.418 148.675 455.699 149.096 455.699H151.901C152.322 455.699 152.69 455.41 152.786 455.007L153.838 450.646L153.856 450.558L154.557 445.602L154.899 445.619L155.618 450.558L155.636 450.637L156.688 454.998C156.784 455.41 157.152 455.69 157.573 455.69H160.361C160.782 455.69 161.15 455.401 161.246 454.998L164.218 442.703C164.359 442.134 163.92 441.582 163.333 441.582H161.694C161.273 441.582 160.905 441.871 160.808 442.283L159.37 448.29L159.353 448.386L158.932 451.705L158.494 448.386L158.476 448.29L157.038 442.283C156.942 441.871 156.574 441.582 156.153 441.582H153.295C152.874 441.582 152.506 441.871 152.409 442.283L150.972 448.29L150.954 448.386L150.533 451.626L150.113 448.386L150.095 448.29L148.657 442.283C148.561 441.871 148.193 441.582 147.772 441.582H146.115C145.528 441.582 145.089 442.134 145.229 442.703L148.201 454.998L148.21 455.007Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M168.918 441.59H167.314C166.811 441.59 166.402 441.998 166.402 442.501V454.787C166.402 455.29 166.811 455.698 167.314 455.698H168.918C169.422 455.698 169.83 455.29 169.83 454.787V442.501C169.83 441.998 169.422 441.59 168.918 441.59Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M172.653 454.796C172.653 455.295 173.057 455.706 173.565 455.706H180.903C181.403 455.706 181.815 455.304 181.815 454.796V453.351C181.815 452.852 181.411 452.44 180.903 452.44H176.993C176.493 452.44 176.081 452.037 176.081 451.529V442.501C176.081 442.001 175.678 441.59 175.169 441.59H173.556C173.057 441.59 172.645 441.993 172.645 442.501V454.796H172.653Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M183.735 442.501V454.796C183.735 455.295 184.139 455.706 184.647 455.706H191.985C192.485 455.706 192.897 455.304 192.897 454.796V453.351C192.897 452.852 192.493 452.44 191.985 452.44H188.075C187.575 452.44 187.163 452.037 187.163 451.529V442.501C187.163 442.001 186.76 441.59 186.251 441.59H184.638C184.139 441.59 183.727 441.993 183.727 442.501H183.735Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M204.294 454.796V451.328C204.294 451.039 204.522 450.811 204.811 450.811H205.092C205.267 450.811 205.425 450.899 205.521 451.039L208.423 455.312C208.59 455.557 208.87 455.706 209.177 455.706H211.132C211.869 455.706 212.298 454.874 211.877 454.27L209.826 451.38C209.519 450.951 209.642 450.347 210.089 450.084C211.29 449.384 212.009 448.149 212.009 446.31C212.009 443.368 210.15 441.59 207.17 441.59H201.787C201.287 441.59 200.875 441.993 200.875 442.501V454.796C200.875 455.295 201.278 455.706 201.787 455.706H203.4C203.9 455.706 204.312 455.304 204.312 454.796H204.294ZM204.294 445.767C204.294 445.268 204.697 444.856 205.206 444.856H206.775C207.924 444.856 208.528 445.364 208.528 446.345C208.528 447.326 207.967 447.799 206.775 447.799H205.206C204.706 447.799 204.294 447.396 204.294 446.888V445.758V445.767Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M219.144 444.856H223.624C224.124 444.856 224.536 444.453 224.536 443.946V442.501C224.536 442.001 224.133 441.59 223.624 441.59H215.716C215.217 441.59 214.805 441.993 214.805 442.501V454.796C214.805 455.295 215.208 455.706 215.716 455.706H223.624C224.124 455.706 224.536 455.304 224.536 454.796V453.351C224.536 452.852 224.133 452.44 223.624 452.44H219.144C218.645 452.44 218.233 452.037 218.233 451.529V451.126C218.233 450.627 218.636 450.216 219.144 450.216H223.054C223.554 450.216 223.966 449.813 223.966 449.305V447.86C223.966 447.361 223.563 446.949 223.054 446.949H219.144C218.645 446.949 218.233 446.546 218.233 446.038V445.758C218.233 445.259 218.636 444.848 219.144 444.848V444.856Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M239.141 448.648C239.141 444.637 236.038 441.59 231.917 441.59H227.56C227.06 441.59 226.648 441.993 226.648 442.501V454.796C226.648 455.295 227.052 455.706 227.56 455.706H231.917C236.038 455.706 239.141 452.659 239.141 448.648ZM230.085 451.529V445.767C230.085 445.268 230.488 444.856 230.997 444.856H231.926C235.152 444.856 236.958 448.035 234.74 451.985C234.582 452.265 234.276 452.431 233.951 452.431H230.997C230.497 452.431 230.085 452.028 230.085 451.52V451.529Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M243.945 441.59H242.279C241.779 441.59 241.367 442.001 241.367 442.501L241.385 449.935C241.385 453.946 243.927 455.96 247.32 455.96C250.713 455.96 253.255 453.946 253.255 449.935L253.273 442.501C253.273 442.001 252.869 441.59 252.361 441.59H250.695C250.195 441.59 249.783 441.993 249.783 442.501V449.734C249.783 451.95 248.793 452.694 247.32 452.694C245.847 452.694 244.856 451.95 244.856 449.734V442.501C244.856 442.001 244.453 441.59 243.945 441.59Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M262.812 444.595C264.135 444.595 265.187 445.181 265.81 446.171C265.968 446.425 266.257 446.574 266.564 446.574H268.414C269.071 446.574 269.527 445.891 269.246 445.286C268.142 442.913 265.783 441.328 262.82 441.328C258.586 441.328 255.5 444.489 255.5 448.64C255.5 452.791 258.586 455.953 262.82 455.953C265.783 455.953 268.142 454.368 269.246 451.994C269.527 451.399 269.08 450.707 268.414 450.707H266.564C266.266 450.707 265.976 450.847 265.81 451.11C265.196 452.099 264.144 452.686 262.812 452.686C260.471 452.686 258.954 450.996 258.954 448.632C258.954 446.267 260.471 444.577 262.812 444.577V444.595Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M280.624 441.591H272.716C272.217 441.591 271.805 441.994 271.805 442.502V454.797C271.805 455.296 272.208 455.707 272.716 455.707H280.624C281.124 455.707 281.536 455.304 281.536 454.797V453.352C281.536 452.852 281.133 452.441 280.624 452.441H276.144C275.645 452.441 275.233 452.038 275.233 451.53V451.127C275.233 450.628 275.636 450.217 276.144 450.217H280.054C280.554 450.217 280.966 449.814 280.966 449.306V447.861C280.966 447.362 280.563 446.95 280.054 446.95H276.144C275.645 446.95 275.233 446.547 275.233 446.039V445.759C275.233 445.26 275.636 444.848 276.144 444.848H280.624C281.124 444.848 281.536 444.446 281.536 443.938V442.493C281.536 441.994 281.133 441.582 280.624 441.582V441.591Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M199.314 496.855H183.569C182.999 496.855 182.534 497.319 182.534 497.889V502.32C182.534 502.889 182.999 503.353 183.569 503.353H190.266C191.143 503.353 191.608 504.36 191.064 505.043C189.521 506.987 187.031 507.977 184.226 507.977C178.501 507.977 175.661 503.861 175.661 498.738C175.661 493.116 179.097 489.359 184.41 489.359C187.557 489.359 190.03 490.664 191.397 492.897C191.59 493.221 191.923 493.431 192.3 493.431C193.624 493.431 196.789 493.431 198.63 493.431C199.34 493.431 199.849 492.722 199.603 492.056C196.324 482.949 184.848 478.168 171.935 485.602C171.803 485.681 171.619 485.856 171.54 485.997C162.44 501.567 171.234 515.158 183.63 515.158C187.251 515.158 190.135 514.002 192.002 512.294C192.651 511.699 193.703 512.189 193.703 513.074V513.441C193.703 514.098 194.168 514.554 194.737 514.554H199.314C199.884 514.554 200.348 514.089 200.348 513.52V497.88C200.348 497.311 199.884 496.847 199.314 496.847V496.855Z"
                    fill="#FFDED4"/>
                  <path
                    d="M227.568 482.538H207.562C206.992 482.538 206.527 483.002 206.527 483.571V513.53C206.527 514.099 206.992 514.563 207.562 514.563H227.568C228.138 514.563 228.602 514.099 228.602 513.53V508.179C228.602 507.61 228.138 507.146 227.568 507.146H215.347C214.777 507.146 214.312 506.681 214.312 506.112V503.144C214.312 502.574 214.777 502.11 215.347 502.11H226.288C226.858 502.11 227.322 501.646 227.322 501.077V495.726C227.322 495.157 226.858 494.693 226.288 494.693H215.347C214.777 494.693 214.312 494.229 214.312 493.66V490.962C214.312 490.393 214.777 489.929 215.347 489.929H227.568C228.138 489.929 228.602 489.465 228.602 488.896V483.545C228.602 482.976 228.138 482.512 227.568 482.512V482.538Z"
                    fill="#FFDED4"/>
                  <path
                    d="M255.553 482.539H232.382C231.812 482.539 231.348 483.003 231.348 483.572V488.923C231.348 489.492 231.812 489.956 232.382 489.956H239.019C239.588 489.956 240.053 490.42 240.053 490.99V513.539C240.053 514.109 240.518 514.573 241.088 514.573H246.812C247.382 514.573 247.847 514.109 247.847 513.539V490.99C247.847 490.42 248.311 489.956 248.881 489.956H255.562C256.131 489.956 256.596 489.492 256.596 488.923V483.572C256.596 483.003 256.131 482.539 255.562 482.539H255.553Z"
                    fill="#FFDED4"/>
                  <path
                    d="M273.297 498.553C273.297 507.975 280.582 515.156 290.059 515.156C299.536 515.156 306.821 507.975 306.821 498.553C306.821 489.13 299.536 481.949 290.059 481.949C280.582 481.949 273.297 489.13 273.297 498.553ZM298.94 498.553C298.94 503.903 295.188 507.748 290.059 507.748C284.93 507.748 281.178 503.903 281.178 498.553C281.178 493.202 284.93 489.358 290.059 489.358C295.188 489.358 298.94 493.202 298.94 498.553Z"
                    fill="#FFDED4"/>
                  <path
                    d="M339.653 513.531V483.572C339.653 483.003 339.188 482.539 338.618 482.539H332.893C332.323 482.539 331.859 483.003 331.859 483.572V495.99L332.06 498.214C332.157 499.309 330.702 499.773 330.149 498.827L320.76 483.047C320.576 482.732 320.234 482.539 319.875 482.539H312.66C312.09 482.539 311.625 483.003 311.625 483.572V513.531C311.625 514.1 312.09 514.564 312.66 514.564H318.384C318.954 514.564 319.419 514.1 319.419 513.531V501.113L319.208 498.941C319.103 497.847 320.558 497.374 321.119 498.319L330.518 514.065C330.702 514.38 331.044 514.564 331.403 514.564H338.627C339.197 514.564 339.661 514.1 339.661 513.531H339.653Z"
                    fill="#FFDED4"/>
                  <path
                    d="M366.628 513.528V508.177C366.628 507.608 366.163 507.144 365.593 507.144H354.652C354.083 507.144 353.618 506.68 353.618 506.11V483.561C353.618 482.991 353.153 482.527 352.583 482.527H346.859C346.289 482.527 345.824 482.991 345.824 483.561V513.519C345.824 514.088 346.289 514.552 346.859 514.552H365.585C366.154 514.552 366.619 514.088 366.619 513.519L366.628 513.528Z"
                    fill="#FFDED4"/>
                  <path
                    d="M392.648 482.539H386.274C385.88 482.539 385.52 482.767 385.345 483.126L380.287 493.643C379.91 494.422 378.805 494.422 378.428 493.643L373.37 483.126C373.194 482.767 372.835 482.539 372.44 482.539H366.023C365.251 482.539 364.752 483.353 365.102 484.036L375.36 504.187C375.43 504.336 375.474 504.493 375.474 504.651V513.531C375.474 514.1 375.938 514.564 376.508 514.564H382.233C382.803 514.564 383.267 514.1 383.267 513.531V504.563C383.267 504.397 383.302 504.239 383.381 504.099L393.586 484.045C393.937 483.362 393.437 482.548 392.665 482.548L392.648 482.539Z"
                    fill="#FFDED4"/>
                  <path
                    d="M105.131 541.028C104.482 540.599 104.438 539.697 105.043 539.215C106.726 537.867 107.621 536.019 107.621 533.331C107.621 527.095 102.939 524.074 96.7937 524.074H82.0215C81.3991 524.074 80.8906 524.582 80.8906 525.204V558.139C80.8906 558.761 81.3991 559.269 82.0215 559.269H96.7937C103.842 559.269 108.822 555.6 108.822 548.962C108.822 545.643 107.629 542.674 105.122 541.028H105.131ZM89.4558 533.357C89.4558 532.735 89.9643 532.227 90.5867 532.227H95.6452C97.7054 532.227 98.9678 532.682 98.9678 534.845C98.9678 536.702 97.907 537.508 95.9959 537.508H90.5867C89.9643 537.508 89.4558 537 89.4558 536.378V533.366V533.357ZM96.6008 551.125H90.5867C89.9643 551.125 89.4558 550.617 89.4558 549.995V546.431C89.4558 545.809 89.9643 545.301 90.5867 545.301H96.6008C99.4675 545.301 100.178 546.256 100.178 548.218C100.178 550.03 99.4763 551.134 96.6008 551.134V551.125Z"
                    fill="#FFDED4"/>
                  <path
                    d="M137.252 524.074H115.256C114.633 524.074 114.125 524.582 114.125 525.204V558.139C114.125 558.761 114.633 559.269 115.256 559.269H137.252C137.874 559.269 138.383 558.761 138.383 558.139V552.263C138.383 551.642 137.874 551.134 137.252 551.134H123.812C123.19 551.134 122.681 550.626 122.681 550.004V546.738C122.681 546.116 123.19 545.608 123.812 545.608H135.84C136.463 545.608 136.971 545.1 136.971 544.478V538.602C136.971 537.981 136.463 537.473 135.84 537.473H123.812C123.19 537.473 122.681 536.965 122.681 536.343V533.383C122.681 532.761 123.19 532.253 123.812 532.253H137.252C137.874 532.253 138.383 531.745 138.383 531.124V525.248C138.383 524.626 137.874 524.118 137.252 524.118V524.074Z"
                    fill="#FFDED4"/>
                  <path
                    d="M173.092 524.074H166.806C166.184 524.074 165.675 524.582 165.675 525.204V538.856L165.894 541.299C165.999 542.499 164.404 543.016 163.79 541.974L153.472 524.626C153.27 524.284 152.893 524.074 152.498 524.074H144.565C143.942 524.074 143.434 524.582 143.434 525.204V558.139C143.434 558.761 143.942 559.269 144.565 559.269H150.85C151.473 559.269 151.981 558.761 151.981 558.139V544.487L151.753 542.105C151.639 540.905 153.235 540.38 153.857 541.422L164.193 558.726C164.395 559.068 164.772 559.278 165.167 559.278H173.101C173.723 559.278 174.231 558.77 174.231 558.148V525.213C174.231 524.591 173.723 524.083 173.101 524.083L173.092 524.074Z"
                    fill="#FFDED4"/>
                  <path
                    d="M204.154 524.074H182.158C181.536 524.074 181.027 524.582 181.027 525.204V558.139C181.027 558.761 181.536 559.269 182.158 559.269H204.154C204.777 559.269 205.285 558.761 205.285 558.139V552.263C205.285 551.642 204.777 551.134 204.154 551.134H190.715C190.092 551.134 189.584 550.626 189.584 550.004V546.738C189.584 546.116 190.092 545.608 190.715 545.608H202.743C203.365 545.608 203.874 545.1 203.874 544.478V538.602C203.874 537.981 203.365 537.473 202.743 537.473H190.715C190.092 537.473 189.584 536.965 189.584 536.343V533.383C189.584 532.761 190.092 532.253 190.715 532.253H204.154C204.777 532.253 205.285 531.745 205.285 531.124V525.248C205.285 524.626 204.777 524.118 204.154 524.118V524.074Z"
                    fill="#FFDED4"/>
                  <path
                    d="M233.697 524.074H211.701C211.079 524.074 210.57 524.582 210.57 525.204V558.139C210.57 558.761 211.079 559.269 211.701 559.269H217.987C218.609 559.269 219.118 558.761 219.118 558.139V547.079C219.118 546.457 219.626 545.949 220.249 545.949H232.277C232.899 545.949 233.408 545.442 233.408 544.82V538.944C233.408 538.322 232.899 537.814 232.277 537.814H220.249C219.626 537.814 219.118 537.306 219.118 536.684V533.366C219.118 532.744 219.626 532.236 220.249 532.236H233.688C234.311 532.236 234.819 531.728 234.819 531.106V525.23C234.819 524.608 234.311 524.1 233.688 524.1L233.697 524.074Z"
                    fill="#FFDED4"/>
                  <path
                    d="M247.55 524.074H241.256C240.631 524.074 240.125 524.58 240.125 525.204V558.139C240.125 558.763 240.631 559.269 241.256 559.269H247.55C248.175 559.269 248.681 558.763 248.681 558.139V525.204C248.681 524.58 248.175 524.074 247.55 524.074Z"
                    fill="#FFDED4"/>
                  <path
                    d="M280.063 524.074H254.596C253.973 524.074 253.465 524.582 253.465 525.204V531.08C253.465 531.702 253.973 532.21 254.596 532.21H261.89C262.512 532.21 263.021 532.718 263.021 533.339V558.122C263.021 558.744 263.529 559.252 264.152 559.252H270.437C271.06 559.252 271.568 558.744 271.568 558.122V533.339C271.568 532.718 272.077 532.21 272.699 532.21H280.046C280.668 532.21 281.177 531.702 281.177 531.08V525.204C281.177 524.582 280.668 524.074 280.046 524.074H280.063Z"
                    fill="#FFDED4"/>
                  <path
                    d="M298.819 537.096C295.347 536.343 293.734 536.141 293.734 534.477C293.734 532.971 294.891 531.412 298.713 531.412C302.229 531.412 303.553 532.638 304.175 534.32C304.342 534.775 304.763 535.09 305.245 535.09H311.75C312.46 535.09 312.995 534.451 312.872 533.75C311.864 528.207 307.945 523.426 298.713 523.426C292.016 523.426 285.125 527.148 285.125 534.889C285.125 542.184 291.016 544.294 296.75 545.503C302.641 546.764 304.754 546.912 304.754 548.97C304.754 550.827 303.193 551.939 299.722 551.939C295.645 551.939 293.988 550.818 293.111 548.41C292.954 547.963 292.524 547.666 292.051 547.666H285.554C284.836 547.666 284.292 548.322 284.432 549.023C285.572 554.557 289.806 559.934 299.765 559.934C305.403 559.934 313.354 557.22 313.354 548.62C313.354 541.43 308.673 539.215 298.81 537.104L298.819 537.096Z"
                    fill="#FFDED4"/>
                  <path
                    d="M346.781 524.605C346.956 523.896 346.412 523.204 345.676 523.204H344.221C343.695 523.204 343.239 523.563 343.116 524.071L341.591 530.437L341.564 530.56L341.117 534.133L340.644 530.551L340.626 530.437L339.101 524.062C338.978 523.554 338.522 523.195 337.996 523.195H335.138C334.612 523.195 334.156 523.554 334.034 524.062L332.508 530.437L332.491 530.56L332.044 534.054L331.596 530.56L331.579 530.437L330.053 524.062C329.931 523.554 329.475 523.195 328.949 523.195H327.467C326.731 523.195 326.196 523.878 326.363 524.596L329.501 537.583C329.624 538.091 330.08 538.45 330.606 538.45H333.402C333.928 538.45 334.384 538.091 334.507 537.583L335.612 532.995L335.629 532.889L336.383 527.539L336.751 527.556L337.532 532.889L337.549 532.995L338.654 537.592C338.776 538.1 339.232 538.459 339.758 538.459H342.529C343.055 538.459 343.511 538.1 343.633 537.592L346.772 524.605H346.781Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M351.769 523.207H350.322C349.698 523.207 349.191 523.713 349.191 524.337V537.332C349.191 537.956 349.698 538.462 350.322 538.462H351.769C352.393 538.462 352.9 537.956 352.9 537.332V524.337C352.9 523.713 352.393 523.207 351.769 523.207Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M357.091 538.46H364.727C365.349 538.46 365.858 537.952 365.858 537.33V536.069C365.858 535.448 365.349 534.94 364.727 534.94H360.799C360.177 534.94 359.668 534.432 359.668 533.81V524.352C359.668 523.731 359.16 523.223 358.537 523.223H357.1C356.477 523.223 355.969 523.731 355.969 524.352V537.339C355.969 537.961 356.477 538.469 357.1 538.469L357.091 538.46Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M370.502 523.207H369.065C368.442 523.207 367.934 523.715 367.934 524.337V537.324C367.934 537.945 368.442 538.453 369.065 538.453H376.7C377.323 538.453 377.831 537.945 377.831 537.324V536.063C377.831 535.441 377.323 534.933 376.7 534.933H372.773C372.15 534.933 371.642 534.425 371.642 533.803V524.345C371.642 523.724 371.133 523.216 370.511 523.216L370.502 523.207Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M396.13 538.461H398.559C399.181 538.461 399.69 537.953 399.69 537.331V524.345C399.69 523.723 399.181 523.215 398.559 523.215H397.121C396.499 523.215 395.99 523.723 395.99 524.345V529.643L396.271 532.821L390.888 523.767C390.686 523.425 390.309 523.215 389.915 523.215H387.486C386.864 523.215 386.355 523.723 386.355 524.345V537.331C386.355 537.953 386.864 538.461 387.486 538.461H388.924C389.547 538.461 390.055 537.953 390.055 537.331V532.025L389.748 528.854L395.157 537.909C395.359 538.251 395.736 538.461 396.13 538.461Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M409.963 538.741C414.478 538.741 417.95 535.317 417.95 530.833C417.95 526.35 414.478 522.926 409.963 522.926C405.448 522.926 401.977 526.35 401.977 530.833C401.977 535.317 405.448 538.741 409.963 538.741ZM409.963 526.455C412.409 526.455 414.198 528.285 414.198 530.833C414.198 533.382 412.409 535.212 409.963 535.212C407.517 535.212 405.729 533.382 405.729 530.833C405.729 528.285 407.517 526.455 409.963 526.455Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M430.259 523.207H420.51C419.887 523.207 419.379 523.715 419.379 524.337V525.598C419.379 526.219 419.887 526.727 420.51 526.727H422.386C423.008 526.727 423.517 527.235 423.517 527.857V537.315C423.517 537.937 424.025 538.444 424.648 538.444H426.086C426.708 538.444 427.216 537.937 427.216 537.315V527.857C427.216 527.235 427.725 526.727 428.347 526.727H430.25C430.872 526.727 431.381 526.219 431.381 525.598V524.337C431.381 523.715 430.872 523.207 430.25 523.207H430.259Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M334.385 546.568C335.788 546.568 336.91 547.172 337.576 548.206C337.778 548.521 338.137 548.705 338.514 548.705H340.189C341.022 548.705 341.592 547.82 341.215 547.076C339.978 544.65 337.489 543.039 334.385 543.039C329.8 543.039 326.469 546.463 326.469 550.947C326.469 555.43 329.809 558.854 334.385 558.854C337.489 558.854 339.978 557.243 341.215 554.817C341.592 554.073 341.022 553.189 340.189 553.189H338.514C338.137 553.189 337.787 553.373 337.576 553.688C336.901 554.721 335.779 555.325 334.385 555.325C331.852 555.325 330.221 553.495 330.221 550.947C330.221 548.398 331.86 546.568 334.385 546.568Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M351.436 543.031C346.921 543.031 343.449 546.455 343.449 550.939C343.449 555.423 346.921 558.847 351.436 558.847C355.951 558.847 359.422 555.423 359.422 550.939C359.422 546.455 355.951 543.031 351.436 543.031ZM351.436 555.326C348.99 555.326 347.201 553.496 347.201 550.948C347.201 548.399 348.99 546.569 351.436 546.569C353.882 546.569 355.67 548.399 355.67 550.948C355.67 553.496 353.882 555.326 351.436 555.326Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M367.383 548.96C365.875 548.636 365.182 548.548 365.182 547.83C365.182 547.173 365.682 546.499 367.339 546.499C368.671 546.499 369.285 546.911 369.592 547.497C369.785 547.865 370.171 548.084 370.583 548.084H372.161C372.958 548.084 373.484 547.296 373.221 546.552C372.529 544.581 370.793 543.031 367.339 543.031C364.437 543.031 361.448 544.643 361.448 547.997C361.448 551.158 363.999 552.069 366.489 552.594C369.04 553.137 369.96 553.207 369.96 554.1C369.96 554.906 369.285 555.388 367.777 555.388C366.269 555.388 365.48 555.011 365.068 554.214C364.858 553.811 364.481 553.54 364.034 553.54H362.491C361.684 553.54 361.15 554.345 361.439 555.099C362.202 557.121 364.078 558.855 367.804 558.855C370.249 558.855 373.695 557.682 373.695 553.951C373.695 550.834 371.67 549.879 367.392 548.96H367.383Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M385.88 543.32H376.131C375.508 543.32 375 543.828 375 544.45V545.711C375 546.333 375.508 546.841 376.131 546.841H378.007C378.629 546.841 379.138 547.349 379.138 547.97V557.428C379.138 558.05 379.646 558.558 380.269 558.558H381.707C382.329 558.558 382.838 558.05 382.838 557.428V547.97C382.838 547.349 383.346 546.841 383.968 546.841H385.871C386.493 546.841 387.002 546.333 387.002 545.711V544.45C387.002 543.828 386.493 543.32 385.871 543.32H385.88Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M409.796 543.32H407.324C406.947 543.32 406.587 543.513 406.377 543.828L404.168 547.138C403.72 547.813 402.73 547.813 402.283 547.138L400.073 543.828C399.863 543.513 399.512 543.32 399.127 543.32H396.654C396.032 543.32 395.523 543.828 395.523 544.45V557.437C395.523 558.059 396.032 558.566 396.654 558.566H398.092C398.715 558.566 399.223 558.059 399.223 557.437V552.296C399.223 551.167 400.687 550.738 401.31 551.675L403.045 554.319H403.396L405.132 551.675C405.746 550.729 407.218 551.167 407.218 552.296V557.437C407.218 558.059 407.727 558.566 408.349 558.566H409.787C410.409 558.566 410.918 558.059 410.918 557.437V544.45C410.918 543.828 410.409 543.32 409.787 543.32H409.796Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M421.307 543.031C416.792 543.031 413.32 546.455 413.32 550.939C413.32 555.423 416.792 558.847 421.307 558.847C425.822 558.847 429.293 555.423 429.293 550.939C429.293 546.455 425.822 543.031 421.307 543.031ZM421.307 555.326C418.861 555.326 417.073 553.496 417.073 550.948C417.073 548.399 418.861 546.569 421.307 546.569C423.753 546.569 425.541 548.399 425.541 550.948C425.541 553.496 423.753 555.326 421.307 555.326Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M441.461 554.029C441.084 553.495 441.241 552.768 441.794 552.418C443.012 551.647 443.74 550.334 443.74 548.425C443.74 545.246 441.732 543.328 438.506 543.328H432.834C432.212 543.328 431.703 543.836 431.703 544.458V557.445C431.703 558.066 432.212 558.574 432.834 558.574H434.272C434.894 558.574 435.403 558.066 435.403 557.445V553.285H435.955C436.332 553.285 436.683 553.469 436.893 553.784L439.821 558.084C440.032 558.39 440.382 558.583 440.759 558.583H442.495C443.416 558.583 443.95 557.541 443.416 556.797L441.452 554.038L441.461 554.029ZM438.085 550.027H436.534C435.911 550.027 435.403 549.519 435.403 548.898V547.987C435.403 547.365 435.911 546.857 436.534 546.857H438.085C439.33 546.857 439.979 547.4 439.979 548.469C439.979 549.537 439.365 550.036 438.085 550.036V550.027Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M456.146 543.32H447.897C447.274 543.32 446.766 543.828 446.766 544.45V557.437C446.766 558.059 447.274 558.566 447.897 558.566H456.146C456.769 558.566 457.277 558.059 457.277 557.437V556.176C457.277 555.554 456.769 555.046 456.146 555.046H451.605C450.982 555.046 450.474 554.538 450.474 553.916V553.785C450.474 553.163 450.982 552.655 451.605 552.655H455.532C456.155 552.655 456.663 552.147 456.663 551.526V550.265C456.663 549.643 456.155 549.135 455.532 549.135H451.605C450.982 549.135 450.474 548.627 450.474 548.005C450.474 547.384 450.982 546.876 451.605 546.876H456.146C456.769 546.876 457.277 546.368 457.277 545.746V544.485C457.277 543.863 456.769 543.355 456.146 543.355V543.32Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M154.637 210.172C161.528 210.172 171.25 206.853 171.25 196.345C171.25 187.561 165.526 184.855 153.471 182.272C149.228 181.353 147.256 181.107 147.256 179.076C147.256 177.228 148.667 175.328 153.348 175.328C158.03 175.328 159.468 177.018 160.151 179.277C160.248 179.601 160.564 179.811 160.897 179.811H169.979C170.453 179.811 170.838 179.391 170.768 178.918C169.76 171.834 165.035 165.555 153.34 165.555C145.16 165.555 136.727 170.1 136.727 179.566C136.727 188.472 143.924 191.055 150.938 192.535C158.135 194.068 160.721 194.252 160.721 196.774C160.721 199.051 158.81 200.399 154.567 200.399C149.386 200.399 147.405 198.919 146.37 195.741C146.265 195.408 145.967 195.18 145.616 195.18H136.534C136.052 195.18 135.666 195.609 135.745 196.082C136.902 203.105 142.004 210.172 154.628 210.172H154.637Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M174.836 209.366H184.217C184.55 209.366 184.857 209.156 184.962 208.831L187.671 200.985C187.785 200.67 188.083 200.451 188.416 200.451H203.407C203.74 200.451 204.047 200.661 204.153 200.985L206.861 208.831C206.975 209.147 207.274 209.366 207.607 209.366H216.987C217.539 209.366 217.916 208.823 217.732 208.306L202.68 166.876C202.566 166.561 202.268 166.359 201.935 166.359H189.88C189.547 166.359 189.249 166.57 189.135 166.876L174.082 208.306C173.898 208.823 174.275 209.366 174.828 209.366H174.836ZM191.669 189.461L195.175 179.285C195.421 178.576 196.429 178.576 196.666 179.285L200.172 189.461C200.348 189.977 199.971 190.503 199.427 190.503H192.414C191.87 190.503 191.493 189.969 191.669 189.461Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M228.964 208.814C229.069 209.147 229.376 209.366 229.718 209.366H241.72C242.062 209.366 242.369 209.147 242.474 208.814L255.572 167.384C255.729 166.876 255.352 166.359 254.818 166.359H245.411C245.06 166.359 244.753 166.587 244.657 166.92L236.539 194.172C236.311 194.925 235.25 194.925 235.022 194.172L226.904 166.92C226.808 166.587 226.492 166.359 226.15 166.359H216.743C216.209 166.359 215.832 166.876 215.989 167.384L228.964 208.814Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M262.531 209.368H290.603C291.041 209.368 291.392 209.017 291.392 208.58V200.208C291.392 199.77 291.041 199.42 290.603 199.42H272.99C272.552 199.42 272.201 199.069 272.201 198.631V193.447C272.201 193.009 272.552 192.659 272.99 192.659H288.884C289.323 192.659 289.673 192.309 289.673 191.871V183.499C289.673 183.061 289.323 182.711 288.884 182.711H272.99C272.552 182.711 272.201 182.361 272.201 181.923V177.115C272.201 176.677 272.552 176.327 272.99 176.327H290.603C291.041 176.327 291.392 175.977 291.392 175.539V167.167C291.392 166.729 291.041 166.379 290.603 166.379H262.531C262.093 166.379 261.742 166.729 261.742 167.167V208.597C261.742 209.035 262.093 209.385 262.531 209.385V209.368Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M314.336 176.307H324.444C324.882 176.307 325.233 176.658 325.233 177.096V208.578C325.233 209.015 325.584 209.366 326.022 209.366H334.903C335.341 209.366 335.692 209.015 335.692 208.578V177.096C335.692 176.658 336.043 176.307 336.481 176.307H346.65C347.089 176.307 347.439 175.957 347.439 175.519V167.148C347.439 166.71 347.089 166.359 346.65 166.359H314.336C313.898 166.359 313.547 166.71 313.547 167.148V175.519C313.547 175.957 313.898 176.307 314.336 176.307Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M362.947 166.359H354.066C353.631 166.359 353.277 166.712 353.277 167.148V208.586C353.277 209.022 353.631 209.374 354.066 209.374H362.947C363.383 209.374 363.736 209.022 363.736 208.586V167.148C363.736 166.712 363.383 166.359 362.947 166.359Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M372.836 209.366H381.717C382.155 209.366 382.506 209.015 382.506 208.578V183.62C382.506 182.832 383.523 182.534 383.961 183.191L393.271 197.386H394.253L403.564 183.191C403.993 182.534 405.019 182.84 405.019 183.62V208.578C405.019 209.015 405.37 209.366 405.808 209.366H414.689C415.127 209.366 415.478 209.015 415.478 208.578V167.148C415.478 166.71 415.127 166.359 414.689 166.359H404.028C403.765 166.359 403.52 166.491 403.371 166.71L394.42 180.117C394.104 180.59 393.42 180.59 393.105 180.117L384.154 166.71C384.005 166.491 383.759 166.359 383.496 166.359H372.836C372.398 166.359 372.047 166.71 372.047 167.148V208.578C372.047 209.015 372.398 209.366 372.836 209.366Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M424.875 209.368H452.946C453.385 209.368 453.735 209.017 453.735 208.58V200.208C453.735 199.77 453.385 199.42 452.946 199.42H435.334C434.895 199.42 434.545 199.069 434.545 198.631V193.447C434.545 193.009 434.895 192.659 435.334 192.659H451.219C451.658 192.659 452.008 192.309 452.008 191.871V183.499C452.008 183.061 451.658 182.711 451.219 182.711H435.334C434.895 182.711 434.545 182.361 434.545 181.923V177.115C434.545 176.677 434.895 176.327 435.334 176.327H452.946C453.385 176.327 453.735 175.977 453.735 175.539V167.167C453.735 166.729 453.385 166.379 452.946 166.379H424.875C424.437 166.379 424.086 166.729 424.086 167.167V208.597C424.086 209.035 424.437 209.385 424.875 209.385V209.368Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M1.41133 275.414H24.5557C24.9415 275.414 25.2483 275.108 25.2483 274.722V267.366C25.2483 266.981 24.9415 266.674 24.5557 266.674H10.599C10.2132 266.674 9.90638 266.368 9.90638 265.983V238.336C9.90638 237.951 9.59954 237.645 9.2138 237.645H1.41133C1.02559 237.645 0.71875 237.951 0.71875 238.336V274.731C0.71875 275.116 1.02559 275.423 1.41133 275.423V275.414Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M31.0793 275.414H55.7316C56.1173 275.414 56.4242 275.108 56.4242 274.722V267.366C56.4242 266.981 56.1173 266.674 55.7316 266.674H40.2582C39.8724 266.674 39.5656 266.368 39.5656 265.983V261.429C39.5656 261.044 39.8724 260.737 40.2582 260.737H54.2149C54.6007 260.737 54.9075 260.431 54.9075 260.045V252.689C54.9075 252.304 54.6007 251.997 54.2149 251.997H40.2582C39.8724 251.997 39.5656 251.691 39.5656 251.306V247.076C39.5656 246.691 39.8724 246.384 40.2582 246.384H55.7316C56.1173 246.384 56.4242 246.078 56.4242 245.692V238.336C56.4242 237.951 56.1173 237.645 55.7316 237.645H31.0793C30.6936 237.645 30.3867 237.951 30.3867 238.336V274.731C30.3867 275.116 30.6936 275.423 31.0793 275.423V275.414Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M76.9036 276.122C82.9528 276.122 91.4916 273.206 91.4916 263.976C91.4916 256.261 86.4683 253.879 75.8779 251.619C72.152 250.814 70.425 250.595 70.425 248.817C70.425 247.197 71.6698 245.524 75.7727 245.524C79.8756 245.524 81.1468 247.013 81.7517 248.992C81.8394 249.272 82.1111 249.456 82.4092 249.456H90.387C90.8078 249.456 91.141 249.088 91.0796 248.677C90.1941 242.459 86.0474 236.934 75.7727 236.934C68.5839 236.934 61.1847 240.927 61.1847 249.237C61.1847 257.066 67.5056 259.326 73.6687 260.622C79.9896 261.97 82.2602 262.137 82.2602 264.343C82.2602 266.34 80.5857 267.531 76.8598 267.531C72.3098 267.531 70.5652 266.226 69.6622 263.441C69.5658 263.152 69.3028 262.951 68.996 262.951H61.0182C60.5973 262.951 60.2554 263.328 60.3256 263.748C61.3425 269.922 65.8224 276.122 76.9124 276.122H76.9036Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M112.513 276.122C118.562 276.122 127.101 273.206 127.101 263.976C127.101 256.261 122.078 253.879 111.487 251.619C107.761 250.814 106.034 250.595 106.034 248.817C106.034 247.197 107.279 245.524 111.382 245.524C115.485 245.524 116.756 247.013 117.361 248.992C117.449 249.272 117.721 249.456 118.019 249.456H125.996C126.417 249.456 126.75 249.088 126.689 248.677C125.804 242.459 121.657 236.934 111.382 236.934C104.193 236.934 96.7941 240.927 96.7941 249.237C96.7941 257.066 103.115 259.326 109.278 260.622C115.599 261.97 117.87 262.137 117.87 264.343C117.87 266.34 116.195 267.531 112.469 267.531C107.919 267.531 106.175 266.226 105.272 263.441C105.175 263.152 104.912 262.951 104.605 262.951H96.6275C96.2067 262.951 95.8648 263.328 95.935 263.748C96.9519 269.922 101.432 276.122 112.522 276.122H112.513Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M163.79 276.122C169.839 276.122 178.378 273.206 178.378 263.976C178.378 256.261 173.355 253.879 162.765 251.619C159.039 250.814 157.312 250.595 157.312 248.817C157.312 247.197 158.557 245.524 162.659 245.524C166.762 245.524 168.034 247.013 168.638 248.992C168.726 249.272 168.998 249.456 169.296 249.456H177.274C177.695 249.456 178.028 249.088 177.966 248.677C177.081 242.459 172.934 236.934 162.659 236.934C155.471 236.934 148.071 240.927 148.071 249.237C148.071 257.066 154.392 259.326 160.555 260.622C166.876 261.97 169.147 262.137 169.147 264.343C169.147 266.34 167.472 267.531 163.747 267.531C159.197 267.531 157.452 266.226 156.549 263.441C156.453 263.152 156.19 262.951 155.883 262.951H147.905C147.484 262.951 147.142 263.328 147.212 263.748C148.229 269.922 152.709 276.122 163.799 276.122H163.79Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M201.067 274.722V247.076C201.067 246.691 201.374 246.384 201.759 246.384H210.693C211.079 246.384 211.385 246.078 211.385 245.692V238.336C211.385 237.951 211.079 237.645 210.693 237.645H182.306C181.92 237.645 181.613 237.951 181.613 238.336V245.692C181.613 246.078 181.92 246.384 182.306 246.384H191.187C191.572 246.384 191.879 246.691 191.879 247.076V274.722C191.879 275.108 192.186 275.414 192.572 275.414H200.366C200.751 275.414 201.058 275.108 201.058 274.722H201.067Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M237.282 275.116C237.414 275.309 237.624 275.423 237.861 275.423H247.39C247.952 275.423 248.285 274.784 247.96 274.328L239.325 262.182C239.071 261.823 239.211 261.306 239.614 261.149C243.779 259.494 246.356 255.956 246.356 250.272C246.356 242.391 241.385 237.645 233.39 237.645H217.224C216.838 237.645 216.531 237.951 216.531 238.336V274.731C216.531 275.116 216.838 275.423 217.224 275.423H225.018C225.403 275.423 225.71 275.116 225.71 274.731V263.005C225.71 262.62 226.017 262.313 226.403 262.313H228.209C228.437 262.313 228.656 262.427 228.787 262.62L237.291 275.125L237.282 275.116ZM226.403 254.266C226.017 254.266 225.71 253.959 225.71 253.574V247.085C225.71 246.699 226.017 246.393 226.403 246.393H232.355C235.433 246.393 237.054 247.742 237.054 250.386C237.054 253.031 235.538 254.274 232.355 254.274H226.403V254.266Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M254.552 275.414H279.204C279.59 275.414 279.897 275.108 279.897 274.722V267.366C279.897 266.981 279.59 266.674 279.204 266.674H263.731C263.345 266.674 263.038 266.368 263.038 265.983V261.429C263.038 261.044 263.345 260.737 263.731 260.737H277.688C278.073 260.737 278.38 260.431 278.38 260.045V252.689C278.38 252.304 278.073 251.997 277.688 251.997H263.731C263.345 251.997 263.038 251.691 263.038 251.306V247.076C263.038 246.691 263.345 246.384 263.731 246.384H279.204C279.59 246.384 279.897 246.078 279.897 245.692V238.336C279.897 237.951 279.59 237.645 279.204 237.645H254.552C254.166 237.645 253.859 237.951 253.859 238.336V274.731C253.859 275.116 254.166 275.423 254.552 275.423V275.414Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M300.332 267.539C295.782 267.539 294.038 266.234 293.135 263.449C293.038 263.16 292.775 262.959 292.469 262.959H284.491C284.07 262.959 283.728 263.335 283.798 263.756C284.815 269.93 289.295 276.13 300.385 276.13C306.434 276.13 314.973 273.213 314.973 263.983C314.973 256.268 309.95 253.886 299.359 251.627C295.633 250.821 293.906 250.603 293.906 248.825C293.906 247.205 295.151 245.532 299.254 245.532C303.357 245.532 304.628 247.021 305.233 249C305.321 249.28 305.593 249.464 305.891 249.464H313.868C314.289 249.464 314.622 249.096 314.561 248.685C313.676 242.467 309.529 236.941 299.254 236.941C292.065 236.941 284.666 240.935 284.666 249.245C284.666 257.074 290.987 259.333 297.15 260.629C303.471 261.978 305.742 262.144 305.742 264.351C305.742 266.348 304.067 267.539 300.341 267.539H300.332Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M320.271 249.237C320.271 257.066 326.592 259.326 332.755 260.622C339.075 261.97 341.346 262.137 341.346 264.343C341.346 266.34 339.672 267.531 335.946 267.531C331.396 267.531 329.651 266.226 328.748 263.441C328.652 263.152 328.389 262.951 328.082 262.951H320.104C319.683 262.951 319.341 263.328 319.412 263.748C320.428 269.922 324.908 276.122 335.998 276.122C342.047 276.122 350.586 273.206 350.586 263.976C350.586 256.261 345.563 253.879 334.973 251.619C331.247 250.814 329.52 250.595 329.52 248.817C329.52 247.197 330.765 245.524 334.867 245.524C338.97 245.524 340.241 247.013 340.846 248.992C340.934 249.272 341.206 249.456 341.504 249.456H349.482C349.903 249.456 350.236 249.088 350.174 248.677C349.289 242.459 345.142 236.934 334.867 236.934C327.679 236.934 320.279 240.927 320.279 249.237H320.271Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M36.593 306.141C36.216 306.141 35.918 306.447 35.918 306.815V342.246C35.918 342.623 36.2248 342.921 36.593 342.921H52.5399C59.904 342.921 65.1115 339.085 65.1115 332.149C65.1115 328.445 63.6825 325.161 60.6842 323.524C60.2284 323.27 60.1933 322.648 60.6229 322.359C62.7182 320.914 63.8491 318.891 63.8491 315.817C63.8491 309.302 58.9572 306.149 52.5399 306.149H36.593V306.141ZM56.0554 331.361C56.0554 333.253 55.3189 334.409 52.3207 334.409H45.5264C45.1494 334.409 44.8514 334.102 44.8514 333.734V328.988C44.8514 328.611 45.1582 328.314 45.5264 328.314H52.3207C55.3189 328.314 56.0554 329.312 56.0554 331.361ZM54.8017 317.385C54.8017 319.329 53.6971 320.17 51.6983 320.17H45.5352C45.1582 320.17 44.8601 319.863 44.8601 319.495V315.327C44.8601 314.95 45.167 314.653 45.5352 314.653H51.33C53.4867 314.653 54.8017 315.125 54.8017 317.385Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M70.6328 306.815V342.246C70.6328 342.623 70.9396 342.921 71.3079 342.921H95.3114C95.6884 342.921 95.9865 342.614 95.9865 342.246V335.083C95.9865 334.706 95.6796 334.409 95.3114 334.409H80.25C79.873 334.409 79.575 334.102 79.575 333.734V329.303C79.575 328.927 79.8818 328.629 80.25 328.629H93.8386C94.2156 328.629 94.5136 328.322 94.5136 327.955V320.791C94.5136 320.415 94.2068 320.117 93.8386 320.117H80.25C79.873 320.117 79.575 319.811 79.575 319.443V315.327C79.575 314.95 79.8818 314.653 80.25 314.653H95.3114C95.6884 314.653 95.9865 314.346 95.9865 313.978V306.815C95.9865 306.438 95.6796 306.141 95.3114 306.141H71.3079C70.9309 306.141 70.6328 306.447 70.6328 306.815Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M99.8118 314.653H108.456C108.833 314.653 109.131 314.959 109.131 315.327V342.246C109.131 342.623 109.438 342.921 109.806 342.921H117.398C117.775 342.921 118.073 342.614 118.073 342.246V315.327C118.073 314.95 118.38 314.653 118.748 314.653H127.445C127.822 314.653 128.12 314.346 128.12 313.978V306.815C128.12 306.438 127.813 306.141 127.445 306.141H99.8118C99.4348 306.141 99.1367 306.447 99.1367 306.815V313.978C99.1367 314.355 99.4436 314.653 99.8118 314.653Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M150.737 342.238V315.319C150.737 314.943 151.044 314.645 151.412 314.645H160.109C160.486 314.645 160.784 314.338 160.784 313.97V306.807C160.784 306.431 160.477 306.133 160.109 306.133H132.476C132.099 306.133 131.801 306.439 131.801 306.807V313.97C131.801 314.347 132.108 314.645 132.476 314.645H141.12C141.497 314.645 141.795 314.951 141.795 315.319V342.238C141.795 342.615 142.102 342.913 142.47 342.913H150.062C150.439 342.913 150.737 342.606 150.737 342.238Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M175.398 314.653H190.46C190.837 314.653 191.135 314.346 191.135 313.978V306.815C191.135 306.438 190.828 306.141 190.46 306.141H166.456C166.079 306.141 165.781 306.447 165.781 306.815V342.246C165.781 342.623 166.088 342.921 166.456 342.921H190.46C190.837 342.921 191.135 342.614 191.135 342.246V335.083C191.135 334.706 190.828 334.409 190.46 334.409H175.398C175.021 334.409 174.723 334.102 174.723 333.734V329.303C174.723 328.927 175.03 328.629 175.398 328.629H188.987C189.364 328.629 189.662 328.322 189.662 327.955V320.791C189.662 320.415 189.355 320.117 188.987 320.117H175.398C175.021 320.117 174.723 319.811 174.723 319.443V315.327C174.723 314.95 175.03 314.653 175.398 314.653Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M217.951 342.913H227.227C227.779 342.913 228.094 342.291 227.779 341.844L219.371 330.022C219.126 329.672 219.258 329.173 219.661 329.015C223.72 327.404 226.227 323.954 226.227 318.428C226.227 310.757 221.388 306.133 213.603 306.133H197.866C197.489 306.133 197.191 306.439 197.191 306.807V342.238C197.191 342.615 197.498 342.913 197.866 342.913H205.459C205.836 342.913 206.134 342.606 206.134 342.238V330.819C206.134 330.443 206.44 330.145 206.809 330.145H208.562C208.79 330.145 208.992 330.259 209.123 330.443L217.399 342.615C217.522 342.799 217.732 342.913 217.96 342.913H217.951ZM212.595 322.325H206.8C206.423 322.325 206.125 322.018 206.125 321.65V315.328C206.125 314.951 206.432 314.654 206.8 314.654H212.595C215.593 314.654 217.171 315.967 217.171 318.542C217.171 321.116 215.698 322.325 212.595 322.325Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M271.782 329.023C275.841 327.412 278.348 323.961 278.348 318.436C278.348 310.764 273.509 306.141 265.724 306.141H249.988C249.611 306.141 249.312 306.447 249.312 306.815V342.246C249.312 342.623 249.619 342.921 249.988 342.921H257.58C257.957 342.921 258.255 342.614 258.255 342.246V330.827C258.255 330.45 258.562 330.153 258.93 330.153H260.683C260.911 330.153 261.113 330.267 261.244 330.45L269.52 342.623C269.643 342.807 269.853 342.921 270.081 342.921H279.356C279.909 342.921 280.224 342.299 279.909 341.852L271.501 330.03C271.256 329.68 271.387 329.181 271.791 329.023H271.782ZM264.725 322.324H258.93C258.553 322.324 258.255 322.017 258.255 321.65V315.327C258.255 314.95 258.562 314.653 258.93 314.653H264.725C267.723 314.653 269.301 315.966 269.301 318.541C269.301 321.115 267.828 322.324 264.725 322.324Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M285.656 306.815V342.246C285.656 342.623 285.963 342.921 286.331 342.921H310.335C310.712 342.921 311.01 342.614 311.01 342.246V335.083C311.01 334.706 310.703 334.409 310.335 334.409H295.273C294.896 334.409 294.598 334.102 294.598 333.734V329.303C294.598 328.927 294.905 328.629 295.273 328.629H308.862C309.239 328.629 309.537 328.322 309.537 327.955V320.791C309.537 320.415 309.23 320.117 308.862 320.117H295.273C294.896 320.117 294.598 319.811 294.598 319.443V315.327C294.598 314.95 294.905 314.653 295.273 314.653H310.335C310.712 314.653 311.01 314.346 311.01 313.978V306.815C311.01 306.438 310.703 306.141 310.335 306.141H286.331C285.954 306.141 285.656 306.447 285.656 306.815Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M330.896 335.241C326.469 335.241 324.768 333.971 323.892 331.257C323.804 330.976 323.541 330.775 323.243 330.775H315.476C315.063 330.775 314.739 331.143 314.8 331.546C315.791 337.553 320.148 343.595 330.949 343.595C336.84 343.595 345.151 340.758 345.151 331.773C345.151 324.26 340.259 321.948 329.95 319.741C326.32 318.953 324.637 318.743 324.637 317.009C324.637 315.432 325.847 313.804 329.844 313.804C333.842 313.804 335.069 315.248 335.666 317.184C335.753 317.455 336.016 317.639 336.305 317.639H344.073C344.476 317.639 344.809 317.28 344.748 316.877C343.88 310.826 339.847 305.449 329.853 305.449C322.857 305.449 315.651 309.337 315.651 317.429C315.651 325.048 321.805 327.255 327.802 328.516C333.956 329.829 336.165 329.987 336.165 332.141C336.165 334.085 334.535 335.241 330.905 335.241H330.896Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M350.672 306.815L350.724 327.893C350.724 338.349 357.352 343.604 366.189 343.604C375.026 343.604 381.654 338.349 381.654 327.893L381.706 306.815C381.706 306.438 381.408 306.141 381.031 306.141H373.282C372.905 306.141 372.606 306.447 372.606 306.815V327.368C372.606 333.148 370.029 335.092 366.189 335.092C362.349 335.092 359.772 333.148 359.772 327.368V306.815C359.772 306.438 359.465 306.141 359.097 306.141H351.347C350.97 306.141 350.672 306.447 350.672 306.815Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M412.689 342.238V335.075C412.689 334.699 412.383 334.401 412.014 334.401H398.426C398.049 334.401 397.751 334.094 397.751 333.727V306.807C397.751 306.431 397.444 306.133 397.076 306.133H389.484C389.107 306.133 388.809 306.439 388.809 306.807V342.238C388.809 342.615 389.115 342.913 389.484 342.913H412.014C412.391 342.913 412.689 342.606 412.689 342.238Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M409.008 306.815V313.978C409.008 314.355 409.315 314.653 409.683 314.653H418.327C418.704 314.653 419.002 314.959 419.002 315.327V342.246C419.002 342.623 419.309 342.921 419.677 342.921H427.269C427.646 342.921 427.944 342.614 427.944 342.246V315.327C427.944 314.95 428.251 314.653 428.619 314.653H437.316C437.693 314.653 437.991 314.346 437.991 313.978V306.815C437.991 306.438 437.684 306.141 437.316 306.141H409.683C409.306 306.141 409.008 306.447 409.008 306.815Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M456.402 319.749C452.772 318.961 451.089 318.75 451.089 317.016C451.089 315.44 452.299 313.811 456.296 313.811C460.294 313.811 461.522 315.256 462.118 317.192C462.205 317.463 462.468 317.647 462.758 317.647H470.525C470.928 317.647 471.261 317.288 471.2 316.885C470.332 310.834 466.299 305.457 456.305 305.457C449.309 305.457 442.103 309.345 442.103 317.437C442.103 325.055 448.257 327.262 454.254 328.523C460.408 329.837 462.617 329.995 462.617 332.149C462.617 334.093 460.987 335.249 457.357 335.249C452.93 335.249 451.229 333.979 450.353 331.264C450.265 330.984 450.002 330.783 449.704 330.783H441.936C441.524 330.783 441.2 331.15 441.261 331.553C442.252 337.561 446.609 343.603 457.401 343.603C463.292 343.603 471.603 340.766 471.603 331.781C471.603 324.267 466.711 321.955 456.402 319.749Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M202.541 357.897H209.396C209.773 357.897 210.071 357.59 210.071 357.222V354.315C210.071 353.938 209.765 353.641 209.396 353.641H198.07C197.693 353.641 197.395 353.947 197.395 354.315V371.356C197.395 371.733 197.701 372.031 198.07 372.031H201.191C201.568 372.031 201.866 371.724 201.866 371.356V365.743C201.866 365.366 202.172 365.069 202.541 365.069H208.66C209.037 365.069 209.335 364.762 209.335 364.394V361.487C209.335 361.11 209.028 360.813 208.66 360.813H202.541C202.164 360.813 201.866 360.506 201.866 360.138V358.571C201.866 358.194 202.172 357.897 202.541 357.897Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M213.506 372.031H216.627C217.004 372.031 217.302 371.724 217.302 371.356V366.321C217.302 365.944 217.609 365.647 217.977 365.647H218.337C218.565 365.647 218.766 365.761 218.898 365.944L222.834 371.733C222.957 371.917 223.167 372.031 223.395 372.031H227.2C227.752 372.031 228.068 371.409 227.752 370.962L224.167 365.918C223.921 365.577 224.053 365.104 224.43 364.929C226.253 364.07 227.358 362.389 227.358 359.788C227.358 355.953 224.938 353.641 221.046 353.641H213.515C213.138 353.641 212.84 353.947 212.84 354.315V371.356C212.84 371.733 213.147 372.031 213.515 372.031H213.506ZM217.302 358.571C217.302 358.194 217.609 357.897 217.977 357.897H220.537C222.036 357.897 222.825 358.553 222.825 359.841C222.825 361.128 222.089 361.732 220.537 361.732H217.977C217.6 361.732 217.302 361.426 217.302 361.058V358.571Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M239.052 372.374C244.496 372.374 248.678 368.249 248.678 362.837C248.678 357.425 244.496 353.301 239.052 353.301C233.608 353.301 229.426 357.425 229.426 362.837C229.426 368.249 233.608 372.374 239.052 372.374ZM239.052 357.557C241.997 357.557 244.154 359.764 244.154 362.837C244.154 365.911 241.997 368.118 239.052 368.118C236.106 368.118 233.949 365.911 233.949 362.837C233.949 359.764 236.106 357.557 239.052 357.557Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M260.936 366.908L264.294 361.785C264.662 361.224 265.538 361.487 265.538 362.153V371.356C265.538 371.733 265.845 372.031 266.214 372.031H269.335C269.711 372.031 270.01 371.724 270.01 371.356V354.315C270.01 353.938 269.703 353.641 269.335 353.641H265.293C265.065 353.641 264.855 353.754 264.732 353.938L261.287 359.096C261.015 359.499 260.427 359.499 260.164 359.096L256.719 353.938C256.596 353.754 256.386 353.641 256.158 353.641H252.116C251.739 353.641 251.441 353.947 251.441 354.315V371.356C251.441 371.733 251.748 372.031 252.116 372.031H255.237C255.614 372.031 255.912 371.724 255.912 371.356V362.153C255.912 361.478 256.789 361.224 257.157 361.785L260.515 366.908H260.936Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M290.472 357.557C292.269 357.557 293.681 358.38 294.47 359.764C294.592 359.982 294.811 360.131 295.066 360.131H298.529C299.002 360.131 299.326 359.658 299.169 359.221C297.906 355.718 294.645 353.301 290.481 353.301C284.958 353.301 280.934 357.425 280.934 362.837C280.934 368.249 284.958 372.374 290.481 372.374C294.654 372.374 297.906 369.957 299.169 366.454C299.326 366.016 299.002 365.543 298.529 365.543H295.066C294.811 365.543 294.592 365.692 294.47 365.911C293.681 367.295 292.269 368.118 290.472 368.118C287.421 368.118 285.448 365.911 285.448 362.837C285.448 359.764 287.421 357.557 290.472 357.557Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M306.741 354.087L300.552 371.129C300.394 371.566 300.719 372.031 301.183 372.031H304.471C304.76 372.031 305.014 371.847 305.111 371.575L306.11 368.677C306.207 368.405 306.461 368.221 306.75 368.221H312.677C312.966 368.221 313.22 368.405 313.317 368.677L314.316 371.575C314.412 371.847 314.667 372.031 314.956 372.031H318.244C318.708 372.031 319.041 371.566 318.875 371.129L312.685 354.087C312.589 353.825 312.335 353.641 312.054 353.641H307.373C307.092 353.641 306.838 353.816 306.741 354.087ZM308.057 363.072L309.082 360.095C309.293 359.49 310.152 359.49 310.362 360.095L311.388 363.072C311.537 363.51 311.213 363.965 310.748 363.965H308.696C308.232 363.965 307.907 363.51 308.057 363.072Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M322.261 372.031H325.382C325.759 372.031 326.057 371.724 326.057 371.356V362.153C326.057 361.478 326.934 361.224 327.302 361.785L330.66 366.908H331.08L334.438 361.785C334.806 361.224 335.683 361.487 335.683 362.153V371.356C335.683 371.733 335.99 372.031 336.358 372.031H339.479C339.856 372.031 340.154 371.724 340.154 371.356V354.315C340.154 353.938 339.847 353.641 339.479 353.641H335.438C335.21 353.641 334.999 353.754 334.876 353.938L331.431 359.096C331.159 359.499 330.572 359.499 330.309 359.096L326.864 353.938C326.741 353.754 326.53 353.641 326.302 353.641H322.261C321.884 353.641 321.586 353.947 321.586 354.315V371.356C321.586 371.733 321.893 372.031 322.261 372.031Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M358.354 359.946C358.354 355.874 355.619 353.641 351.726 353.641H344.511C344.134 353.641 343.836 353.947 343.836 354.315V371.356C343.836 371.733 344.143 372.031 344.511 372.031H347.632C348.009 372.031 348.307 371.724 348.307 371.356V367.03C348.307 366.654 348.614 366.356 348.982 366.356H351.726C355.619 366.356 358.354 364.018 358.354 359.946ZM351.542 362.1H348.982C348.605 362.1 348.307 361.794 348.307 361.426V358.571C348.307 358.194 348.614 357.897 348.982 357.897H351.542C353.067 357.897 353.83 358.606 353.83 359.998C353.83 361.391 353.067 362.1 351.542 362.1Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M369.269 353.641H364.588C364.307 353.641 364.053 353.816 363.956 354.087L357.767 371.129C357.609 371.566 357.934 372.031 358.398 372.031H361.686C361.975 372.031 362.229 371.847 362.326 371.575L363.325 368.677C363.422 368.405 363.676 368.221 363.965 368.221H369.891C370.181 368.221 370.435 368.405 370.531 368.677L371.531 371.575C371.627 371.847 371.882 372.031 372.171 372.031H375.458C375.923 372.031 376.256 371.566 376.09 371.129L369.9 354.087C369.804 353.825 369.55 353.641 369.269 353.641ZM367.954 363.965H365.903C365.438 363.965 365.114 363.51 365.263 363.072L366.288 360.095C366.499 359.49 367.358 359.49 367.568 360.095L368.594 363.072C368.743 363.51 368.419 363.965 367.954 363.965Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M382.714 353.641H379.593C379.22 353.641 378.918 353.943 378.918 354.315V371.356C378.918 371.729 379.22 372.031 379.593 372.031H382.714C383.087 372.031 383.389 371.729 383.389 371.356V354.315C383.389 353.943 383.087 353.641 382.714 353.641Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M395.385 372.372C397.375 372.372 398.98 371.767 400.049 370.857C400.496 370.48 401.171 370.778 401.171 371.356C401.171 371.732 401.478 372.03 401.846 372.03H404.31C404.687 372.03 404.985 371.724 404.985 371.356V362.537C404.985 362.161 404.678 361.863 404.31 361.863H395.429C395.052 361.863 394.754 362.169 394.754 362.537V364.919C394.754 365.296 395.061 365.594 395.429 365.594H399.015C399.584 365.594 399.918 366.268 399.541 366.697C398.646 367.73 397.27 368.247 395.718 368.247C392.431 368.247 390.8 365.883 390.8 362.94C390.8 359.709 392.773 357.554 395.823 357.554C397.612 357.554 399.023 358.29 399.812 359.551C399.944 359.753 400.154 359.893 400.391 359.893H403.854C404.327 359.893 404.66 359.411 404.485 358.964C402.539 353.833 396.016 351.171 388.67 355.383C388.582 355.435 388.459 355.558 388.407 355.645C383.208 364.578 388.258 372.363 395.368 372.363L395.385 372.372Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M419.808 372.031H423.823C424.2 372.031 424.498 371.724 424.498 371.356V354.315C424.498 353.938 424.191 353.641 423.823 353.641H420.702C420.325 353.641 420.027 353.947 420.027 354.315V361.303C420.027 361.303 420.027 361.347 420.027 361.364L420.369 365.226L413.671 353.973C413.548 353.772 413.329 353.641 413.093 353.641H409.077C408.7 353.641 408.402 353.947 408.402 354.315V371.356C408.402 371.733 408.709 372.031 409.077 372.031H412.198C412.575 372.031 412.873 371.724 412.873 371.356V364.368C412.873 364.368 412.873 364.324 412.873 364.307L412.505 360.454L419.229 371.707C419.352 371.908 419.571 372.039 419.808 372.039V372.031Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M442.357 366.463C442.357 362.706 439.911 361.55 434.756 360.447C432.941 360.053 432.1 359.947 432.1 359.08C432.1 358.292 432.705 357.478 434.704 357.478C436.518 357.478 437.22 358.1 437.544 358.967C437.64 359.229 437.895 359.396 438.166 359.396H441.41C441.84 359.396 442.155 359.002 442.076 358.581C441.515 355.735 439.455 353.301 434.704 353.301C431.206 353.301 427.602 355.245 427.602 359.291C427.602 363.1 430.68 364.203 433.678 364.834C436.755 365.491 437.86 365.57 437.86 366.647C437.86 367.619 437.044 368.197 435.23 368.197C433.117 368.197 432.249 367.619 431.784 366.384C431.688 366.13 431.433 365.964 431.162 365.964H427.927C427.497 365.964 427.173 366.358 427.26 366.778C427.892 369.633 430.11 372.374 435.247 372.374C438.193 372.374 442.348 370.955 442.348 366.463H442.357Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M370.216 241.576H376.133C376.519 241.576 376.826 241.27 376.826 240.884V238.485C376.826 238.099 376.519 237.793 376.133 237.793H366.236C365.85 237.793 365.543 238.099 365.543 238.485V253.468C365.543 253.854 365.85 254.16 366.236 254.16H368.831C369.216 254.16 369.523 253.854 369.523 253.468V248.661C369.523 248.275 369.83 247.969 370.216 247.969H375.476C375.862 247.969 376.168 247.662 376.168 247.277V244.877C376.168 244.492 375.862 244.186 375.476 244.186H370.216C369.83 244.186 369.523 243.879 369.523 243.494V242.285C369.523 241.9 369.83 241.594 370.216 241.594V241.576Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M389.704 247.794C391.265 247.014 392.211 245.534 392.211 243.266C392.211 239.851 390.055 237.793 386.592 237.793H379.982C379.596 237.793 379.289 238.099 379.289 238.485V253.468C379.289 253.854 379.596 254.16 379.982 254.16H382.577C382.962 254.16 383.269 253.854 383.269 253.468V248.477H384.146C384.374 248.477 384.593 248.591 384.724 248.783L388.179 253.862C388.31 254.055 388.521 254.169 388.757 254.169H391.913C392.474 254.169 392.808 253.53 392.483 253.074L389.459 248.818C389.213 248.468 389.345 247.986 389.722 247.794H389.704ZM386.145 244.991H383.962C383.576 244.991 383.269 244.685 383.269 244.3V242.268C383.269 241.883 383.576 241.576 383.962 241.576H386.145C387.477 241.576 388.179 242.163 388.179 243.31C388.179 244.457 387.521 244.991 386.145 244.991Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M402.625 254.465C407.473 254.465 411.199 250.796 411.199 245.971C411.199 241.146 407.473 237.477 402.625 237.477C397.777 237.477 394.051 241.146 394.051 245.971C394.051 250.796 397.777 254.465 402.625 254.465ZM402.625 241.277C405.246 241.277 407.166 243.239 407.166 245.98C407.166 248.721 405.246 250.682 402.625 250.682C400.003 250.682 398.084 248.721 398.084 245.98C398.084 243.239 400.003 241.277 402.625 241.277Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M425.461 238.099L422.498 242.539C422.226 242.951 421.621 242.951 421.341 242.539L418.378 238.099C418.246 237.907 418.036 237.793 417.799 237.793H414.345C413.959 237.793 413.652 238.099 413.652 238.485V253.468C413.652 253.854 413.959 254.16 414.345 254.16H416.94C417.326 254.16 417.632 253.854 417.632 253.468V245.674C417.632 244.983 418.527 244.72 418.904 245.298L421.727 249.598H422.104L424.927 245.298C425.303 244.72 426.198 244.991 426.198 245.674V253.468C426.198 253.854 426.505 254.16 426.89 254.16H429.485C429.871 254.16 430.178 253.854 430.178 253.468V238.485C430.178 238.099 429.871 237.793 429.485 237.793H426.031C425.803 237.793 425.584 237.907 425.452 238.099H425.461Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M365.543 275.063C365.543 275.448 365.85 275.755 366.236 275.755H371.653C376.431 275.755 380.035 272.226 380.035 267.567C380.035 262.908 376.431 259.379 371.653 259.379H366.236C365.85 259.379 365.543 259.685 365.543 260.071V275.054V275.063ZM369.523 263.863C369.523 263.477 369.83 263.171 370.216 263.171H371.653C375.449 263.171 377.545 266.954 374.818 271.621C374.696 271.832 374.459 271.963 374.213 271.963H370.216C369.83 271.963 369.523 271.656 369.523 271.271V263.863Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M382.609 260.072V275.055C382.609 275.441 382.916 275.747 383.302 275.747H393.2C393.585 275.747 393.892 275.441 393.892 275.055V272.656C393.892 272.27 393.585 271.964 393.2 271.964H387.282C386.896 271.964 386.59 271.657 386.59 271.272V270.09C386.59 269.705 386.896 269.398 387.282 269.398H392.542C392.928 269.398 393.235 269.092 393.235 268.706V266.307C393.235 265.922 392.928 265.615 392.542 265.615H387.282C386.896 265.615 386.59 265.309 386.59 264.923V263.881C386.59 263.496 386.896 263.189 387.282 263.189H393.2C393.585 263.189 393.892 262.883 393.892 262.498V260.098C393.892 259.713 393.585 259.406 393.2 259.406H383.302C382.916 259.406 382.609 259.713 382.609 260.098V260.072Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M399.023 275.754C399.322 275.754 399.585 275.57 399.681 275.29L400.531 272.829C400.628 272.549 400.891 272.365 401.189 272.365H406.335C406.633 272.365 406.896 272.549 406.992 272.829L407.843 275.29C407.939 275.57 408.202 275.754 408.5 275.754H411.227C411.709 275.754 412.042 275.272 411.876 274.826L406.431 259.842C406.335 259.571 406.072 259.387 405.783 259.387H401.741C401.452 259.387 401.189 259.571 401.092 259.842L395.648 274.826C395.482 275.281 395.824 275.754 396.297 275.754H399.023ZM402.311 267.653L403.091 265.377C403.31 264.755 404.187 264.755 404.406 265.377L405.187 267.653C405.344 268.1 405.002 268.573 404.529 268.573H402.96C402.486 268.573 402.144 268.1 402.302 267.653H402.311Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M428.925 267.567C428.925 262.917 425.322 259.379 420.544 259.379H415.126C414.74 259.379 414.434 259.685 414.434 260.071V275.054C414.434 275.439 414.74 275.746 415.126 275.746H420.544C425.322 275.746 428.925 272.217 428.925 267.558V267.567ZM423.7 271.621C423.577 271.832 423.341 271.963 423.095 271.963H419.098C418.712 271.963 418.405 271.656 418.405 271.271V263.863C418.405 263.477 418.712 263.171 419.098 263.171H420.535C424.331 263.171 426.427 266.954 423.7 271.621Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M432.196 275.754H441.437C441.822 275.754 442.129 275.447 442.129 275.062V272.663C442.129 272.277 441.822 271.971 441.437 271.971H436.177C435.791 271.971 435.484 271.664 435.484 271.279V260.079C435.484 259.693 435.177 259.387 434.791 259.387H432.196C431.811 259.387 431.504 259.693 431.504 260.079V275.062C431.504 275.447 431.811 275.754 432.196 275.754Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M447.643 259.379H445.048C444.666 259.379 444.355 259.689 444.355 260.071V275.063C444.355 275.445 444.666 275.755 445.048 275.755H447.643C448.026 275.755 448.336 275.445 448.336 275.063V260.071C448.336 259.689 448.026 259.379 447.643 259.379Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M452.194 275.754H454.789C455.174 275.754 455.481 275.447 455.481 275.062V268.932C455.481 268.932 455.481 268.888 455.481 268.871L455.157 265.438L461.109 275.412C461.232 275.622 461.46 275.754 461.706 275.754H465.133C465.519 275.754 465.826 275.447 465.826 275.062V260.079C465.826 259.693 465.519 259.387 465.133 259.387H462.538C462.153 259.387 461.846 259.693 461.846 260.079V266.209C461.846 266.209 461.846 266.252 461.846 266.27L462.144 269.703L456.209 259.728C456.086 259.518 455.858 259.387 455.613 259.387H452.185C451.799 259.387 451.492 259.693 451.492 260.079V275.062C451.492 275.447 451.799 275.754 452.185 275.754H452.194Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M469.681 275.755H479.579C479.964 275.755 480.271 275.448 480.271 275.063V272.664C480.271 272.278 479.964 271.972 479.579 271.972H473.661C473.275 271.972 472.968 271.665 472.968 271.28V270.098C472.968 269.712 473.275 269.406 473.661 269.406H478.921C479.307 269.406 479.614 269.099 479.614 268.714V266.315C479.614 265.929 479.307 265.623 478.921 265.623H473.661C473.275 265.623 472.968 265.316 472.968 264.931V263.889C472.968 263.504 473.275 263.197 473.661 263.197H479.579C479.964 263.197 480.271 262.891 480.271 262.505V260.106C480.271 259.721 479.964 259.414 479.579 259.414H469.681C469.295 259.414 468.988 259.721 468.988 260.106V275.089C468.988 275.475 469.295 275.781 469.681 275.781V275.755Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M489.144 276.05C491.766 276.05 495.465 274.789 495.465 270.787C495.465 267.442 493.291 266.418 488.697 265.428C487.084 265.078 486.33 264.981 486.33 264.211C486.33 263.51 486.865 262.783 488.645 262.783C490.223 262.783 490.854 263.309 491.152 264.053C491.257 264.316 491.511 264.491 491.792 264.491H494.501C494.939 264.491 495.281 264.088 495.185 263.659C494.659 261.172 492.818 259.07 488.645 259.07C485.532 259.07 482.324 260.804 482.324 264.403C482.324 267.792 485.059 268.773 487.733 269.342C490.468 269.929 491.459 269.999 491.459 270.954C491.459 271.821 490.731 272.337 489.118 272.337C487.259 272.337 486.479 271.838 486.067 270.761C485.971 270.507 485.708 270.35 485.436 270.35H482.736C482.289 270.35 481.955 270.761 482.052 271.199C482.648 273.695 484.638 276.059 489.135 276.059L489.144 276.05Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M67.2939 405.826C70.1606 405.826 72.3962 407.201 73.5709 409.486C73.6586 409.661 73.8339 409.775 74.0268 409.775H80.374C80.7159 409.775 80.9701 409.442 80.8737 409.118C79.1379 403.339 73.983 399.293 67.2939 399.293C58.8164 399.293 52.6445 405.624 52.6445 413.926C52.6445 422.228 58.8164 428.559 67.2939 428.559C73.983 428.559 79.1379 424.514 80.8737 418.734C80.9701 418.41 80.7159 418.077 80.374 418.077H74.0268C73.8339 418.077 73.6586 418.191 73.5709 418.366C72.3962 420.652 70.1606 422.027 67.2939 422.027C62.6124 422.027 59.5879 418.637 59.5879 413.926C59.5879 409.215 62.6124 405.826 67.2939 405.826Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M113.626 413.926C113.626 405.624 107.209 399.293 98.8541 399.293C90.4993 399.293 84.082 405.624 84.082 413.926C84.082 422.228 90.4993 428.559 98.8541 428.559C107.209 428.559 113.626 422.228 113.626 413.926ZM91.0254 413.926C91.0254 409.206 94.3304 405.826 98.8541 405.826C103.378 405.826 106.683 409.215 106.683 413.926C106.683 418.637 103.378 422.027 98.8541 422.027C94.3304 422.027 91.0254 418.637 91.0254 413.926Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M120.884 434.409L116.238 438.464C116.124 438.56 116.062 438.7 116.062 438.858V440.951C116.062 441.24 116.29 441.467 116.58 441.467H128.292C128.582 441.467 128.809 441.24 128.809 440.951V438.183C128.809 437.894 128.582 437.667 128.292 437.667H122.62L122.664 437.64L124.189 436.301C126.776 434.041 128.494 432.614 128.494 430.048C128.494 427.202 126.355 424.715 122.638 424.715C119.21 424.715 116.667 426.799 116.282 430.162C116.247 430.477 116.483 430.757 116.799 430.757H119.815C120.06 430.757 120.271 430.591 120.323 430.354C120.56 429.146 121.217 428.533 122.445 428.533C123.953 428.533 124.444 429.452 124.444 430.249C124.444 431.239 123.953 431.729 120.867 434.435L120.884 434.409Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M151.947 406.348H163.502C163.791 406.348 164.019 406.121 164.019 405.832V400.341C164.019 400.052 163.791 399.824 163.502 399.824H145.091C144.802 399.824 144.574 400.052 144.574 400.341V427.523C144.574 427.812 144.802 428.04 145.091 428.04H150.913C151.202 428.04 151.43 427.812 151.43 427.523V417.873C151.43 417.584 151.658 417.356 151.947 417.356H162.371C162.66 417.356 162.888 417.128 162.888 416.839V411.349C162.888 411.06 162.66 410.832 162.371 410.832H151.947C151.658 410.832 151.43 410.604 151.43 410.315V406.874C151.43 406.585 151.658 406.357 151.947 406.357V406.348Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M181.815 399.293C173.46 399.293 167.043 405.624 167.043 413.926C167.043 422.228 173.46 428.559 181.815 428.559C190.17 428.559 196.587 422.228 196.587 413.926C196.587 405.624 190.17 399.293 181.815 399.293ZM181.815 422.027C177.291 422.027 173.986 418.637 173.986 413.926C173.986 409.215 177.291 405.826 181.815 405.826C186.339 405.826 189.644 409.215 189.644 413.926C189.644 418.637 186.339 422.027 181.815 422.027Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M214.585 428.559C222.939 428.559 229.357 422.228 229.357 413.926C229.357 405.624 222.939 399.293 214.585 399.293C206.23 399.293 199.812 405.624 199.812 413.926C199.812 422.228 206.23 428.559 214.585 428.559ZM214.585 405.826C219.108 405.826 222.413 409.215 222.413 413.926C222.413 418.637 219.108 422.027 214.585 422.027C210.061 422.027 206.756 418.637 206.756 413.926C206.756 409.215 210.061 405.826 214.585 405.826Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M239.64 406.865V427.514C239.64 427.803 239.868 428.031 240.157 428.031H245.978C246.267 428.031 246.495 427.803 246.495 427.514V406.865C246.495 406.576 246.723 406.348 247.013 406.348H253.684C253.973 406.348 254.201 406.121 254.201 405.832V400.341C254.201 400.052 253.973 399.824 253.684 399.824H232.486C232.197 399.824 231.969 400.052 231.969 400.341V405.832C231.969 406.121 232.197 406.348 232.486 406.348H239.114C239.403 406.348 239.631 406.576 239.631 406.865H239.64Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M280.319 409.493C280.319 403.249 276.12 399.816 270.15 399.816H258.56C258.271 399.816 258.043 400.044 258.043 400.333V427.515C258.043 427.804 258.271 428.032 258.56 428.032H264.381C264.671 428.032 264.899 427.804 264.899 427.515V419.844C264.899 419.555 265.127 419.327 265.416 419.327H270.141C276.111 419.327 280.311 415.737 280.311 409.493H280.319ZM265.425 412.794C265.135 412.794 264.907 412.567 264.907 412.278V406.866C264.907 406.577 265.135 406.349 265.425 406.349H269.869C272.21 406.349 273.376 407.435 273.376 409.572C273.376 411.709 272.201 412.794 269.869 412.794H265.425Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M284.764 400.332V427.514C284.764 427.803 284.992 428.031 285.281 428.031H291.102C291.391 428.031 291.619 427.803 291.619 427.514V418.757C291.619 418.468 291.847 418.24 292.137 418.24H293.478C293.653 418.24 293.811 418.328 293.907 418.468L300.255 427.812C300.351 427.952 300.509 428.04 300.684 428.04H307.803C308.224 428.04 308.469 427.567 308.224 427.225L301.771 418.153C301.578 417.881 301.684 417.496 301.99 417.382C305.103 416.139 307.023 413.503 307.023 409.256C307.023 403.371 303.314 399.824 297.335 399.824H285.263C284.974 399.824 284.746 400.052 284.746 400.341L284.764 400.332ZM291.619 406.865C291.619 406.576 291.847 406.348 292.137 406.348H296.581C298.878 406.348 300.088 407.355 300.088 409.334C300.088 411.314 298.957 412.233 296.581 412.233H292.137C291.847 412.233 291.619 412.005 291.619 411.716V406.865Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M318.182 399.816H312.361C312.075 399.816 311.844 400.048 311.844 400.333V427.515C311.844 427.801 312.075 428.032 312.361 428.032H318.182C318.468 428.032 318.699 427.801 318.699 427.515V400.333C318.699 400.048 318.468 399.816 318.182 399.816Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M324.152 400.334V427.516C324.152 427.805 324.38 428.033 324.67 428.033H330.491C330.78 428.033 331.008 427.805 331.008 427.516V416.246C331.008 416.246 331.008 416.211 331.008 416.193L330.666 412.541C330.613 411.99 331.341 411.753 331.63 412.226L340.915 427.779C341.011 427.937 341.178 428.033 341.362 428.033H348.34C348.629 428.033 348.857 427.805 348.857 427.516V400.334C348.857 400.045 348.629 399.817 348.34 399.817H342.519C342.23 399.817 342.002 400.045 342.002 400.334V411.604C342.002 411.604 342.002 411.631 342.002 411.648L342.326 415.326C342.379 415.878 341.642 416.106 341.362 415.633L332.104 400.063C332.007 399.905 331.841 399.809 331.657 399.809H324.678C324.389 399.809 324.161 400.036 324.161 400.325L324.152 400.334Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M352.995 406.348H359.622C359.912 406.348 360.14 406.576 360.14 406.865V427.514C360.14 427.803 360.368 428.031 360.657 428.031H366.478C366.767 428.031 366.995 427.803 366.995 427.514V406.865C366.995 406.576 367.223 406.348 367.513 406.348H374.184C374.473 406.348 374.701 406.121 374.701 405.832V400.341C374.701 400.052 374.473 399.824 374.184 399.824H352.986C352.697 399.824 352.469 400.052 352.469 400.341V405.832C352.469 406.121 352.697 406.348 352.986 406.348H352.995Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M148.825 454.637C148.878 454.874 149.088 455.031 149.325 455.031H152.744C152.981 455.031 153.191 454.865 153.244 454.637L154.374 449.961C154.374 449.961 154.374 449.926 154.383 449.909L155.085 444.935L155.426 444.952L156.145 449.909C156.145 449.909 156.145 449.944 156.154 449.952L157.285 454.629C157.338 454.865 157.548 455.023 157.785 455.023H161.186C161.423 455.023 161.633 454.856 161.686 454.629L164.842 441.554C164.921 441.23 164.675 440.915 164.342 440.915H161.896C161.66 440.915 161.449 441.081 161.388 441.309L159.871 447.632C159.871 447.632 159.871 447.667 159.863 447.684L159.442 451.021L159.003 447.684C159.003 447.684 159.003 447.649 158.995 447.632L157.478 441.3C157.425 441.064 157.215 440.906 156.969 440.906H153.489C153.252 440.906 153.042 441.073 152.981 441.3L151.464 447.632C151.464 447.632 151.464 447.667 151.455 447.684L151.034 450.942L150.614 447.684C150.614 447.684 150.613 447.649 150.605 447.632L149.088 441.3C149.035 441.064 148.825 440.906 148.58 440.906H146.116C145.783 440.906 145.538 441.221 145.616 441.546L148.772 454.62L148.825 454.637Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M169.848 440.922H167.455C167.169 440.922 166.938 441.153 166.938 441.439V454.513C166.938 454.798 167.169 455.03 167.455 455.03H169.848C170.134 455.03 170.365 454.798 170.365 454.513V441.439C170.365 441.153 170.134 440.922 169.848 440.922Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M173.188 454.513C173.188 454.802 173.415 455.03 173.705 455.03H181.832C182.121 455.03 182.349 454.802 182.349 454.513V452.28C182.349 451.991 182.121 451.763 181.832 451.763H177.141C176.852 451.763 176.624 451.536 176.624 451.247V441.439C176.624 441.15 176.396 440.922 176.107 440.922H173.714C173.424 440.922 173.196 441.15 173.196 441.439V454.513H173.188Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M184.27 441.446V454.521C184.27 454.81 184.497 455.037 184.787 455.037H192.914C193.203 455.037 193.431 454.81 193.431 454.521V452.288C193.431 451.999 193.203 451.771 192.914 451.771H188.223C187.934 451.771 187.706 451.543 187.706 451.254V441.446C187.706 441.157 187.478 440.93 187.189 440.93H184.796C184.506 440.93 184.278 441.157 184.278 441.446H184.27Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M204.829 454.513V450.651C204.829 450.362 205.057 450.134 205.346 450.134H205.627C205.802 450.134 205.96 450.222 206.057 450.362L209.081 454.802C209.178 454.942 209.335 455.03 209.511 455.03H212.43C212.851 455.03 213.096 454.557 212.851 454.215L210.098 450.345C209.914 450.082 210.01 449.723 210.3 449.583C211.694 448.917 212.544 447.639 212.544 445.642C212.544 442.7 210.685 440.922 207.705 440.922H201.927C201.638 440.922 201.41 441.15 201.41 441.439V454.513C201.41 454.802 201.638 455.03 201.927 455.03H204.321C204.61 455.03 204.838 454.802 204.838 454.513H204.829ZM204.829 444.705C204.829 444.416 205.057 444.188 205.346 444.188H207.31C208.459 444.188 209.064 444.696 209.064 445.677C209.064 446.658 208.503 447.131 207.31 447.131H205.346C205.057 447.131 204.829 446.903 204.829 446.614V444.705Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M219.293 444.188H224.553C224.842 444.188 225.07 443.961 225.07 443.672V441.439C225.07 441.15 224.842 440.922 224.553 440.922H215.865C215.576 440.922 215.348 441.15 215.348 441.439V454.513C215.348 454.802 215.576 455.03 215.865 455.03H224.553C224.842 455.03 225.07 454.802 225.07 454.513V452.28C225.07 451.991 224.842 451.763 224.553 451.763H219.293C219.003 451.763 218.775 451.536 218.775 451.247V450.064C218.775 449.775 219.003 449.548 219.293 449.548H223.983C224.272 449.548 224.5 449.32 224.5 449.031V446.798C224.5 446.509 224.272 446.281 223.983 446.281H219.293C219.003 446.281 218.775 446.054 218.775 445.765V444.705C218.775 444.416 219.003 444.188 219.293 444.188Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M239.676 447.98C239.676 443.969 236.573 440.922 232.452 440.922H227.701C227.412 440.922 227.184 441.15 227.184 441.439V454.513C227.184 454.802 227.412 455.03 227.701 455.03H232.452C236.573 455.03 239.676 451.982 239.676 447.971V447.98ZM230.62 451.247V444.705C230.62 444.416 230.848 444.188 231.137 444.188H232.452C235.731 444.188 237.537 447.472 235.153 451.509C235.056 451.667 234.881 451.763 234.697 451.763H231.129C230.839 451.763 230.611 451.536 230.611 451.247H230.62Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M244.866 440.922H242.412C242.122 440.922 241.895 441.158 241.895 441.439L241.912 449.267C241.912 453.278 244.454 455.292 247.847 455.292C251.24 455.292 253.782 453.278 253.782 449.267L253.8 441.439C253.8 441.15 253.572 440.922 253.283 440.922H250.828C250.539 440.922 250.311 441.15 250.311 441.439V449.066C250.311 451.282 249.32 452.026 247.847 452.026C246.374 452.026 245.384 451.282 245.384 449.066V441.439C245.384 441.15 245.156 440.922 244.866 440.922Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M263.348 443.927C264.724 443.927 265.811 444.557 266.416 445.625C266.512 445.792 266.679 445.906 266.872 445.906H269.528C269.888 445.906 270.142 445.547 270.019 445.205C269.046 442.517 266.548 440.66 263.348 440.66C259.113 440.66 256.027 443.821 256.027 447.972C256.027 452.123 259.113 455.285 263.348 455.285C266.548 455.285 269.046 453.437 270.019 450.74C270.142 450.398 269.888 450.039 269.528 450.039H266.872C266.679 450.039 266.512 450.153 266.416 450.319C265.811 451.379 264.724 452.018 263.348 452.018C261.007 452.018 259.49 450.328 259.49 447.964C259.49 445.599 261.007 443.909 263.348 443.909V443.927Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M281.557 440.922H272.869C272.579 440.922 272.352 441.15 272.352 441.439V454.513C272.352 454.802 272.579 455.03 272.869 455.03H281.557C281.846 455.03 282.074 454.802 282.074 454.513V452.28C282.074 451.991 281.846 451.763 281.557 451.763H276.297C276.007 451.763 275.779 451.536 275.779 451.247V450.064C275.779 449.775 276.007 449.548 276.297 449.548H280.987C281.276 449.548 281.504 449.32 281.504 449.031V446.798C281.504 446.509 281.276 446.281 280.987 446.281H276.297C276.007 446.281 275.779 446.054 275.779 445.765V444.705C275.779 444.416 276.007 444.188 276.297 444.188H281.557C281.846 444.188 282.074 443.961 282.074 443.672V441.439C282.074 441.15 281.846 440.922 281.557 440.922Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M200.364 495.111H183.725C183.4 495.111 183.137 495.374 183.137 495.698V501.022C183.137 501.346 183.4 501.609 183.725 501.609H191.702C192.158 501.609 192.448 502.108 192.22 502.493C190.764 504.971 188.003 506.233 184.829 506.233C179.105 506.233 176.264 502.117 176.264 496.994C176.264 491.372 179.701 487.615 185.013 487.615C188.266 487.615 190.8 489.007 192.132 491.38C192.237 491.564 192.43 491.687 192.641 491.687H199.847C200.241 491.687 200.531 491.31 200.417 490.934C197.436 481.406 185.688 476.231 172.433 483.919C172.354 483.963 172.258 484.068 172.205 484.138C163 499.752 171.811 513.405 184.233 513.405C188.327 513.405 191.483 511.925 193.281 509.849C193.631 509.438 194.306 509.692 194.306 510.235V512.222C194.306 512.546 194.569 512.809 194.894 512.809H200.355C200.68 512.809 200.943 512.546 200.943 512.222V495.698C200.943 495.374 200.68 495.111 200.355 495.111H200.364Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M228.62 480.793H207.72C207.396 480.793 207.133 481.056 207.133 481.38V512.222C207.133 512.546 207.396 512.809 207.72 512.809H228.62C228.945 512.809 229.208 512.546 229.208 512.222V505.987C229.208 505.663 228.945 505.4 228.62 505.4H215.505C215.181 505.4 214.918 505.138 214.918 504.814V500.961C214.918 500.637 215.181 500.374 215.505 500.374H227.332C227.656 500.374 227.919 500.111 227.919 499.787V493.552C227.919 493.228 227.656 492.965 227.332 492.965H215.505C215.181 492.965 214.918 492.703 214.918 492.379V488.797C214.918 488.473 215.181 488.21 215.505 488.21H228.62C228.945 488.21 229.208 487.948 229.208 487.624V481.388C229.208 481.064 228.945 480.802 228.62 480.802V480.793Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M256.597 480.793H232.541C232.216 480.793 231.953 481.056 231.953 481.38V487.615C231.953 487.939 232.216 488.201 232.541 488.201H240.062C240.387 488.201 240.65 488.464 240.65 488.788V512.222C240.65 512.546 240.913 512.809 241.237 512.809H247.847C248.172 512.809 248.435 512.546 248.435 512.222V488.788C248.435 488.464 248.698 488.201 249.022 488.201H256.588C256.912 488.201 257.175 487.939 257.175 487.615V481.38C257.175 481.056 256.912 480.793 256.588 480.793H256.597Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M273.902 496.803C273.902 506.225 281.188 513.406 290.665 513.406C300.141 513.406 307.427 506.225 307.427 496.803C307.427 487.38 300.141 480.199 290.665 480.199C281.188 480.199 273.902 487.38 273.902 496.803ZM299.545 496.803C299.545 502.153 295.793 505.998 290.665 505.998C285.536 505.998 281.784 502.153 281.784 496.803C281.784 491.452 285.536 487.608 290.665 487.608C295.793 487.608 299.545 491.452 299.545 496.803Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M340.259 512.223V481.381C340.259 481.057 339.996 480.794 339.672 480.794H333.061C332.737 480.794 332.474 481.057 332.474 481.381V494.166C332.474 494.166 332.474 494.201 332.474 494.219L332.842 498.396C332.895 499.018 332.071 499.28 331.746 498.746L321.235 481.074C321.13 480.899 320.937 480.785 320.726 480.785H312.81C312.486 480.785 312.223 481.048 312.223 481.372V512.215C312.223 512.539 312.486 512.801 312.81 512.801H319.42C319.745 512.801 320.008 512.539 320.008 512.215V499.429C320.008 499.429 320.008 499.394 320.008 499.377L319.613 495.226C319.552 494.604 320.385 494.332 320.7 494.867L331.238 512.512C331.343 512.687 331.536 512.801 331.746 512.801H339.663C339.987 512.801 340.25 512.539 340.25 512.215L340.259 512.223Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M367.231 512.222V505.987C367.231 505.663 366.968 505.4 366.644 505.4H354.818C354.493 505.4 354.23 505.138 354.23 504.814V481.38C354.23 481.056 353.967 480.793 353.643 480.793H347.033C346.708 480.793 346.445 481.056 346.445 481.38V512.222C346.445 512.546 346.708 512.809 347.033 512.809H366.644C366.968 512.809 367.231 512.546 367.231 512.222Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M393.979 480.793H386.606C386.378 480.793 386.177 480.924 386.08 481.126L380.496 492.729C380.285 493.176 379.645 493.176 379.435 492.729L373.851 481.126C373.754 480.924 373.544 480.793 373.325 480.793H365.908C365.47 480.793 365.18 481.257 365.382 481.651L376.007 502.537C376.051 502.616 376.069 502.712 376.069 502.8V512.222C376.069 512.546 376.332 512.809 376.656 512.809H383.266C383.591 512.809 383.854 512.546 383.854 512.222V502.703C383.854 502.607 383.871 502.519 383.915 502.441L394.496 481.651C394.698 481.257 394.409 480.793 393.97 480.793H393.979Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M105.265 538.783C104.827 538.547 104.792 537.951 105.204 537.662C107.211 536.279 108.29 534.343 108.29 531.401C108.29 525.166 103.608 522.145 97.4626 522.145H82.1995C81.8401 522.145 81.5508 522.434 81.5508 522.793V556.7C81.5508 557.059 81.8401 557.348 82.1995 557.348H97.4626C104.511 557.348 109.491 553.679 109.491 547.041C109.491 543.494 108.123 540.351 105.256 538.783H105.265ZM90.1247 530.945C90.1247 530.586 90.414 530.297 90.7735 530.297H96.3229C98.3831 530.297 99.6455 530.753 99.6455 532.916C99.6455 534.772 98.5847 535.578 96.6736 535.578H90.7735C90.414 535.578 90.1247 535.289 90.1247 534.93V530.945ZM97.2697 549.204H90.7647C90.4053 549.204 90.116 548.915 90.116 548.556V544.011C90.116 543.652 90.4053 543.363 90.7647 543.363H97.2697C100.136 543.363 100.847 544.318 100.847 546.279C100.847 548.092 100.145 549.195 97.2697 549.195V549.204Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M138.403 522.155H115.434C115.074 522.155 114.785 522.444 114.785 522.803V556.711C114.785 557.07 115.074 557.359 115.434 557.359H138.403C138.762 557.359 139.052 557.07 139.052 556.711V549.854C139.052 549.495 138.762 549.206 138.403 549.206H123.99C123.631 549.206 123.342 548.917 123.342 548.558V544.32C123.342 543.96 123.631 543.671 123.99 543.671H136.992C137.351 543.671 137.64 543.383 137.64 543.023V536.167C137.64 535.808 137.351 535.519 136.992 535.519H123.99C123.631 535.519 123.342 535.23 123.342 534.871V530.93C123.342 530.571 123.631 530.282 123.99 530.282H138.403C138.762 530.282 139.052 529.993 139.052 529.634V522.777C139.052 522.418 138.762 522.129 138.403 522.129V522.155Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M174.249 522.154H166.981C166.622 522.154 166.332 522.443 166.332 522.802V536.857C166.332 536.857 166.332 536.892 166.332 536.91L166.736 541.499C166.797 542.182 165.885 542.48 165.534 541.884L153.98 522.461C153.866 522.268 153.655 522.145 153.427 522.145H144.731C144.371 522.145 144.082 522.434 144.082 522.794V556.701C144.082 557.06 144.371 557.349 144.731 557.349H151.998C152.358 557.349 152.647 557.06 152.647 556.701V542.646C152.647 542.646 152.647 542.602 152.647 542.585L152.218 538.022C152.156 537.339 153.068 537.041 153.419 537.628L165 557.025C165.114 557.218 165.324 557.34 165.552 557.34H174.249C174.608 557.34 174.897 557.051 174.897 556.692V522.785C174.897 522.426 174.608 522.137 174.249 522.137V522.154Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M205.309 522.155H182.34C181.981 522.155 181.691 522.444 181.691 522.803V556.711C181.691 557.07 181.981 557.359 182.34 557.359H205.309C205.669 557.359 205.958 557.07 205.958 556.711V549.854C205.958 549.495 205.669 549.206 205.309 549.206H190.897C190.537 549.206 190.248 548.917 190.248 548.558V544.32C190.248 543.96 190.537 543.671 190.897 543.671H203.898C204.257 543.671 204.547 543.383 204.547 543.023V536.167C204.547 535.808 204.257 535.519 203.898 535.519H190.897C190.537 535.519 190.248 535.23 190.248 534.871V530.93C190.248 530.571 190.537 530.282 190.897 530.282H205.309C205.669 530.282 205.958 529.993 205.958 529.634V522.777C205.958 522.418 205.669 522.129 205.309 522.129V522.155Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M234.856 522.154H211.887C211.528 522.154 211.238 522.443 211.238 522.802V556.71C211.238 557.069 211.528 557.358 211.887 557.358H219.155C219.514 557.358 219.803 557.069 219.803 556.71V544.678C219.803 544.319 220.093 544.03 220.452 544.03H233.453C233.813 544.03 234.102 543.741 234.102 543.382V536.525C234.102 536.166 233.813 535.877 233.453 535.877H220.452C220.093 535.877 219.803 535.588 219.803 535.229V530.938C219.803 530.579 220.093 530.29 220.452 530.29H234.865C235.224 530.29 235.514 530.001 235.514 529.642V522.785C235.514 522.426 235.224 522.137 234.865 522.137L234.856 522.154Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M248.697 522.152H241.438C241.08 522.152 240.789 522.442 240.789 522.8V556.699C240.789 557.057 241.08 557.347 241.438 557.347H248.697C249.055 557.347 249.345 557.057 249.345 556.699V522.8C249.345 522.442 249.055 522.152 248.697 522.152Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M281.222 522.152H254.782C254.422 522.152 254.133 522.441 254.133 522.8V529.657C254.133 530.016 254.422 530.305 254.782 530.305H263.049C263.408 530.305 263.697 530.594 263.697 530.953V556.717C263.697 557.076 263.987 557.365 264.346 557.365H271.614C271.973 557.365 272.263 557.076 272.263 556.717V530.953C272.263 530.594 272.552 530.305 272.911 530.305H281.231C281.591 530.305 281.88 530.016 281.88 529.657V522.8C281.88 522.441 281.591 522.152 281.231 522.152H281.222Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M299.485 535.175C296.013 534.422 294.4 534.22 294.4 532.556C294.4 531.05 295.557 529.491 299.38 529.491C303.202 529.491 304.385 530.875 304.947 532.723C305.025 532.985 305.28 533.161 305.56 533.161H312.994C313.38 533.161 313.696 532.819 313.643 532.434C312.819 526.637 308.953 521.496 299.388 521.496C292.691 521.496 285.8 525.218 285.8 532.959C285.8 540.254 291.691 542.364 297.425 543.573C303.316 544.834 305.429 544.983 305.429 547.041C305.429 548.897 303.868 550.009 300.397 550.009C296.162 550.009 294.532 548.792 293.69 546.2C293.602 545.928 293.357 545.736 293.076 545.736H285.642C285.247 545.736 284.932 546.086 285.002 546.471C285.949 552.225 290.122 558.005 300.449 558.005C306.086 558.005 314.038 555.29 314.038 546.69C314.038 539.501 309.356 537.285 299.494 535.175H299.485Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M346.72 522.961C346.817 522.558 346.51 522.164 346.089 522.164H343.625C343.327 522.164 343.064 522.365 342.994 522.663L341.372 529.432C341.372 529.432 341.364 529.476 341.364 529.503L340.908 533.11L340.434 529.503C340.434 529.503 340.434 529.459 340.426 529.432L338.804 522.663C338.734 522.374 338.471 522.164 338.172 522.164H334.543C334.245 522.164 333.982 522.365 333.912 522.663L332.29 529.432C332.29 529.432 332.281 529.476 332.281 529.503L331.825 533.023L331.369 529.503C331.369 529.503 331.369 529.459 331.361 529.432L329.739 522.663C329.669 522.374 329.406 522.164 329.108 522.164H326.618C326.197 522.164 325.89 522.558 325.987 522.961L329.362 536.92C329.432 537.209 329.695 537.41 329.993 537.41H333.552C333.85 537.41 334.113 537.209 334.184 536.92L335.385 531.928C335.385 531.928 335.385 531.884 335.393 531.867L336.156 526.49L336.524 526.508L337.305 531.858C337.305 531.858 337.305 531.893 337.313 531.919L338.514 536.911C338.584 537.2 338.848 537.401 339.146 537.401H342.687C342.985 537.401 343.248 537.2 343.319 536.911L346.694 522.952L346.72 522.961Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M352.04 522.152H349.629C349.271 522.152 348.98 522.442 348.98 522.8V536.759C348.98 537.117 349.271 537.407 349.629 537.407H352.04C352.398 537.407 352.689 537.117 352.689 536.759V522.8C352.689 522.442 352.398 522.152 352.04 522.152Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M356.388 537.408H364.997C365.356 537.408 365.646 537.119 365.646 536.76V534.518C365.646 534.159 365.356 533.87 364.997 533.87H360.096C359.737 533.87 359.448 533.581 359.448 533.222V522.793C359.448 522.434 359.158 522.145 358.799 522.145H356.379C356.02 522.145 355.73 522.434 355.73 522.793V536.751C355.73 537.111 356.02 537.399 356.379 537.399L356.388 537.408Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M370.782 522.153H368.371C368.012 522.153 367.723 522.442 367.723 522.801V536.76C367.723 537.119 368.012 537.408 368.371 537.408H376.98C377.34 537.408 377.629 537.119 377.629 536.76V534.518C377.629 534.159 377.34 533.87 376.98 533.87H372.08C371.72 533.87 371.431 533.581 371.431 533.222V522.793C371.431 522.434 371.142 522.145 370.782 522.145V522.153Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M395.637 537.407H398.837C399.196 537.407 399.486 537.118 399.486 536.759V522.8C399.486 522.441 399.196 522.152 398.837 522.152H396.426C396.067 522.152 395.777 522.441 395.777 522.8V528.51C395.777 528.51 395.777 528.545 395.777 528.563L396.058 531.759L390.526 522.468C390.412 522.275 390.202 522.152 389.974 522.152H386.774C386.414 522.152 386.125 522.441 386.125 522.8V536.759C386.125 537.118 386.414 537.407 386.774 537.407H389.193C389.553 537.407 389.842 537.118 389.842 536.759V531.05C389.842 531.05 389.842 531.006 389.842 530.988L389.535 527.792L395.085 537.083C395.199 537.276 395.409 537.399 395.637 537.399V537.407Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M409.76 537.698C414.275 537.698 417.747 534.274 417.747 529.791C417.747 525.307 414.275 521.883 409.76 521.883C405.245 521.883 401.773 525.307 401.773 529.791C401.773 534.274 405.245 537.698 409.76 537.698ZM409.76 525.403C412.206 525.403 413.994 527.233 413.994 529.782C413.994 532.33 412.206 534.16 409.76 534.16C407.314 534.16 405.526 532.33 405.526 529.782C405.526 527.233 407.314 525.403 409.76 525.403Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M430.532 522.152H419.801C419.442 522.152 419.152 522.441 419.152 522.8V525.042C419.152 525.401 419.442 525.69 419.801 525.69H422.65C423.01 525.69 423.299 525.979 423.299 526.338V536.768C423.299 537.127 423.588 537.416 423.948 537.416H426.359C426.718 537.416 427.007 537.127 427.007 536.768V526.338C427.007 525.979 427.297 525.69 427.656 525.69H430.532C430.891 525.69 431.18 525.401 431.18 525.042V522.8C431.18 522.441 430.891 522.152 430.532 522.152Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M334.174 545.517C335.647 545.517 336.804 546.183 337.462 547.304C337.585 547.514 337.795 547.654 338.032 547.654H340.723C341.179 547.654 341.495 547.199 341.337 546.77C340.258 543.932 337.585 541.988 334.174 541.988C329.589 541.988 326.258 545.412 326.258 549.896C326.258 554.38 329.598 557.804 334.174 557.804C337.585 557.804 340.258 555.86 341.337 553.022C341.495 552.602 341.179 552.138 340.723 552.138H338.032C337.795 552.138 337.585 552.278 337.462 552.488C336.804 553.609 335.638 554.275 334.174 554.275C331.641 554.275 330.01 552.444 330.01 549.896C330.01 547.348 331.649 545.517 334.174 545.517Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M351.225 541.988C346.71 541.988 343.238 545.412 343.238 549.896C343.238 554.38 346.71 557.804 351.225 557.804C355.74 557.804 359.211 554.38 359.211 549.896C359.211 545.412 355.74 541.988 351.225 541.988ZM351.225 554.275C348.779 554.275 346.99 552.444 346.99 549.896C346.99 547.348 348.779 545.517 351.225 545.517C353.671 545.517 355.459 547.348 355.459 549.896C355.459 552.444 353.671 554.275 351.225 554.275Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M367.171 547.917C365.663 547.593 364.97 547.505 364.97 546.787C364.97 546.13 365.47 545.456 367.127 545.456C368.591 545.456 369.178 545.947 369.459 546.638C369.555 546.884 369.792 547.05 370.055 547.05H372.58C372.992 547.05 373.299 546.673 373.22 546.271C372.729 543.959 371.011 541.988 367.127 541.988C364.225 541.988 361.236 543.6 361.236 546.954C361.236 550.115 363.787 551.026 366.277 551.551C368.828 552.094 369.748 552.164 369.748 553.057C369.748 553.863 369.073 554.345 367.565 554.345C365.838 554.345 365.111 553.88 364.725 552.882C364.637 552.646 364.392 552.497 364.137 552.497H361.621C361.209 552.497 360.894 552.882 360.99 553.285C361.542 555.614 363.392 557.812 367.592 557.812C370.037 557.812 373.483 556.639 373.483 552.908C373.483 549.791 371.458 548.836 367.18 547.917H367.171Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M386.161 542.27H375.43C375.071 542.27 374.781 542.559 374.781 542.918V545.159C374.781 545.518 375.071 545.807 375.43 545.807H378.279C378.639 545.807 378.928 546.096 378.928 546.455V556.885C378.928 557.244 379.217 557.533 379.577 557.533H381.988C382.347 557.533 382.636 557.244 382.636 556.885V546.455C382.636 546.096 382.926 545.807 383.285 545.807H386.161C386.52 545.807 386.809 545.518 386.809 545.159V542.918C386.809 542.559 386.52 542.27 386.161 542.27Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M410.067 542.27H406.85C406.63 542.27 406.429 542.375 406.315 542.559L403.553 546.692C403.299 547.077 402.729 547.077 402.475 546.692L399.713 542.559C399.591 542.375 399.389 542.27 399.179 542.27H395.961C395.602 542.27 395.312 542.559 395.312 542.918V556.876C395.312 557.236 395.602 557.525 395.961 557.525H398.381C398.74 557.525 399.03 557.236 399.03 556.876V549.617C399.03 548.978 399.862 548.724 400.213 549.266L402.843 553.277H403.194L405.824 549.266C406.175 548.732 407.007 548.978 407.007 549.617V556.876C407.007 557.236 407.297 557.525 407.656 557.525H410.076C410.435 557.525 410.725 557.236 410.725 556.876V542.918C410.725 542.559 410.435 542.27 410.076 542.27H410.067Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M421.096 541.988C416.581 541.988 413.109 545.412 413.109 549.896C413.109 554.38 416.581 557.804 421.096 557.804C425.611 557.804 429.083 554.38 429.083 549.896C429.083 545.412 425.611 541.988 421.096 541.988ZM421.096 554.275C418.65 554.275 416.862 552.444 416.862 549.896C416.862 547.348 418.65 545.517 421.096 545.517C423.542 545.517 425.33 547.348 425.33 549.896C425.33 552.444 423.542 554.275 421.096 554.275Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M440.944 552.542C440.716 552.218 440.839 551.771 441.189 551.587C442.645 550.86 443.53 549.485 443.53 547.366C443.53 544.187 441.522 542.27 438.296 542.27H432.133C431.774 542.27 431.484 542.559 431.484 542.918V556.876C431.484 557.236 431.774 557.525 432.133 557.525H434.544C434.903 557.525 435.193 557.236 435.193 556.876V552.226H436.008C436.218 552.226 436.42 552.332 436.543 552.507L439.76 557.236C439.883 557.411 440.085 557.516 440.295 557.516H443.241C443.767 557.516 444.074 556.92 443.767 556.5L440.944 552.533V552.542ZM437.875 548.986H435.841C435.482 548.986 435.193 548.697 435.193 548.338V546.447C435.193 546.088 435.482 545.799 435.841 545.799H437.875C439.12 545.799 439.769 546.342 439.769 547.41C439.769 548.478 439.155 548.978 437.875 548.978V548.986Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M456.426 542.268H447.203C446.844 542.268 446.555 542.557 446.555 542.916V556.875C446.555 557.234 446.844 557.523 447.203 557.523H456.426C456.786 557.523 457.075 557.234 457.075 556.875V554.634C457.075 554.274 456.786 553.986 456.426 553.986H450.912C450.552 553.986 450.263 553.697 450.263 553.337V552.234C450.263 551.875 450.552 551.586 450.912 551.586H455.812C456.172 551.586 456.461 551.297 456.461 550.938V548.696C456.461 548.337 456.172 548.048 455.812 548.048H450.912C450.552 548.048 450.263 547.759 450.263 547.4V546.428C450.263 546.069 450.552 545.78 450.912 545.78H456.426C456.786 545.78 457.075 545.491 457.075 545.132V542.89C457.075 542.531 456.786 542.242 456.426 542.242V542.268Z"
                    stroke="#3388FF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M353.373 619.252C359.623 619.252 364.691 614.19 364.691 607.946C364.691 601.702 359.623 596.641 353.373 596.641C347.122 596.641 342.055 601.702 342.055 607.946C342.055 614.19 347.122 619.252 353.373 619.252Z"
                    fill="#FFD9F6"/>
                  <path
                    d="M361.177 80.0406C367.428 80.0406 372.495 74.979 372.495 68.7351C372.495 62.4913 367.428 57.4297 361.177 57.4297C354.927 57.4297 349.859 62.4913 349.859 68.7351C349.859 74.979 354.927 80.0406 361.177 80.0406Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M297.291 673.74C303.541 673.74 308.609 668.678 308.609 662.434C308.609 656.191 303.541 651.129 297.291 651.129C291.04 651.129 285.973 656.191 285.973 662.434C285.973 668.678 291.04 673.74 297.291 673.74Z"
                    fill="#D4F7F5"/>
                  <path
                    d="M317.726 727.01C321.706 727.01 324.932 723.787 324.932 719.812C324.932 715.836 321.706 712.613 317.726 712.613C313.746 712.613 310.52 715.836 310.52 719.812C310.52 723.787 313.746 727.01 317.726 727.01Z"
                    fill="#FFDED4"/>
                  <path
                    d="M334.128 16.8069C338.108 16.8069 341.335 13.5841 341.335 9.60852C341.335 5.63298 338.108 2.41016 334.128 2.41016C330.148 2.41016 326.922 5.63298 326.922 9.60852C326.922 13.5841 330.148 16.8069 334.128 16.8069Z"
                    fill="#FFDED4"/>
                  <path
                    d="M289.596 129.963C291.963 129.963 293.883 128.046 293.883 125.681C293.883 123.316 291.963 121.398 289.596 121.398C287.228 121.398 285.309 123.316 285.309 125.681C285.309 128.046 287.228 129.963 289.596 129.963Z"
                    fill="#FFDED4"/>
                  <path
                    d="M352.673 617.502C358.924 617.502 363.991 612.44 363.991 606.196C363.991 599.952 358.924 594.891 352.673 594.891C346.423 594.891 341.355 599.952 341.355 606.196C341.355 612.44 346.423 617.502 352.673 617.502Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M360.466 78.2906C366.717 78.2906 371.784 73.229 371.784 66.9851C371.784 60.7413 366.717 55.6797 360.466 55.6797C354.216 55.6797 349.148 60.7413 349.148 66.9851C349.148 73.229 354.216 78.2906 360.466 78.2906Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M296.591 671.986C302.842 671.986 307.909 666.924 307.909 660.681C307.909 654.437 302.842 649.375 296.591 649.375C290.341 649.375 285.273 654.437 285.273 660.681C285.273 666.924 290.341 671.986 296.591 671.986Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M317.027 725.256C321.007 725.256 324.233 722.033 324.233 718.058C324.233 714.082 321.007 710.859 317.027 710.859C313.047 710.859 309.82 714.082 309.82 718.058C309.82 722.033 313.047 725.256 317.027 725.256Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M333.421 15.053C337.401 15.053 340.628 11.8302 340.628 7.85462C340.628 3.87907 337.401 0.65625 333.421 0.65625C329.441 0.65625 326.215 3.87907 326.215 7.85462C326.215 11.8302 329.441 15.053 333.421 15.053Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M288.892 128.213C291.26 128.213 293.179 126.296 293.179 123.931C293.179 121.566 291.26 119.648 288.892 119.648C286.525 119.648 284.605 121.566 284.605 123.931C284.605 126.296 286.525 128.213 288.892 128.213Z"
                    stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path className={"spinner"}
                        d="M294.503 140.735C292.741 141.322 290.847 141.646 288.884 141.646C279.091 141.646 271.148 133.712 271.148 123.931C271.148 114.149 279.091 106.215 288.884 106.215C293.215 106.215 297.177 107.765 300.254 110.331M304.664 115.84C305.918 118.266 306.619 121.015 306.619 123.931"
                        stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path className={"spinner"}
                        d="M275.629 644.964C280.319 638.948 287.639 635.086 295.854 635.086C302.078 635.086 307.786 637.301 312.23 640.997M289.709 685.533C278.532 682.784 270.238 672.695 270.238 660.681C270.238 659.498 270.317 658.334 270.475 657.195M303.097 685.246C301.668 685.666 300.178 685.964 298.652 686.131M318.132 648.012C320.271 651.751 321.489 656.077 321.489 660.683C321.489 668.757 317.746 675.965 311.898 680.658"
                        stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_1240_15452">
                    <rect width="497" height="727" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

            </div>

          </div>


        </div>
      </section>


      <section>
        <div className="container mx-auto px-4">

          <div className="grid lg:grid-cols-2 gap-4 lg:gap-40">
            <div>
              <header className={'mb-10 lg:mb-20'}>
                <h2 className={'headLine'}>
                  HOW DO YOU JOIN?
                </h2>
                <p className={"mt-10 bodyText"}>Your first automated campaign is FREE.</p>
              </header>

              <ContactForm/>

            </div>
            <div className={"flex items-center"}>
              <svg className={"max-w-full h-auto"} width="770" height="550" viewBox="0 0 770 550" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M377.94 188.461L378.83 189.571V188.148V71.5H491.813V383.274H413.665C402.875 383.274 395.562 381.856 389.683 378.643C383.803 375.43 379.298 370.39 374.181 363.016L374.176 363.009L374.17 363.001L295.522 258.364L294.623 257.167V258.664V383.274H180.5V71.5H259.788C270.589 71.5 277.616 72.6366 283.493 75.7082C289.376 78.783 294.164 83.8261 300.429 91.7818L300.432 91.7852L377.94 188.461Z"
                  stroke="#B6D43E"/>
                <path
                  d="M661.676 222.867L661.686 222.869C693.403 229.638 720.367 235.744 739.412 246.888C748.921 252.452 756.434 259.26 761.574 268.017C766.713 276.774 769.498 287.513 769.498 300.969C769.498 328.196 758.522 349.305 739.762 363.62C720.986 377.948 694.371 385.5 663.071 385.5C626.973 385.5 598.594 376.823 577.812 361.282C557.185 345.857 543.998 323.638 538.19 296.316H547.428C553.889 322 566.882 342.109 586.106 355.809C605.42 369.573 630.981 376.837 662.43 376.837C691.63 376.837 716.237 370.221 733.558 357.496C750.897 344.757 760.894 325.925 760.894 301.613C760.894 290.434 758.679 281.325 754.305 273.725C749.93 266.125 743.417 260.07 734.879 254.965C717.831 244.771 692.616 238.315 659.982 230.912C622.832 222.212 594.581 214.336 575.612 202.709C566.14 196.903 559.005 190.174 554.234 181.959C549.464 173.745 547.038 164.012 547.038 152.166C547.038 127.207 558.004 107.06 576.373 93.1393C594.755 79.2096 620.572 71.5 650.265 71.5C710.609 71.5 749.977 97.5421 764.804 138.138H755.601C739.418 100.73 701.485 80.1626 650.905 80.1626C624.263 80.1626 600.617 86.4589 583.619 98.9405C566.603 111.435 556.283 130.105 556.283 154.742C556.283 163.173 557.693 170.466 561.133 176.959C564.574 183.453 570.024 189.107 578.041 194.296C594.05 204.655 620.383 213.206 661.676 222.867Z"
                  stroke="#82A6FC"/>
                <path
                  d="M14.8775 524.959C22.614 538.359 39.7501 542.949 53.1521 535.212C66.5541 527.474 71.1469 510.338 63.4104 496.939C55.674 483.539 38.5379 478.948 25.1359 486.686C11.7339 494.424 7.14106 511.559 14.8775 524.959Z"
                  fill="#FFD9F6"/>
                <path
                  d="M17.772 521.283C25.5085 534.683 42.6446 539.273 56.0466 531.536C69.4486 523.798 74.0414 506.663 66.305 493.263C58.5685 479.863 41.4324 475.273 28.0304 483.01C14.6284 490.748 10.0356 507.883 17.772 521.283Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M36.7728 248.91C41.6905 257.428 52.5832 260.346 61.1023 255.427C69.6214 250.509 72.5409 239.616 67.6231 231.099C62.7054 222.581 51.8127 219.663 43.2936 224.582C34.7745 229.5 31.855 240.392 36.7728 248.91Z"
                  fill="#D4F7F5"/>
                <path
                  d="M39.6673 245.234C44.585 253.752 55.4778 256.67 63.9968 251.751C72.5159 246.833 75.4354 235.941 70.5177 227.423C65.5999 218.905 54.7072 215.987 46.1881 220.906C37.669 225.824 34.7495 236.716 39.6673 245.234Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M490.773 499.91C495.69 508.428 506.583 511.346 515.102 506.427C523.621 501.509 526.541 490.616 521.623 482.099C516.705 473.581 505.813 470.663 497.294 475.582C488.774 480.5 485.855 491.392 490.773 499.91Z"
                  fill="#FFD9F6"/>
                <path
                  d="M493.667 496.234C498.585 504.752 509.478 507.67 517.997 502.751C526.516 497.833 529.435 486.941 524.518 478.423C519.6 469.905 508.707 466.987 500.188 471.906C491.669 476.824 488.75 487.717 493.667 496.234Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M165.21 84.959C172.946 98.359 190.082 102.949 203.484 95.2115C216.886 87.4739 221.479 70.3385 213.742 56.9385C206.006 43.5386 188.87 38.9484 175.468 46.686C162.066 54.4237 157.473 71.5591 165.21 84.959Z"
                  fill="#FFDED4"/>
                <path
                  d="M166.76 81.2832C174.497 94.6832 191.633 99.2734 205.035 91.5357C218.437 83.7981 223.03 66.6627 215.293 53.2628C207.557 39.8628 190.421 35.2726 177.019 43.0102C163.617 50.7479 159.024 67.8833 166.76 81.2832Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M623.773 70.9101C628.69 79.4279 639.583 82.3457 648.102 77.4272C656.621 72.5087 659.541 61.6164 654.623 53.0986C649.705 44.5808 638.813 41.663 630.294 46.5815C621.774 51.5 618.855 62.3923 623.773 70.9101Z"
                  fill="#FFDED4"/>
                <path
                  d="M626.667 67.2343C631.585 75.7521 642.478 78.6699 650.997 73.7514C659.516 68.8329 662.435 57.9406 657.518 49.4228C652.6 40.905 641.707 37.9872 633.188 42.9057C624.669 47.8243 621.749 58.7165 626.667 67.2343Z"
                  stroke="#3388FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

            </div>

          </div>


        </div>
      </section>


    </div>
  );
}

export default Home;
