import * as React from "react";
const Circles1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={364}
    height={211}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#D4F7F5"
        d="M227.469 18.794c0-10.21-8.263-18.488-18.456-18.488s-18.456 8.277-18.456 18.488 8.263 18.487 18.456 18.487 18.456-8.277 18.456-18.487"
      />
      <path
        fill="#FFDED4"
        d="M27.828 74.45c0-6.503-5.263-11.774-11.754-11.774S4.32 67.946 4.32 74.449c0 6.502 5.263 11.774 11.754 11.774s11.754-5.272 11.754-11.774M336.939 169.101c0-5.196-4.205-9.408-9.391-9.408-5.187 0-9.392 4.212-9.392 9.408s4.205 9.407 9.392 9.407 9.391-4.212 9.391-9.407"
      />
      <path
        stroke="#38F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M224.608 19.93c0-10.21-8.263-18.487-18.456-18.487S187.695 9.72 187.695 19.93s8.264 18.488 18.457 18.488 18.456-8.277 18.456-18.487M24.947 75.605c0-6.502-5.262-11.773-11.754-11.773-6.491 0-11.754 5.271-11.754 11.773 0 6.503 5.263 11.774 11.754 11.774 6.492 0 11.754-5.271 11.754-11.774M333.099 170.64c0-5.196-4.204-9.408-9.391-9.408s-9.392 4.212-9.392 9.408 4.205 9.407 9.392 9.407 9.391-4.212 9.391-9.407"
      />
      <path
         className={'spinner'}
        stroke="#38F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M360.563 158.329a39 39 0 0 1 1.998 12.331c0 21.489-17.401 38.918-38.853 38.918s-38.853-17.429-38.853-38.918a38.84 38.84 0 0 1 9.027-24.951M305.961 136.031c5.32-2.751 11.35-4.29 17.746-4.29"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 211V0h364v211z" />
      </clipPath>
    </defs>
  </svg>
);
export default Circles1;